import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Table,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { compareDateStrings } from '../../../utils/Utils';

// Translation
import { useTranslation } from 'react-i18next';

const Step4 = ({ vki, setVKI, jumpToStep }) => {
  const { t } = useTranslation();

  const {
    departureDate,
    investigated,
    investigationDate,
    investigationResult,
    typeOfCompany,
    optionalInvestigation,
    optionalInvestigationDate,
    optionalInvestigationResult,
    listOfCountries,
  } = vki;

  const onChange = e =>
    setVKI({ ...vki, [e.target.getAttribute('data-field')]: e.target.value });

  const [invalidFields, setInvalidFields] = useState([]);

  const validateStep = async () => {
    let falsyFields = [];

    if (!investigated || investigated === '') {
      console.log("investigatted", falsyFields)
      falsyFields.push({
        field: 'investigated',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    // if (!investigationDate) {
    //   console.log()
    //   falsyFields.push({
    //     field: 'investigationDate',
    //     msg: t('forms.fieldErrors.fieldRequired'),
    //   });
    // } else if (compareDateStrings(investigationDate, departureDate)) {
    //   falsyFields.push({
    //     field: 'investigationDate',
    //     msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
    //   });
    // }

    if (
      optionalInvestigationDate &&
      optionalInvestigationDate !== '' &&
      compareDateStrings(optionalInvestigationDate, departureDate)
    ) {
      falsyFields.push({
        field: 'optionalInvestigationDate',
        msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
      });
    }

    // if (!investigationResult) {
    //   falsyFields.push({
    //     field: 'investigationResult',
    //     msg: t('forms.fieldErrors.fieldRequired'),
    //   });
    // }

    if (!typeOfCompany || typeOfCompany === '') {
      falsyFields.push({
        field: 'typeOfCompany',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (!listOfCountries) {
      falsyFields.push({
        field: 'listOfCountries',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (falsyFields.length === 0) {
      jumpToStep(4);
    } else {
      setInvalidFields(falsyFields);
    }
  };

  const checkField = fieldName => {
    return invalidFields.find(field => field.field === fieldName)
      ? true
      : false;
  };

  const getMessage = fieldName => {
    return invalidFields.find(field => field.field === fieldName).msg;
  };

  return (
    <div className='step step1 mt-5 mb-5'>
      <Row className='justify-content-md-center mt-5'>
        <Col lg='6'>
          {invalidFields.length > 0 && (
            <div className='alert alert-danger fade show' role='alert'>
              {t('forms.fieldErrors.checkFields')}
            </div>
          )}
          <Form
            className='form-horizontal form-material mt-2'
            onSubmit={e => e.preventDefault()}
          >
            <h4 className='mb-3'>{t('vki.investigationsCarriedOutTitle')}</h4>
            <Row className='mt-4'>
              <Col md='12'>
                <h5>
                  {t('vki.investigationsCarriedOutDesc')}{' '}
                  <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='investigated'
                      name='investigated'
                      value='1'
                      id='investigatedRadio1'
                      onChange={onChange}
                      checked={investigated === '1'}
                    />
                    <Label for='investigatedRadio1'>{t('general.yes')}</Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='investigated'
                      name='investigated'
                      value='2'
                      id='investigatedRadio2'
                      onChange={onChange}
                      checked={investigated === '2'}
                    />
                    <Label for='investigatedRadio2'>{t('general.no')}</Label>
                  </div>
                </div>
                {checkField('investigated') && (
                  <span className='text-danger'>
                    {getMessage('investigated')}
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4'>
              <Table>
                <thead>
                  <tr>
                    <th>{t('vki.typeofInvestigation')}</th>
                    <th>{t('vki.dateofInvestigation')}</th>
                    <th>{t('vki.analysisResult')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {t('vki.salmonella')}
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          type='date'
                          value={investigationDate}
                          data-field='investigationDate'
                          name='investigationDate'
                          onChange={onChange}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          type='text'
                          value={investigationResult}
                          data-field='investigationResult'
                          name='investigationResult'
                          onChange={onChange}
                        />
                        {/* {checkField('investigationResult') && (
                          <span className='text-danger'>
                            {getMessage('investigationResult')}
                          </span>
                        )} */}
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='3' className='border-none'>
                      {/* <span className='text-primary'>*</span> */}
                      <div className='form-inline'>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='typeOfCompany'
                            name='typeOfCompany'
                            value='1'
                            id='typeOfCompanyRadio1'
                            onChange={onChange}
                            checked={typeOfCompany === '1'}
                          />
                          <Label for='typeOfCompanyRadio1'>
                            {t('vki.companyTypeRisk')}
                          </Label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='typeOfCompany'
                            name='typeOfCompany'
                            value='2'
                            id='typeOfCompanyRadio2'
                            onChange={onChange}
                            checked={typeOfCompany === '2'}
                          />
                          <Label for='typeOfCompanyRadio2'>
                            {t('vki.companyTypeNoRisk')}
                          </Label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='typeOfCompany'
                            name='typeOfCompany'
                            value='3'
                            id='typeOfCompanyRadio3'
                            onChange={onChange}
                            checked={typeOfCompany === '3'}
                          />
                          <Label for='typeOfCompanyRadio3'>
                            {t('vki.companyTypeFittings')}
                          </Label>
                        </div>
                      </div>
                      {checkField('typeOfCompany') && (
                        <span className='text-danger'>
                          {getMessage('typeOfCompany')}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormGroup>
                        <Input
                          type='text'
                          value={optionalInvestigation}
                          data-field='optionalInvestigation'
                          name='optionalInvestigation'
                          onChange={onChange}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          type='date'
                          data-field='optionalInvestigationDate'
                          name='optionalInvestigationDate'
                          onChange={onChange}
                          defaultValue={optionalInvestigationDate}
                        />
                        {checkField('optionalInvestigationDate') && (
                          <span className='text-danger'>
                            {getMessage('optionalInvestigationDate')}
                          </span>
                        )}
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Input
                          type='text'
                          value={optionalInvestigationResult}
                          data-field='optionalInvestigationResult'
                          name='optionalInvestigationResult'
                          onChange={onChange}
                        />
                      </FormGroup>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row className='mt-4'>
              <Col md='12'>
                <h4 className='mb-3'>
                  {t('vki.exportTitle')} <span className='text-primary'>*</span>
                </h4>
                <FormGroup>
                  <Label htmlFor='listOfCountries'>
                    {t('vki.listOfCountries')}
                  </Label>
                  <Input
                    type='text'
                    value={listOfCountries}
                    data-field='listOfCountries'
                    name='listOfCountries'
                    onChange={onChange}
                  />
                  {checkField('listOfCountries') && (
                    <span className='text-danger'>
                      {getMessage('listOfCountries')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <div className='mt-4 d-flex justify-content-between'>
            <Button onClick={() => jumpToStep(2)} className='btn-secondary'>
              {t('general.previous')}
            </Button>
            <Button onClick={validateStep} className='btn-primary'>
              {t('general.next')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step4;
