import React, { useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';

// Context
import { GlobalContext } from '../../store/Provider';
import { setCurrentLoadingplace } from '../../store/actions/loadingplaces';

// Translation
import { useTranslation } from 'react-i18next';

const LoadingplaceItem = ({ loadingplace }) => {
  const { veterinariansState, loadingplacesDispatch } =
    useContext(GlobalContext);
  const [loadingPlacesOption, setLoadingPlacesOption] = useState([])
  const { t } = useTranslation();

  const { lp_code, address_line1, locality, postcode, default_veterinarian } =
    loadingplace;

  const defaultVeterinarian = () => {
    return veterinariansState.veterinarians.list.filter(
      veterinarian => veterinarian.id === default_veterinarian
    )[0];
  };

  let match = useRouteMatch();
  let uniqueCountryCodes = [];

  useEffect(() => {
    if(loadingplace?.options?.length > 0) {
      loadingplace?.options.forEach(item => { 
        if(item.deingeb) {
          uniqueCountryCodes.push(`${item.dropdown1} | ${item.dropdown2} | ${item.dropdown3}`)
        }
      })
    }
    setLoadingPlacesOption(uniqueCountryCodes);
  }, [])

  return (
    <>
     {loadingPlacesOption.length > 0  && (
    <tr>
      <td>{lp_code}</td>
      <td>
        {address_line1}, {postcode} {locality}
      </td>
      <td>
        {defaultVeterinarian() ? (
          decodeStr(defaultVeterinarian().title)
        ) : (
          <span className='text-danger'>
            {t('loadingplaces.standardVeterinarianNotSet')}
          </span>
        )}
      </td>
      <td>
        <Link
          onClick={() =>
            setCurrentLoadingplace(loadingplace)(loadingplacesDispatch)
          }
          to={`${match.path}/view/${loadingplace.lp_code}`}
          className='btn btn-secondary btn-sm mr-1'
        >
          <i className='icon-pencil'></i> <span>{t('general.edit')}</span>
        </Link>
      </td>
    </tr>
    )
        }
    </>
  );
};

LoadingplaceItem.propTypes = {
  loadingplace: PropTypes.object.isRequired,
};

export default LoadingplaceItem;
