import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Load layout components
import Header from '../components/layout/Header';
import Sidebar from '../components/layout/Sidebar';
import PageSpinner from '../components/layout/PageSpinner';
import StablesComponent from '../components/stables/StablesComponent';

import { GlobalContext } from '../store/Provider';

const Stables = () => {
  const { t } = useTranslation();

  const { stablesState, layoutState } = useContext(GlobalContext);

  const getSidebarType = () => {
    if (window.innerWidth >= 768) {
      return layoutState.sidebarOpen ? 'iconbar' : 'mini-sidebar';
    } else {
      return 'mini-sidebar';
    }
  };

  return (
    <div
      id='main-wrapper'
      data-layout='vertical'
      data-sidebartype={getSidebarType()}
      data-sidebarposition='fixed'
      data-header-position='fixed'
      data-boxed-layout='full'
      data-theme='light'
    >
      <Header />
      <Sidebar />
      <div className='page-wrapper d-block'>
        <div className='page-content container-fluid'>
          <h1>{t('stables.name')}</h1>
          {stablesState.stables.fetching ? (
            <PageSpinner msg={t('general.stillFetchingData')} />
          ) : (
            <StablesComponent />
          )}
        </div>
      </div>
      {/* Footer */}
    </div>
  );
};

export default Stables;
