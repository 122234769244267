import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ChartPigPrice from '../components/dashboard/ChartPigPrice';
import TasteAndWelfare from '../components/dashboard/TasteAndWelfare';
import MapsCard from '../components/dashboard/MapsCard';
import FeaturedBlogItem from '../components/dashboard/FeaturedBlogItem';

// Load layout components
import Header from '../components/layout/Header';
import Sidebar from '../components/layout/Sidebar';
import SocialCard from '../components/dashboard/SocialCard';
import './dashboard.css';

import { GlobalContext } from '../store/Provider';


const Dashboard = () => {
  const { suppliersState, articlesState, authState, layoutState } =
    useContext(GlobalContext);

  const { t } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng');
  const convertToSlug = text => {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  };

  const [priceData, setPriceData] = useState(undefined);
  const [randomblog, setRandomblog] = useState(undefined);

  let nonFeaturedArticles = [];
  let featuredArticles = [];

  articlesState.articles.list.map(item => {
    if (item.featured === 'True') {
      featuredArticles.push(item);
    }
    else {
      nonFeaturedArticles.push(item);
    }
  });

  let featuredBlog = false;

  if (featuredArticles.length > 0) {
    featuredBlog = featuredArticles[0];
  }


  useEffect(() => {
    if (priceData === undefined) {
      const loadPriceData = async () => {
        await fetch('https://www.westvlees.com/nl/pig_prices')
          .then(response => response.json())
          .then(data => {
            // console.log('data resp', data);
            setPriceData(data);
          });
      };
      loadPriceData();
    }
  }, [priceData]);

  useEffect(() => {
    if (randomblog === undefined) {
      const loadTasteAndWelfareBlog = async () => {
        await fetch(
          'https://www.westvlees.com/nl/taste_and_welfare?lang=' + currentLang
        )
          .then(response => response.json())
          .then(data => setRandomblog(data));
      };
      loadTasteAndWelfareBlog();
    }
  }, [randomblog, currentLang]);

  const getSidebarType = () => {
    if (window.innerWidth >= 768) {
      return layoutState.sidebarOpen ? 'iconbar' : 'mini-sidebar';
    } else {
      return 'mini-sidebar';
    }
  };

  return (
    <div
      id='main-wrapper'
      data-layout='vertical'
      data-sidebartype={getSidebarType()}
      data-sidebarposition='fixed'
      data-header-position='fixed'
      data-boxed-layout='full'
      data-theme='light'
    >
      <Header />
      <Sidebar />
      <div className='page-wrapper d-block'>
        <div className='page-content container-fluid'>
          <h1>
            {t('general.greeting')}{' '}
            {typeof authState.user_data.field_first_name[0] !== 'undefined'
              ? authState.user_data.field_first_name[0].value
              : ''}
          </h1>
          {suppliersState.suppliers.list === null ||
            (suppliersState.suppliers.list.length === 0 && (
              <span className='text-info pt-4 pb-4 d-block h6'>
                {t('alerts.info.dashboard.notConnectedToSupplier')}
              </span>
            ))}
          <Row className='mt-4'>
            {priceData && (
              <Col sm='12' md='12' lg='12' xl='8' className='col-xlg-6 mt-4'>
                <ChartPigPrice
                  chartData={priceData.pig_prices}
                  cardData={priceData.current_prices}
                />
              </Col>
            )}

            {featuredBlog ? (
              <Col sm='12' md='6' lg='6' xl='4' className='col-xlg-3 mt-4'>
                <div className='featured-blog-wrapper'>
                  <FeaturedBlogItem
                    title={featuredBlog.title}
                    summary={featuredBlog.intro}
                    image={featuredBlog.image}
                    nid={featuredBlog.nid}
                    category='News'
                    badgeColor='info'
                    url={`/blog/${convertToSlug(featuredBlog.title)}`}
                  />
                  <MapsCard />
                </div>
              </Col>
            ) : (
              <Col sm='12' md='6' lg='6' xl='4' className='col-xlg-3 mt-4'>
                <MapsCard />
              </Col>
            )}

            {randomblog && (
              <Col sm='12' md='6' lg='6' xl='4' className='col-xlg-3 mt-4'>
                <TasteAndWelfare content={randomblog.random_blog} />
              </Col>
            )}

               {nonFeaturedArticles.map(blog => (
                <Col
                  key={blog.nid}
                  sm='12'
                  md='6'
                  lg='6'
                  xl='4'
                  className='col-xlg-3 mt-4'
                >
                  <SocialCard
                    title={blog.title}
                    summary={blog.intro}
                    image={blog.image}
                    nid={blog.nid}
                    category='News'
                    badgeColor='info'
                    url={`/blog/${convertToSlug(blog.title)}`}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </div>
      {/* Footer */}
    </div>
  );
};

export default Dashboard;
