import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';

import { GlobalContext } from '../../store/Provider';

import { setCurrentStable } from '../../store/actions/stables';

// Translation
import { useTranslation } from 'react-i18next';

const StableItem = ({ stable }) => {
  const { stablesDispatch, authState } = useContext(GlobalContext);

  const { t } = useTranslation();

  const ifOwner = () => {
    return authState.user.roles && authState.user.roles.includes('owner');
  };

  const { id, title, lp_code, users, access_from, access_to } = stable;

  let match = useRouteMatch();

  return (
    <tr>
      <td>{id}</td>
      <td>{decodeStr(title)}</td>
      <td>{lp_code}</td>
      <td>{access_from}</td>
      <td>{access_to}</td>
      <td>
        <ul>
          {users.map(user => {
            if (Number(user.id) !== 0) {
              return <li key={user.id}>{user.first_name}</li>;
            } else {
              return '';
            }
          })}
        </ul>
      </td>
      <td>
        <Link
          onClick={() => setCurrentStable(stable)(stablesDispatch)}
          to={`${match.path}/view/${stable.id}`}
          className='btn btn-sm btn-secondary mr-1'
        >
          {ifOwner() ? (
            <Fragment>
              <i className='icon-pencil'></i> <span>{t('general.edit')}</span>
            </Fragment>
          ) : (
            <Fragment>
              <i className='icon-eye'></i> <span>{t('general.view')}</span>
            </Fragment>
          )}
        </Link>
      </td>
    </tr>
  );
};

StableItem.propTypes = {
  stable: PropTypes.object.isRequired,
};

export default StableItem;
