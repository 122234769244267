import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import ContainerSpinner from '../layout/ContainerSpinner';
import { GlobalContext } from '../../store/Provider';

import { setAlert } from '../../store/actions/alerts';

import {
  addContributor,
  updateContributor,
  clearCurrentContributor,
  setContributorLoading,
} from '../../store/actions/contributors';

// Translation
import { useTranslation } from 'react-i18next';

const ContributorForm = () => {
  const {
    suppliersState,
    contributorsState,
    contributorsDispatch,
    alertsDispatch,
    authState,
  } = useContext(GlobalContext);

  const { t } = useTranslation();
  const history = useHistory();

  const [contributor, setContributor] = useState({
    first_name: '',
    email: '',
    mobile: '',
    permissions: {
      AI: '0',
      BO: '0',
      CN: '0',
      FA: '0',
      FL: '0',
      IN: '0',
      OF: '0',
      SL: '0',
      documents: '0',
      contributors: '0',
      loadingplaces: '0',
      offers: '0',
      reporting: '0',
      stables: '0',
      veterinarians: '0',
    },
  });

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (contributorsState.contributors.current !== null) {
      setContributor(contributorsState.contributors.current);
      let permissionKeys = Object.keys(
        contributorsState.contributors.current.permissions
      );
      let currentContribPermissions = [];
      permissionKeys.forEach(key => {
        if (
          key !== 'uid' &&
          key !== 'supplierid' &&
          contributorsState.contributors.current.permissions[key] !== '0'
        ) {
          currentContribPermissions.push(key);
        }
      });
      setSelectedPermissions(currentContribPermissions);
    }
    // eslint-disable-next-line
  }, []);

  const { first_name, email, mobile } = contributor;

  const onChange = e =>
    setContributor({ ...contributor, [e.target.name]: e.target.value });

  // useEffect(() => {
  //   console.log('Selected Permissions', selectedPermissions);
  // }, [selectedPermissions]);

  const handleRegularPermissionChange = e => {
    let permissions = [...selectedPermissions];
    if (permissions.includes(e.target.value)) {
      permissions = permissions.filter(
        permission => permission !== e.target.value
      );
      setSelectedPermissions([...permissions]);
    } else {
      setSelectedPermissions([...permissions, e.target.value]);
    }
  };

  const handleDocumentPermissionChange = e => {
    let permissions = [...selectedPermissions];

    if (e.target.value === 'documents') {
      if (permissions.includes('documents')) {
        permissions = permissions.filter(permission => {
          return (
            permission !== 'documents' &&
            permission !== 'AI' &&
            permission !== 'BO' &&
            permission !== 'CN' &&
            permission !== 'FA' &&
            permission !== 'FL' &&
            permission !== 'IN' &&
            permission !== 'OF' &&
            permission !== 'SL'
          );
        });
        setSelectedPermissions([...permissions]);
      } else {
        setSelectedPermissions([
          ...permissions,
          'documents',
          'AI',
          'BO',
          'CN',
          'FA',
          'FL',
          'IN',
          'OF',
          'SL',
        ]);
      }
    } else {
      if (
        permissions.includes('documents') &&
        permissions.includes(e.target.value)
      ) {
        permissions = permissions.filter(
          permission => permission !== e.target.value
        );
        if (
          !permissions.includes('AI') &&
          !permissions.includes('BO') &&
          !permissions.includes('CN') &&
          !permissions.includes('FA') &&
          !permissions.includes('FL') &&
          !permissions.includes('IN') &&
          !permissions.includes('OF') &&
          !permissions.includes('SL')
        ) {
          permissions = permissions.filter(
            permission => permission !== 'documents'
          );
        }
        setSelectedPermissions([...permissions]);
      } else if (
        permissions.includes('documents') &&
        !permissions.includes(e.target.value)
      ) {
        setSelectedPermissions([...permissions, e.target.value]);
      } else {
        setSelectedPermissions([...permissions, e.target.value, 'documents']);
      }
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    setContributorLoading(true)(contributorsDispatch);
    let res;

    if (contributorsState.contributors.current === null) {
      res = await addContributor(
        contributor,
        selectedPermissions,
        suppliersState.suppliers.activeID,
        authState.user.uid
      )(contributorsDispatch);
    } else {
      res = await updateContributor(
        contributor,
        selectedPermissions,
        suppliersState.suppliers.activeID
      )(contributorsDispatch);
    }
    setContributorLoading(false)(contributorsDispatch);
    switch (res.code) {
      case 200:
        setAlert(res.msg, 'success')(alertsDispatch);
        redirect();
        break;
      case 400:
        setAlert(res.msg, 'danger')(alertsDispatch);
        break;
      case 504:
        setAlert(res.msg, 'warning')(alertsDispatch);
        redirect();
        break;
      case 406:
        setAlert(res.msg, 'danger')(alertsDispatch);
        break;
      default:
        break;
    }
  };

  const redirect = () => {
    clearCurrentContributor()(contributorsDispatch);
    history.push('/contributors');
  };

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <button className='btn btn-primary' onClick={redirect}>
            {t('general.prev')}
          </button>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <Card>
            <CardBody>
              {contributorsState.contributors.loading ? (
                <ContainerSpinner />
              ) : (
                ''
              )}
              <Form
                className='form-material'
                onSubmit={onSubmit}
                autoComplete='off'
              >
                <h4>
                  {contributorsState.contributors.current
                    ? t('contributors.edit')
                    : t('contributors.add')}
                </h4>
                <Row className='mt-3'>
                  <Col md='12' sm='12'>
                    <FormGroup>
                      <Label>{t('general.name')}</Label>
                      <Input
                        type='text'
                        name='first_name'
                        value={decodeStr(first_name)}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                        disabled={
                          contributorsState.contributors.current !== null
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6' sm='12'>
                    <FormGroup>
                      <Label>{t('general.email')}</Label>
                      <Input
                        type='email'
                        name='email'
                        value={email}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                        disabled={
                          contributorsState.contributors.current !== null
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6' sm='12'>
                    <FormGroup>
                      <Label>
                        {t('general.phone')}
                        <span className='text-secondary'> (+32123456789)</span>
                      </Label>
                      <Input
                        type='text'
                        name='mobile'
                        value={mobile}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                        disabled={
                          contributorsState.contributors.current !== null
                        }
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md='6' sm="12">
                    <FormGroup>
                      <Label>{t('general.address')}</Label>
                      <Input
                        type='text'
                        name='address_line1'
                        value={address_line1}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6' sm="12">
                    <FormGroup>
                      <Label>{t('general.zip')}</Label>
                      <Input
                        type='text'
                        name='postal_code'
                        value={postal_code}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6' sm="12">
                    <FormGroup>
                      <Label>{t('general.city')}</Label>
                      <Input
                        type='text'
                        name='locality'
                        value={locality}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6' sm="12">
                    <FormGroup>
                      <Label>{t('general.country')}</Label>
                      <Input
                        type='select'
                        name='country_code'
                        value={country_code}
                        onChange={onChange}
                        autoComplete={false}
                        required={true}
                      >
                        <option value=''>{t('general.makeChoice')}</option>
                        <option value='BE'>{t('countries.Belgium')}</option>
                        <option value='FR'>{t('countries.France')}</option>
                        <option value='LU'>{t('countries.Luxembourg')}</option>
                        <option value='NL'>{t('countries.Netherlands')}</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                </Row>

                <FormGroup className='mt-3'>
                  <Label>{t('general.permissions')}</Label>
                  <Row className='pl-3 pr-3'>
                    <Col md='4'>
                      <ul>
                        <li>
                          <Input
                            name='contributors'
                            type='checkbox'
                            value='contributors'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('contributors')
                            }
                          />
                          <Label for='contributors'>
                            {t('contributors.name')}
                          </Label>
                        </li>

                        <li>
                          <Input
                            name='veterinarians'
                            type='checkbox'
                            value='veterinarians'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('veterinarians')
                            }
                          />
                          <Label for='veterinarians'>
                            {t('veterinarians.name')}
                          </Label>
                        </li>

                        <li>
                          <Input
                            name='loadingplaces'
                            type='checkbox'
                            value='loadingplaces'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('loadingplaces')
                            }
                          />
                          <Label for='loadingplaces'>
                            {t('loadingplaces.name')}
                          </Label>
                        </li>

                        <li>
                          <Input
                            name='stables'
                            type='checkbox'
                            value='stables'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('stables')
                            }
                          />
                          <Label for='stables'>{t('stables.name')}</Label>
                        </li>

                        <li>
                          <Input
                            name='offers'
                            type='checkbox'
                            value='offers'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('offers')
                            }
                          />
                          <Label for='offers'>{t('offers.name')}</Label>
                        </li>

                        <li>
                          <Input
                            name='documents'
                            type='checkbox'
                            value='documents'
                            onChange={handleDocumentPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('documents')
                            }
                          />
                          <Label for='documents'>{t('documents.name')}</Label>

                          <ul>
                            <li>
                              <Input
                                name='AI'
                                type='checkbox'
                                value='AI'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('AI')
                                }
                              />
                              <Label for='AI'>{t('documents.types.AI')}</Label>
                            </li>

                            <li>
                              <Input
                                name='BO'
                                type='checkbox'
                                value='BO'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('BO')
                                }
                              />
                              <Label for='BO'>{t('documents.types.BO')}</Label>
                            </li>

                            <li>
                              <Input
                                name='CN'
                                type='checkbox'
                                value='CN'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('CN')
                                }
                              />
                              <Label for='CN'>{t('documents.types.CN')}</Label>
                            </li>

                            <li>
                              <Input
                                name='FA'
                                type='checkbox'
                                value='FA'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('FA')
                                }
                              />
                              <Label for='FA'>{t('documents.types.FA')}</Label>
                            </li>

                            <li>
                              <Input
                                name='FL'
                                type='checkbox'
                                value='FL'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('FL')
                                }
                              />
                              <Label for='FL'>{t('documents.types.FL')}</Label>
                            </li>

                            <li>
                              <Input
                                name='IN'
                                type='checkbox'
                                value='IN'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('IN')
                                }
                              />
                              <Label for='IN'>{t('documents.types.IN')}</Label>
                            </li>

                            <li>
                              <Input
                                name='OF'
                                type='checkbox'
                                value='OF'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('OF')
                                }
                              />
                              <Label for='OF'>{t('documents.types.OF')}</Label>
                            </li>

                            <li>
                              <Input
                                name='SL'
                                type='checkbox'
                                value='SL'
                                onChange={handleDocumentPermissionChange}
                                checked={
                                  selectedPermissions &&
                                  selectedPermissions.includes('SL')
                                }
                              />
                              <Label for='SL'>{t('documents.types.SL')}</Label>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Input
                            name='reporting'
                            type='checkbox'
                            value='reporting'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('reporting')
                            }
                          />
                          <Label for='reporting'>{t('reporting.name')}</Label>
                        </li>

                        <li>
                          <Input
                            name='benchmarking'
                            type='checkbox'
                            value='benchmarking'
                            onChange={handleRegularPermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes('benchmarking')
                            }
                          />
                          <Label for='benchmarking'>
                            {t('benchmarking.name')}
                          </Label>
                        </li>
                      </ul>
                      {/* {Object.entries(permissions).map(permission => {
                      return (
                        <div
                          key={permission[0]}
                          className='form-check col-md-4'
                        >
                          <Input
                            name={permission[0]}
                            type='checkbox'
                            value={permission[0]}
                            onChange={handlePermissionChange}
                            checked={
                              selectedPermissions &&
                              selectedPermissions.includes(permission[0])
                            }
                          />
                          <Label for={permission[0]}>{permission[0]}</Label>
                        </div>
                      );
                    })} */}
                    </Col>
                  </Row>
                </FormGroup>

                <Row className='mt-3'>
                  <Col md='6' sm='12'>
                    <input
                      type='submit'
                      value={
                        contributorsState.contributors.current
                          ? t('general.edit')
                          : t('general.add')
                      }
                      className='btn btn-primary'
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ContributorForm;
