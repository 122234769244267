/* eslint-disable import/no-anonymous-default-export */
export default {
  sidebarOpen:
    localStorage.getItem('sidebarOpen') === null
      ? true
      : JSON.parse(localStorage.getItem('sidebarOpen')),
    

  topbarOpen: false,
  settingsExpanded: false,
};
