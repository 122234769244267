import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import OverviewVKI from './OverviewVKI';

// Translation
import { useTranslation } from 'react-i18next';

const Step5 = ({ vki, setVKI, jumpToStep }) => {
  const { t } = useTranslation();

  const { signatureProducer, signatureDate } = vki;

  const onChange = e =>
    setVKI({ ...vki, [e.target.getAttribute('data-field')]: e.target.value });

  const [invalidFields, setInvalidFields] = useState([]);

  const validateStep = async () => {
    let falsyFields = [];

    if (!signatureDate) {
      falsyFields.push({
        field: 'signatureDate',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!signatureProducer) {
      falsyFields.push({
        field: 'signatureProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (falsyFields.length === 0) {
      jumpToStep(5);
    } else {
      setInvalidFields(falsyFields);
    }
  };

  const checkField = fieldName => {
    return invalidFields.find(field => field.field === fieldName)
      ? true
      : false;
  };

  const getMessage = fieldName => {
    return invalidFields.find(field => field.field === fieldName).msg;
  };

  return (
    <div className='step step1 mt-5 mb-5'>
      <Row className='justify-content-md-center mt-5'>
        <Col lg='6'>
          {invalidFields.length > 0 && (
            <div className='alert alert-danger fade show' role='alert'>
              {t('forms.fieldErrors.checkFields')}
            </div>
          )}

          <OverviewVKI vki={vki} />

          <Form className='form-horizontal form-material mt-5'>
            <h4 className='mb-3'>{t('vki.declaration')}</h4>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='signatureProducer'>
                    {t('vki.signatureProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={signatureProducer}
                    name='signatureProducer'
                    data-field='signatureProducer'
                    onChange={onChange}
                  />
                  {checkField('signatureProducer') && (
                    <span className='text-danger'>
                      {getMessage('signatureProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='signatureDate'>
                    {t('vki.signatureDate')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='date'
                    value={signatureDate}
                    name='signatureDate'
                    data-field='signatureDate'
                    onChange={onChange}
                  />
                  {checkField('signatureDate') && (
                    <span className='text-danger'>
                      {getMessage('signatureDate')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div className='mt-4 d-flex justify-content-between'>
            <Button onClick={() => jumpToStep(3)} className='btn-secondary'>
              {t('general.previous')}
            </Button>
            <Button onClick={validateStep} className='btn-primary'>
              {t('general.saveAndSend')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step5;
