import React, { Fragment, useContext } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

// Item
import VeterinarianItem from './VeterinarianItem';

// Context
import { GlobalContext } from '../../store/Provider';

// Translation
import { useTranslation } from 'react-i18next';
import ContainerSpinner from '../layout/ContainerSpinner';

const VeterinariansList = () => {
  const { veterinariansState } = useContext(GlobalContext);
  const match = useRouteMatch();

  const { t } = useTranslation();

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <Link className='btn btn-primary' to={`${match.path}/new`}>
            {t('veterinarians.add')}
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <Card>
            <CardBody>
              {veterinariansState.veterinarians.loading && <ContainerSpinner />}
              {veterinariansState.veterinarians.list.length === 0 ? (
                <h4>{t('veterinarians.notfound')}</h4>
              ) : (
                <Table responsive className='mb-0'>
                  <thead>
                    <tr>
                      <th>{t('general.name')}</th>
                      <th>{t('general.email')}</th>
                      <th>{t('general.phone')}</th>
                      <th>{t('general.address')}</th>
                      <th>{t('general.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {veterinariansState.veterinarians.list.map(veterinarian => (
                      <VeterinarianItem
                        key={veterinarian.id}
                        veterinarian={veterinarian}
                      />
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default VeterinariansList;
