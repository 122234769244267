import React, { useContext } from 'react';

// Load layout components
import Header from '../components/layout/Header';
import Sidebar from '../components/layout/Sidebar';

import { useTranslation } from 'react-i18next';

import ReportingComponent from '../components/reporting/ReportingComponent';

import { GlobalContext } from '../store/Provider';

const Reporting = () => {
  const { layoutState } = useContext(GlobalContext);

  const { t } = useTranslation();

  const getSidebarType = () => {
    if (window.innerWidth >= 768) {
      return layoutState.sidebarOpen ? 'iconbar' : 'mini-sidebar';
    } else {
      return 'mini-sidebar';
    }
  };

  return (
    <div
      id='main-wrapper'
      data-layout='vertical'
      data-sidebartype={getSidebarType()}
      data-sidebarposition='fixed'
      data-header-position='fixed'
      data-boxed-layout='full'
      data-theme='light'
    >
      <Header />
      <Sidebar />
      <div className='page-wrapper d-block'>
        <div className='page-content container-fluid'>
          <h1>{t('reporting.name')}</h1>
          <div className='mt-4'>
            <ReportingComponent />
          </div>
        </div>
      </div>
      {/* Footer */}
    </div>
  );
};

export default Reporting;
