/* IMPORTS */
import React, { useEffect, Fragment } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';





import Dashboard from './routes/Dashboard';
import Stables from './routes/Stables';
import Veterinarians from './routes/Veterinarians';
import Loadingplaces from './routes/Loadingplaces';
import Contributors from './routes/Contributors';
import Offers from './routes/Offers';
import Documents from './routes/Documents';
import Account from './routes/Account';
import About from './routes/About';
import LegalInfo from './routes/LegalInfo';
import Reporting from './routes/Reporting';
import Benchmarking from './routes/Benchmarking';
import Login from './routes/Login';
import Register from './routes/Register';
import ResetPassword from './routes/ResetPassword';

import BlogPage from './components/dashboard/BlogPage';
import PrivateRoute from './components/auth/PrivateRoute';
import OwnerRoute from './components/auth/OwnerRoute';

import './assets/scss/style.css';
import './assets/scss/overrides.css';

//import tooltip scss
import "./assets/scss/tooltip.scss";

const App = () => {
  // Initialize Google Tag Manager
  const tagManagerArgs = {
    gtmId: 'GTM-5CPQSLP',
  };
  TagManager.initialize(tagManagerArgs);

  // Extract the i18n object from useTranslation hook
  const { i18n } = useTranslation();
  /**
   * useEffect hook to set the current app language if language is stored in LocalStorage
   * see https://reactjs.org/docs/hooks-effect.html for documentation on this hook
   */
  useEffect(() => {
    if (localStorage.getItem('language')) {
      i18n.changeLanguage(localStorage.getItem('language'));
    }
    // eslint-disable-next-line
  }, [localStorage.getItem('language')]);

  return (
    <Fragment>
      {/* React router component, used to define routes and render correct components
                      see https://v5.reactrouter.com/web/guides/quick-start 
                      */}
      <Router>
        <Switch>
          {/* Custom Private Route component - only logged in users can visit these routes
                              View component in /components/auth/PrivateRoute.js
                          */}
          <PrivateRoute path='/blog' component={BlogPage} />

          {/* Custom Owner Route component - only logged in users who are owners OR with the correct permissions can visit these routes
                              View component in /components/auth/OwnerRoute.js
                          */}
          <OwnerRoute path='/stables' component={Stables} slug='stables' />
          <OwnerRoute
            path='/veterinarians'
            component={Veterinarians}
            slug='veterinarians'
          />
          <OwnerRoute
            path='/loadingplaces'
            component={Loadingplaces}
            slug='loadingplaces'
          />
          <OwnerRoute
            path='/contributors'
            component={Contributors}
            slug='contributors'
          />
          <OwnerRoute path='/offers' component={Offers} slug='offers' />
          <PrivateRoute path='/documents' component={Documents} />
          <PrivateRoute path='/account' component={Account} />

          <PrivateRoute path='/dashboard' component={Dashboard} />

          <PrivateRoute
            path='/reporting'
            component={Reporting}
            slug='reporting'
          />

          <PrivateRoute
            path='/benchmarking'
            component={Benchmarking}
            slug='benchmarking'
          />

          {/* Regular route which any user can visit */}
          <Route path='/register' component={Register} />
          <Route path='/about' component={About} />
          <Route path='/legal-info' component={LegalInfo} />
          <Route path='/new-password' component={ResetPassword} />

          <Route path='/' component={Login} />
        </Switch>
      </Router>
    </Fragment>
  );
};

export default App;
