import React, { Fragment, useContext } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import ContainerSpinner from '../layout/ContainerSpinner';
import { Link, useRouteMatch } from 'react-router-dom';

// Item
import ContributorItem from './ContributorItem';

// Context
import { GlobalContext } from '../../store/Provider';

// Translation
import { useTranslation } from 'react-i18next';

const ContributorsList = () => {
  const { contributorsState } = useContext(GlobalContext);
  const match = useRouteMatch();

  const { t } = useTranslation();

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <Link className='btn btn-primary' to={`${match.path}/new`}>
            {t('contributors.add')}
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <Card>
            <CardBody>
              {contributorsState.contributors.loading ? (
                <ContainerSpinner />
              ) : (
                ''
              )}
              {contributorsState.contributors.list.length === 0 ? (
                <h4>{t('contributors.notfound')}</h4>
              ) : (
                <Table responsive className='mb-0'>
                  <thead>
                    <tr>
                      <th>{t('general.name')}</th>
                      <th>{t('general.email')}</th>
                      <th>{t('general.phone')}</th>
                      <th>{t('general.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contributorsState.contributors.list.map(contributor => (
                      <ContributorItem
                        key={contributor.uid}
                        contributor={contributor}
                      />
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ContributorsList;
