import React, { Fragment, useContext, useEffect, useState } from 'react';
import LoadingplaceResearchItem from './LoadingplaceResearchItem';
import LoadingplaceResearchForm from './LoadingplaceResearchForm';
import { useHistory } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Table,
  Button,
  Label,
} from 'reactstrap';
import CountryCheckbox from '../layout/CountryCheckbox';
import ContainerSpinner from '../layout/ContainerSpinner';

// Context
import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import {
  clearCurrentLoadingplace,
  updateLoadingplace,
  setLPLoading,
  getLoadingplaces,
} from '../../store/actions/loadingplaces';

// Translation
import { useTranslation } from 'react-i18next';

const LoadingplacesDetail = () => {
  const {
    veterinariansState,
    stablesState,
    alertsDispatch,
    loadingplacesState,
    loadingplacesDispatch,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();

  const listOfCountries = [
    { ISO: 'KR', label: t('countries.South-Korea') },
    { ISO: 'ZA', label: t('countries.South-Africa') },
    { ISO: 'JP', label: t('countries.Japan') },
    { ISO: 'CN', label: t('countries.China') },
    { ISO: 'UA', label: t('countries.Ukraine') },
    { ISO: 'CA', label: t('countries.Canada') },
    { ISO: 'AU', label: t('countries.Australia') },
    { ISO: 'NZ', label: t('countries.New-Zealand') },
    { ISO: 'PH', label: t('countries.Philippines') },
    { ISO: 'UY', label: t('countries.Uruguay') },
    { ISO: 'SG', label: t('countries.Singapore') },
    { ISO: 'IN', label: t('countries.India') },
    { ISO: 'PE', label: t('countries.Peru') },
    { ISO: 'CU', label: t('countries.Cuba') },
    { ISO: 'PF', label: t('countries.French-Polynesia') },
    { ISO: 'TW', label: t('countries.Taiwan') },
    { ISO: 'MD', label: t('countries.Moldavia') },
    { ISO: 'RS', label: t('countries.Serbia') },
    { ISO: 'BA', label: t('countries.Bosnia') },
    { ISO: 'CL', label: t('countries.Chile') },
    { ISO: 'MY', label: t('countries.Malaysia') },
    { ISO: 'MX', label: t('countries.Mexico') },
    { ISO: 'ME', label: t('countries.Montenegro') },
    { ISO: 'MK', label: t('countries.North-Macedonia') },
    { ISO: 'GB', label: t('countries.United-Kingdom') },
  ];

  const {
    lp_code,
    address_line1,
    postcode,
    locality,
    default_veterinarian,
    field_default_countries,
    researches,
    field_research_carried_out,
    field_type_of_company,
  } = loadingplacesState.loadingplaces.current;

  const [selectedVeterinarian, setSelectedVeterinarian] = useState(
    default_veterinarian ? default_veterinarian : ''
  );
  const [selectedCountries, setSelectedCountries] = useState(
    field_default_countries ? field_default_countries.split(' ') : ''
  );

  const [investigated, setInvestigated] = useState(
    field_research_carried_out === '' ? 1 : Number(field_research_carried_out)
  );
  const [typeOfCompany, setTypeOfCompany] = useState(
    field_type_of_company === '' ? 0 : Number(field_type_of_company)
  );

  const [researchLoading, setResearchLoading] = useState(false);

  useEffect(() => {
    getLoadingplaces()(loadingplacesDispatch)
    setSelectedVeterinarian(default_veterinarian ? default_veterinarian : '');
    setSelectedCountries(field_default_countries ? field_default_countries.split(' ') : '' );
  }, [default_veterinarian, field_default_countries]);

  const onSubmit = async e => {
    e.preventDefault();

    setLPLoading(true)(loadingplacesDispatch);

    const newDefaultVeterinarian = () => {
      if (selectedVeterinarian) {
        return veterinariansState.veterinarians.list.find(veterinarian => {
          return parseInt(veterinarian.id) === parseInt(selectedVeterinarian);
        });
      } else {
        return null;
      }
    };

    let countryString;

    if (selectedCountries.length > 1) {
      countryString = selectedCountries.join(' ');
    } else if (selectedCountries.length === 1) {
      countryString = selectedCountries[0];
    } else {
      countryString = '';
    }

    const res = await updateLoadingplace(
      newDefaultVeterinarian(),
      countryString,
      investigated,
      typeOfCompany,
      loadingplacesState.loadingplaces.current
    )(loadingplacesDispatch);

    setLPLoading(false)(loadingplacesDispatch);
    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    } else if (res.code === 504) {
      setAlert(res.msg, 'warning')(alertsDispatch);
    } else {
      setAlert(res.msg, 'success')(alertsDispatch);
    }
  };

  const handleVeterinarianChange = e => {
    setSelectedVeterinarian(e.target.value);
  };

  const redirect = () => {
    clearCurrentLoadingplace()(loadingplacesDispatch);
    history.push('/loadingplaces');
  };

  const checkStables = () => {
    let connectedStables = [];
    if (stablesState.stables.list.length > 0) {
      stablesState.stables.list.forEach(stable => {
        if (stable.lp_code === lp_code) {
          connectedStables.push(stable);
        }
      });
    }

    return connectedStables;
  };

  const handleCountryCheckboxChange = e => {
    let countries = selectedCountries;
    if (countries.includes(e.target.value)) {
      countries = countries.filter(country => country !== e.target.value);
      setSelectedCountries([...countries]);
    } else {
      setSelectedCountries([...countries, e.target.value]);
    }
  };

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <button className='btn btn-primary' onClick={redirect}>
            {t('general.prev')}
          </button>
        </Col>
      </Row>
      <Row>
        <Col xs='12' md='12' lg='4'>
          <Card>
            <CardBody>
              <div className='text-center py-3'>
                <CardTitle>{lp_code}</CardTitle>
                <CardSubtitle className='mb-0'>
                  <i className='icon-location-pin'></i>
                  &nbsp;
                  <span>
                    {address_line1}, {postcode} {locality}
                  </span>
                </CardSubtitle>
              </div>
            </CardBody>
            <CardBody className='border-top'>
              {loadingplacesState.loadingplaces.loading && <ContainerSpinner />}
              <div>
                <Form className='mt-2' onSubmit={onSubmit}>
                  <FormGroup>
                    <Label htmlFor='standardVeterinarian'>
                      {t('loadingplaces.standardVeterinarian')}
                    </Label>
                    {veterinariansState.veterinarians.list.length > 0 ||
                    veterinariansState.veterinarians.list !== null ? (
                      <Input
                        name='standardVeterinarian'
                        type='select'
                        value={selectedVeterinarian}
                        onChange={handleVeterinarianChange}
                      >
                        <option disabled='disabled' value=''>
                          {t('general.makeChoice')}
                        </option>
                        {veterinariansState.veterinarians.list.map(item => {
                          return (
                            <option key={item.id} value={item.id}>
                              {decodeStr(item.title)}
                            </option>
                          );
                        })}
                      </Input>
                    ) : (
                      <Input type='select' value='' readOnly>
                        <option>{t('veterinarians.notfound')}</option>
                      </Input>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>{t('loadingplaces.standardExportCountries')}</Label>

                    <Row className='pl-3 pr-3'>
                      {listOfCountries.map(country => {
                        return (
                          <CountryCheckbox
                            key={country.ISO}
                            ISO={country.ISO}
                            label={country.label}
                            onChange={handleCountryCheckboxChange}
                            checked={
                              selectedCountries &&
                              selectedCountries.includes(country.ISO)
                            }
                          />
                        );
                      })}
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label>{t('vki.investigationsCarriedOutDesc')}</Label>
                    <Row className='pl-3 pr-3'>
                      <div className='form-inline'>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='investigated'
                            name='investigated'
                            value='1'
                            id='investigatedRadioYes'
                            onChange={() => {
                              setInvestigated(1);
                            }}
                            checked={investigated === 1}
                          />
                          <Label for='investigatedRadioYes'>
                            {t('general.yes')}
                          </Label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='investigated'
                            name='investigated'
                            value='2'
                            id='investigatedRadioNo'
                            onChange={() => {
                              setInvestigated(2);
                            }}
                            checked={investigated === 2}
                          />
                          <Label for='investigatedRadioNo'>
                            {t('general.no')}
                          </Label>
                        </div>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row className='pl-3 pr-3'>
                      <div className='form-inline'>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='typeOfCompany'
                            name='typeOfCompany'
                            value='1'
                            id='typeOfCompanyRadio1'
                            onChange={() => setTypeOfCompany(1)}
                            checked={typeOfCompany === 1}
                          />
                          <Label for='typeOfCompanyRadio1'>
                            {t('vki.companyTypeRisk')}
                          </Label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='typeOfCompany'
                            name='typeOfCompany'
                            value='2'
                            id='typeOfCompanyRadio2'
                            onChange={() => setTypeOfCompany(2)}
                            checked={typeOfCompany === 2}
                          />
                          <Label for='typeOfCompanyRadio2'>
                            {t('vki.companyTypeNoRisk')}
                          </Label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <Input
                            className='form-check-input'
                            type='radio'
                            data-field='typeOfCompany'
                            name='typeOfCompany'
                            value='3'
                            id='typeOfCompanyRadio3'
                            onChange={() => setTypeOfCompany(3)}
                            checked={typeOfCompany === 3}
                          />
                          <Label for='typeOfCompanyRadio3'>
                            {t('vki.companyTypeFittings')}
                          </Label>
                        </div>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Button className='btn-primary'>{t('general.save')}</Button>
                  </FormGroup>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs='12' md='12' lg='8'>
          <Card>
            <CardBody>
              <CardTitle>{t('stables.name')}</CardTitle>
              {checkStables().length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t('general.accessFrom')}</th>
                      <th>{t('general.accessTo')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkStables().map(stable => {
                      return (
                        <tr key={stable.id}>
                          <th scope='row'>{stable.id}</th>
                          <td>{stable.access_from}</td>
                          <td>{stable.access_to}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                'Geen stallen'
              )}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              {researchLoading && <ContainerSpinner />}
              <CardTitle>{t('loadingplaces.researches.title')}</CardTitle>
            </CardBody>

            <CardBody className='border-top'>
              <Table className='table table-hover table-condensed mb-0'>
                <thead>
                  <tr>
                    <th>{t('loadingplaces.researches.type')}</th>
                    <th>{t('loadingplaces.researches.date')}</th>
                    <th>{t('loadingplaces.researches.result')}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <LoadingplaceResearchForm
                    setResearchLoading={setResearchLoading}
                  />
                  {researches.map(row => {
                    return <LoadingplaceResearchItem key={row.ID} row={row} />;
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoadingplacesDetail;
