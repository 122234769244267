import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Table,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { compareDateStrings } from '../../../utils/Utils';

// Translation
import { useTranslation } from 'react-i18next';

const Step3 = ({ vki, setVKI, jumpToStep }) => {
  const { t } = useTranslation();

  const {
    departureDate,
    antiparasiticDrugs,
    antibiotics,
    antiInflamatoryDrugs,
    nameMedication1,
    dateFromMedication1,
    dateUntilMedication1,
    durationMedication1,
    nameMedication2,
    dateFromMedication2,
    dateUntilMedication2,
    durationMedication2,
    nameMedication3,
    dateFromMedication3,
    dateUntilMedication3,
    durationMedication3,
    deaths,
    causeOfDeaths,
    sickness,
    causeOfSickness,
  } = vki;

  const onChange = e =>
    setVKI({ ...vki, [e.target.getAttribute('data-field')]: e.target.value });

  const [invalidFields, setInvalidFields] = useState([]);

  const validateStep = async () => {
    let falsyFields = [];

    if (!antiparasiticDrugs || antiparasiticDrugs === '') {
      falsyFields.push({
        field: 'antiparasiticDrugs',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (!antibiotics || antibiotics === '') {
      falsyFields.push({
        field: 'antibiotics',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (!antiInflamatoryDrugs || antiInflamatoryDrugs === '') {
      falsyFields.push({
        field: 'antiInflamatoryDrugs',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (
      dateFromMedication1 &&
      dateFromMedication1 !== '' &&
      compareDateStrings(dateFromMedication1, departureDate)
    ) {
      falsyFields.push({
        field: 'dateFromMedication1',
        msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
      });
    }

    if (dateUntilMedication1 && dateUntilMedication1 !== '') {
      if (compareDateStrings(dateUntilMedication1, departureDate)) {
        falsyFields.push({
          field: 'dateUntilMedication1',
          msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
        });
      } else if (
        compareDateStrings(dateFromMedication1, dateUntilMedication1)
      ) {
        falsyFields.push({
          field: 'dateUntilMedication1',
          msg: t('forms.fieldErrors.dateMustBeLaterThanStartDate'),
        });
      }
    }

    if (
      dateFromMedication2 &&
      dateFromMedication2 !== '' &&
      compareDateStrings(dateFromMedication2, departureDate)
    ) {
      falsyFields.push({
        field: 'dateFromMedication2',
        msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
      });
    }

    if (dateUntilMedication2 && dateUntilMedication2 !== '') {
      if (compareDateStrings(dateUntilMedication2, departureDate)) {
        falsyFields.push({
          field: 'dateUntilMedication2',
          msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
        });
      } else if (
        compareDateStrings(dateFromMedication2, dateUntilMedication2)
      ) {
        falsyFields.push({
          field: 'dateUntilMedication2',
          msg: t('forms.fieldErrors.dateMustBeLaterThanStartDate'),
        });
      }
    }

    if (
      dateFromMedication3 &&
      dateFromMedication3 !== '' &&
      compareDateStrings(dateFromMedication3, departureDate)
    ) {
      falsyFields.push({
        field: 'dateFromMedication3',
        msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
      });
    }

    if (dateUntilMedication3 && dateUntilMedication3 !== '') {
      if (compareDateStrings(dateUntilMedication3, departureDate)) {
        falsyFields.push({
          field: 'dateUntilMedication3',
          msg: t('forms.fieldErrors.dateMustBeBeforeDepartureDate'),
        });
      } else if (
        compareDateStrings(dateFromMedication3, dateUntilMedication3)
      ) {
        falsyFields.push({
          field: 'dateUntilMedication3',
          msg: t('forms.fieldErrors.dateMustBeLaterThanStartDate'),
        });
      }
    }

    if (!deaths || deaths === '0') {
      falsyFields.push({
        field: 'deaths',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (!sickness || sickness === '0') {
      falsyFields.push({
        field: 'sickness',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (falsyFields.length === 0) {
      jumpToStep(3);
    } else {
      setInvalidFields(falsyFields);
    }
  };

  const checkField = fieldName => {
    return invalidFields.find(field => field.field === fieldName)
      ? true
      : false;
  };

  const getMessage = fieldName => {
    return invalidFields.find(field => field.field === fieldName).msg;
  };

  return (
    <div className='step step1 mt-5 mb-5'>
      <Row className='justify-content-md-center mt-5'>
        <Col lg='6'>
          {invalidFields.length > 0 && (
            <div className='alert alert-danger fade show' role='alert'>
              {t('forms.fieldErrors.checkFields')}
            </div>
          )}
          <Form
            className='form-horizontal form-material mt-2'
            onSubmit={e => e.preventDefault()}
          >
            <h4>{t('vki.medicationAndAdditives')}</h4>
            <Row className='mt-4'>
              <Col md='12' className='mt-3'>
                <p>{t('vki.infoMedication')}</p>
              </Col>
              <Col md='12' className='mt-1'>
                <h5>
                  {t('vki.antiparasiticDrugs')}{' '}
                  <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='antiparasiticDrugs'
                      name='antiparasiticDrugs'
                      value='1'
                      id='antiparasiticDrugsRadio1'
                      onChange={onChange}
                      checked={antiparasiticDrugs === '1'}
                    />
                    <Label for='antiparasiticDrugsRadio1'>
                      {t('general.yes')}
                    </Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='antiparasiticDrugs'
                      name='antiparasiticDrugs'
                      value='2'
                      id='antiparasiticDrugsRadio2'
                      onChange={onChange}
                      checked={antiparasiticDrugs === '2'}
                    />
                    <Label for='antiparasiticDrugsRadio2'>
                      {t('general.no')}
                    </Label>
                  </div>
                </div>
                {checkField('antiparasiticDrugs') && (
                  <span className='text-danger'>
                    {getMessage('antiparasiticDrugs')}
                  </span>
                )}
              </Col>

              <Col md='12' className='mt-3'>
                <h5>
                  {t('vki.antibiotics')} <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='antibiotics'
                      name='antibiotics'
                      value='1'
                      id='antibioticsRadio1'
                      onChange={onChange}
                      checked={antibiotics === '1'}
                    />
                    <Label for='antibioticsRadio1'>{t('general.yes')}</Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='antibiotics'
                      name='antibiotics'
                      value='2'
                      id='antibioticsRadio2'
                      onChange={onChange}
                      checked={antibiotics === '2'}
                    />
                    <Label for='antibioticsRadio2'>{t('general.no')}</Label>
                  </div>
                </div>
                {checkField('antibiotics') && (
                  <span className='text-danger'>
                    {getMessage('antibiotics')}
                  </span>
                )}
              </Col>

              <Col md='12' className='mt-3'>
                <h5>
                  {t('vki.antiInflamatoryDrugs')}{' '}
                  <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='antiInflamatoryDrugs'
                      name='antiInflamatoryDrugs'
                      value='1'
                      id='antiInflamatoryDrugsRadio1'
                      onChange={onChange}
                      checked={antiInflamatoryDrugs === '1'}
                    />
                    <Label for='antiInflamatoryDrugsRadio1'>
                      {t('general.yes')}
                    </Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='antiInflamatoryDrugs'
                      name='antiInflamatoryDrugs'
                      value='2'
                      id='antiInflamatoryDrugsRadio2'
                      onChange={onChange}
                      checked={antiInflamatoryDrugs === '2'}
                    />
                    <Label for='antiInflamatoryDrugsRadio2'>
                      {t('general.no')}
                    </Label>
                  </div>
                </div>
                {checkField('antiInflamatoryDrugs') && (
                  <span className='text-danger'>
                    {getMessage('antiInflamatoryDrugs')}
                  </span>
                )}
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col md='12'>
                <p>{t('vki.infoExtraMedication')}</p>
              </Col>
              <Table className='mt-1'>
                <thead>
                  <tr>
                    <th>{t('vki.nameMedication')}</th>
                    <th>{t('vki.dateAdministrationMedication')}</th>
                    <th>{t('vki.dateUntilAdministrationMedication')}</th>
                    <th>{t('vki.waitingDurationMedication')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        name='nameMedication1'
                        data-field='nameMedication1'
                        autoComplete='off'
                        type='text'
                        defaultValue={nameMedication1}
                        onChange={onChange}
                      />
                    </td>
                    <td>
                      <Input
                        name='dateFromMedication1'
                        data-field='dateFromMedication1'
                        autoComplete='off'
                        type='date'
                        defaultValue={dateFromMedication1}
                        onChange={onChange}
                      />
                      {checkField('dateFromMedication1') && (
                        <span className='text-danger'>
                          {getMessage('dateFromMedication1')}
                        </span>
                      )}
                    </td>
                    <td>
                      <Input
                        name='dateUntilMedication1'
                        data-field='dateUntilMedication1'
                        autoComplete='off'
                        type='date'
                        defaultValue={dateUntilMedication1}
                        onChange={onChange}
                      />
                      {checkField('dateUntilMedication1') && (
                        <span className='text-danger'>
                          {getMessage('dateUntilMedication1')}
                        </span>
                      )}
                    </td>
                    <td>
                      <Input
                        name='durationMedication1'
                        data-field='durationMedication1'
                        autoComplete='off'
                        type='text'
                        defaultValue={durationMedication1}
                        onChange={onChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input
                        name='nameMedication2'
                        data-field='nameMedication2'
                        autoComplete='off'
                        type='text'
                        defaultValue={nameMedication2}
                        onChange={onChange}
                      />
                    </td>
                    <td>
                      <Input
                        name='dateFromMedication2'
                        data-field='dateFromMedication2'
                        autoComplete='off'
                        type='date'
                        defaultValue={dateFromMedication2}
                        onChange={onChange}
                      />
                      {checkField('dateFromMedication2') && (
                        <span className='text-danger'>
                          {getMessage('dateFromMedication2')}
                        </span>
                      )}
                    </td>
                    <td>
                      <Input
                        name='dateUntilMedication2'
                        data-field='dateUntilMedication2'
                        autoComplete='off'
                        type='date'
                        defaultValue={dateUntilMedication2}
                        onChange={onChange}
                      />
                      {checkField('dateUntilMedication2') && (
                        <span className='text-danger'>
                          {getMessage('dateUntilMedication2')}
                        </span>
                      )}
                    </td>
                    <td>
                      <Input
                        name='durationMedication2'
                        data-field='durationMedication2'
                        autoComplete='off'
                        type='text'
                        defaultValue={durationMedication2}
                        onChange={onChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input
                        name='nameMedication3'
                        data-field='nameMedication3'
                        autoComplete='off'
                        type='text'
                        defaultValue={nameMedication3}
                        onChange={onChange}
                      />
                    </td>
                    <td>
                      <Input
                        name='dateFromMedication3'
                        data-field='dateFromMedication3'
                        autoComplete='off'
                        type='date'
                        defaultValue={dateFromMedication3}
                        onChange={onChange}
                      />
                      {checkField('dateFromMedication3') && (
                        <span className='text-danger'>
                          {getMessage('dateFromMedication3')}
                        </span>
                      )}
                    </td>
                    <td>
                      <Input
                        name='dateUntilMedication3'
                        data-field='dateUntilMedication3'
                        autoComplete='off'
                        type='date'
                        defaultValue={dateUntilMedication3}
                        onChange={onChange}
                      />
                      {checkField('dateUntilMedication3') && (
                        <span className='text-danger'>
                          {getMessage('dateUntilMedication3')}
                        </span>
                      )}
                    </td>
                    <td>
                      <Input
                        name='durationMedication3'
                        data-field='durationMedication3'
                        autoComplete='off'
                        type='text'
                        defaultValue={durationMedication3}
                        onChange={onChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>

            <h4 className='mt-4'>{t('vki.problemsIdentifiedStables')}</h4>

            <Row className='mt-4'>
              <Col md='6'>
                <h5>
                  {t('vki.deaths')} <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='deaths'
                      name='deaths'
                      value='1'
                      id='deathsRadio1'
                      onChange={onChange}
                      checked={deaths === '1'}
                    />
                    <Label for='deathsRadio1'>{t('general.yes')}</Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='deaths'
                      name='deaths'
                      value='2'
                      id='deathsRadio2'
                      onChange={onChange}
                      checked={deaths === '2'}
                    />
                    <Label for='deathsRadio2'>{t('general.no')}</Label>
                  </div>
                </div>
                {checkField('deaths') && (
                  <span className='text-danger'>{getMessage('deaths')}</span>
                )}
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='causeOfDeaths'>
                    {t('vki.causeOfDeaths')}
                  </Label>
                  <Input
                    type='text'
                    value={causeOfDeaths}
                    data-field='causeOfDeaths'
                    name='causeOfDeaths'
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col md='6'>
                <h5>
                  {t('vki.sickness')} <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='sickness'
                      name='sickness'
                      value='1'
                      id='sicknessRadio1'
                      onChange={onChange}
                      checked={sickness === '1'}
                    />
                    <Label for='sicknessRadio1'>{t('general.yes')}</Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='sickness'
                      name='sickness'
                      value='2'
                      id='sicknessRadio2'
                      onChange={onChange}
                      checked={sickness === '2'}
                    />
                    <Label for='sicknessRadio2'>{t('general.no')}</Label>
                  </div>
                </div>
                {checkField('sickness') && (
                  <span className='text-danger'>{getMessage('sickness')}</span>
                )}
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='causeOfSickness'>
                    {t('vki.causeOfSickness')}
                  </Label>
                  <Input
                    type='text'
                    value={causeOfSickness}
                    data-field='causeOfSickness'
                    name='causeOfSickness'
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <div className='mt-4 d-flex justify-content-between'>
            <Button onClick={() => jumpToStep(1)} className='btn-secondary'>
              {t('general.previous')}
            </Button>
            <Button onClick={validateStep} className='btn-primary'>
              {t('general.next')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step3;
