import axios from 'axios';
import i18next from 'i18next';

export const setFetching = value => dispatch => {
  dispatch({
    type: 'SET_FETCHING',
    payload: value,
  });
};

export const getStables = () => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {
    const res = await axios.get('/jsonapi/entity/stables?_format=json');

    let stables = res.data;
    stables.forEach(stable => {
      stable.users = JSON.parse(stable.users);
    });

    dispatch({
      type: 'GET_STABLES',
      payload: stables,
      // payload: res.data,
    });
    response.code = 200;
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.stables.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

export const addContributorToStable =
  (user, stableId, ownerId) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    try {
      const formData = {
        user_id: user.uid,
        group_id: stableId,
        owner_id: ownerId,
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await axios.post(
        '/jsonapi/stableuser?_format=json',
        JSON.stringify(formData),
        config
      );

      dispatch({
        type: 'ADD_CONTRIBUTOR_TO_STABLE',
        payload: { user: user, stable_id: stableId },
      });
      response.code = 200;
      response.msg = i18next.t(
        'alerts.success.stables.addedContributorToStable'
      );
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t(
          'alerts.errors.stables.couldNotAddContributor'
        );
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const removeContributorFromStable =
  (user_id, group_id, owner_id) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    const formData = {
      user_id: user_id,
      group_id: group_id,
      owner_id: owner_id,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.post(
        '/jsonapi/removefromgroup?_format=json',
        JSON.stringify(formData),
        config
      );
      dispatch({
        type: 'REMOVE_CONTRIBUTOR_FROM_STABLE',
        payload: { user_id: user_id, group_id: group_id },
      });
      response.code = 200;
      response.msg = i18next.t(
        'alerts.success.stables.removedContributorFromStable'
      );
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t(
          'alerts.errors.stables.couldNotRemoveContributor'
        );
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const setCurrentStable = stable => dispatch => {
  dispatch({
    type: 'SET_CURRENT_STABLE',
    payload: stable,
  });
};

export const clearCurrentStable = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_STABLE' });
};

export const setLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const clearStableState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};
