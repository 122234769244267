/* eslint-disable import/no-anonymous-default-export */
export default {
  access_token: null,
  logout_token: null,
  csrf_token: null,
  user: null,
  user_data: null,
  permissions: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};
