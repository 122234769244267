/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_ALERT':
      return produce(state, draft => {
        draft.alerts.push({ ...payload });
      });
    case 'REMOVE_ALERT':
      return produce(state, draft => {
        const index = draft.alerts.findIndex(alert => alert.id === payload);
        draft.alerts.splice(index, 1);
      });
    default:
      return state;
  }
};
