/* eslint-disable import/no-anonymous-default-export */

import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_FETCHING':
      return produce(state, draft => {
        draft.veterinarians.fetching = payload;
      });

    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.veterinarians.list = [];
        draft.veterinarians.loading = false;
        draft.veterinarians.current = null;
        draft.veterinarians.error = null;
        draft.veterinarians.fetching = true;
      });
    case 'GET_VETERINARIANS':
      return produce(state, draft => {
        draft.veterinarians.list = payload;
        draft.veterinarians.loading = false;
        draft.veterinarians.fetching = false;
      });
    case 'ADD_VETERINARIAN':
      return produce(state, draft => {
        draft.veterinarians.list.push({ ...payload });
        draft.veterinarians.loading = false;
      });
    case 'UPDATE_VETERINARIAN':
      return produce(state, draft => {
        draft.veterinarians.loading = false;
        const index = draft.veterinarians.list.findIndex(
          veterinarian => veterinarian.id === payload.id
        );
        draft.veterinarians.list[index] = payload;
      });
    case 'DELETE_VETERINARIAN':
      return produce(state, draft => {
        draft.veterinarians.loading = false;
        const index = draft.veterinarians.list.findIndex(
          veterinarian => veterinarian.id === payload
        );
        draft.veterinarians.list.splice(index, 1);
      });
    case 'SET_CURRENT_VETERINARIAN':
      return produce(state, draft => {
        draft.veterinarians.current = payload;
      });
    case 'CLEAR_CURRENT_VETERINARIAN':
      return produce(state, draft => {
        draft.veterinarians.current = null;
      });
    case 'VETERINARIAN_ERROR':
      return produce(state, draft => {
        draft.veterinarians.error = payload;
      });
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.veterinarians.loading = payload;
      });
    default:
      return state;
  }
};
