import axios from 'axios';
import i18next from 'i18next';

export const loadActiveSupplier = (uid, name) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  const encodedString = Buffer.from(`${uid}-${name}`).toString('base64');

  try {
    const res = await axios.get(`/suppliers/getactive?q=${encodedString}`);

    const data = res.data;

    dispatch({
      type: 'SET_ACTIVE_SUPPLIER',
      payload: {
        id: data.id,
        uuid: data.uuid,
      },
    });

    response.code = 200;
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t(
        'alerts.errors.suppliers.failedToLoadActiveSupplier'
      );
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

export const loadAllSuppliers = (uid, name) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };
  const encodedString = Buffer.from(`${uid}-${name}`).toString('base64');

  try {
    const res = await axios.get(`/suppliers/getoptions?q=${encodedString}`);

    dispatch({
      type: 'SET_ALL_SUPPLIERS',
      payload: res.data,
    });
    response.code = 200;
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t(
        'alerts.errors.suppliers.failedToFetchAllSuppliers'
      );
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

export const setActiveSupplier =
  (supplier, uuid, uid, name) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };
    const encodedString = Buffer.from(`${uid}-${name}`).toString('base64');

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const params = new URLSearchParams();
    params.append('q', encodedString);
    params.append('s', supplier);

    try {
      await axios.post(`/suppliers/setactive`, params, config);

      dispatch({
        type: 'SET_ACTIVE_SUPPLIER',
        payload: {
          id: supplier,
          uuid: uuid,
        },
      });
      response.code = 200;
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t(
          'alerts.errors.suppliers.failedToSwitchSupplier'
        );
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const setSupplierLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};


export const clearSupplierState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};
