export const decodeStr = str => {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
};

export const filterDateStringToTimestamp = dateString => {
  const date = dateString.split('-');
  return new Date(date[0], date[1] - 1, date[2], 0, 0, 0, 0).getTime() / 1000;
};

export const docDateStringToTimestamp = dateString => {
  const date = dateString.split('/');
  return new Date(date[2], date[1] - 1, date[0], 0, 0, 0, 0).getTime() / 1000;
};

export const formatVKIDate = date => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formatVKIDateString = dateString => {
  const dateArray = dateString.split('-');
  return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

const dateStringToDate = dateString => {
  const dateArray = dateString.split('-');
  const date = new Date(
    dateArray[0],
    dateArray[1] - 1,
    dateArray[2],
    0,
    0,
    0,
    0
  );
  return date;
};

export const checkStringDatePast = dateString => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const date = dateStringToDate(dateString);
  return today > date;
};

export const checkStringDateInFuture = dateString => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const date = dateStringToDate(dateString);
  return today < date;
};

export const checkStringDateTodayOrInFuture = dateString => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const date = dateStringToDate(dateString);
  return today <= date;
};

export const checkStringDateMaxDaysInFuture = (dateString, days) => {
  const dateInFuture = new Date();
  dateInFuture.setHours(0, 0, 0, 0);
  dateInFuture.setDate(dateInFuture.getDate() + days);
  const date = dateStringToDate(dateString);

  return date <= dateInFuture;
};

export const compareDateStrings = (firstDateString, secondDateString) => {
  return (
    dateStringToDate(firstDateString) >= dateStringToDate(secondDateString)
  );
};

const getWeek = dateToParse => {
  var date = new Date(dateToParse.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  var week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

const getWeekYear = dateToParse => {
  var date = new Date(dateToParse.getTime());
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  return date.getFullYear();
};

export const getWeekOptions = () => {
  let options = [];
  const now = new Date();
  const today = now.getDay();
  const thisMonday = new Date();
  const thisFriday = new Date();

  if (today === 0 || today === 6) {
    // Saturday, get next monday and friday
    thisMonday.setDate(thisMonday.getDate() + ((1 + 7 - now.getDay()) % 7));

    thisFriday.setDate(now.getDate() + ((5 + 7 - now.getDay()) % 7));
  } else if (today === 1) {
    // Monday, get next friday
    thisFriday.setDate(now.getDate() + ((5 + 7 - now.getDay()) % 7));
  } else if (today === 5) {
    // Friday, get last monday
    thisMonday.setDate(now.getDate() - ((now.getDay() + 6) % 7));
  } else {
    // Tuesday, Wednesday and Thursday
    // Get last monday and next friday
    thisMonday.setDate(now.getDate() - ((now.getDay() + 6) % 7));

    thisFriday.setDate(now.getDate() + ((5 + 7 - now.getDay()) % 7));
  }

  options.push({
    week: getWeek(thisMonday),
    year: getWeekYear(thisMonday),
    monday: thisMonday,
    friday: thisFriday,
  });

  for (let i = 0; i < 4; i++) {
    let mondayToParse = new Date();
    mondayToParse.setTime(thisMonday.getTime() + 604800000 * (i + 1));

    let fridayToParse = new Date();
    fridayToParse.setTime(thisFriday.getTime() + 604800000 * (i + 1));

    options.push({
      week: getWeek(mondayToParse),
      year: getWeekYear(mondayToParse),
      monday: mondayToParse,
      friday: fridayToParse,
    });
  }

  return options;
};
