/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_CURRENT_ARTICLE':
      return produce(state, draft => {
        const index = draft.articles.list.findIndex(
          article => article.nid === payload
        );
        draft.articles.current = draft.articles.list[index];
      });
    case 'CLEAR_CURRENT_ARTICLE':
      return produce(state, draft => {
        draft.articles.current = null;
      });
    case 'SET_ARTICLES':
      return produce(state, draft => {
        draft.articles.list = payload;
      });
    default:
      return state;
  }
};
