/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_FETCHING':
      return produce(state, draft => {
        draft.loadingplaces.fetching = payload;
      });

    case 'ADD_RESEARCH':
      return produce(state, draft => {
        const index = draft.loadingplaces.list.findIndex(
          loadingplace => Number(loadingplace.id) === payload.loading_place_id
        );
        draft.loadingplaces.list[index].researches = insertResearch(
          draft.loadingplaces.list[index].researches,
          payload
        );
        draft.loadingplaces.current.researches = insertResearch(
          draft.loadingplaces.current.researches,
          payload
        );
      });

    case 'REMOVE_RESEARCH':
      return produce(state, draft => {
        const loadingplaceIndex = draft.loadingplaces.list.findIndex(
          loadingplace => Number(loadingplace.id) === payload.loading_place_id
        );
        const researchIndex = draft.loadingplaces.list[
          loadingplaceIndex
        ].researches.findIndex(
          research => Number(research.ID) !== Number(payload.ID)
        );
        draft.loadingplaces.list[loadingplaceIndex].researches.splice(
          researchIndex,
          1
        );
        draft.loadingplaces.current.researches.splice(researchIndex, 1);
      });

    case 'REMOVE_DEFAULT_VETERINARIAN':
      return produce(state, draft => {
        draft.loadingplaces.list.map(loadingplace =>
          loadingplace.default_veterinarian === payload.id
            ? { ...loadingplace, default_veterinarian: '' }
            : loadingplace
        );
      });

    case 'UPDATE_LOADINGPLACE':
      return produce(state, draft => {
        draft.loadingplaces.list.map(loadingplace =>
          loadingplace.id === payload.id ? payload : loadingplace
        );
        draft.loadingplaces.current = payload;
      });

    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.loadingplaces.list = [];
        draft.loadingplaces.loading = false;
        draft.loadingplaces.current = null;
        draft.loadingplaces.error = null;
        draft.loadingplaces.fetching = true;
      });

    case 'GET_LOADINGPLACES':
      return produce(state, draft => {
        draft.loadingplaces.list = payload;
        draft.loadingplaces.loading = false;
        draft.loadingplaces.fetching = false;
      });

    case 'LOADINGPLACE_ERROR':
      return produce(state, draft => {
        draft.loadingplaces.error = payload;
      });
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.loadingplaces.loading = payload;
      });
    case 'SET_CURRENT_LOADINGPLACE':
      return produce(state, draft => {
        draft.loadingplaces.current = payload;
      });
    case 'CLEAR_CURRENT_LOADINGPLACE':
      return produce(state, draft => {
        draft.loadingplaces.current = null;
      });
    default:
      return state;
  }
};

const insertResearch = (array, insert) => {
  let newArray = [...array];
  const index = newArray.findIndex(value => {
    return value.field_research_date <= insert.field_research_date;
  });

  if (index === -1) {
    newArray.push(insert);
  } else {
    newArray.splice(index, 0, insert);
  }

  return newArray;
};
