import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import {
  checkStringDatePast,
  // checkStringDateInFuture,
  checkStringDateTodayOrInFuture,
  checkStringDateMaxDaysInFuture,
} from '../../../utils/Utils';

// Translation
import { useTranslation } from 'react-i18next';

const Step2 = ({ vki, setVKI, jumpToStep }) => {
  const { t } = useTranslation();

  const {
    amountOfPigs,
    setupDate,
    serialNumber,
    departureDate,
    typeOfPigs,
    outdoors,
    controlledHousing,
  } = vki;

  const onChange = e =>
    setVKI({ ...vki, [e.target.getAttribute('data-field')]: e.target.value });

  const [invalidFields, setInvalidFields] = useState([]);

  const validateStep = async () => {
    let falsyFields = [];

    if (!amountOfPigs) {
      falsyFields.push({
        field: 'amountOfPigs',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    } else if (isNaN(amountOfPigs)) {
      falsyFields.push({
        field: 'amountOfPigs',
        msg: t('forms.fieldErrors.expectedANumericValue'),
      });
    }

    
    if(typeOfPigs && typeOfPigs !== '2') {
      if (!setupDate) {
        falsyFields.push({
          field: 'setupDate',
          msg: t('forms.fieldErrors.fieldRequired'),
        });
      } else if (!checkStringDatePast(setupDate)) {
        falsyFields.push({
          field: 'setupDate',
          msg: t('forms.fieldErrors.dateMustBeInPast'),
        });
      }
    }


    if (!departureDate) {
      falsyFields.push({
        field: 'departureDate',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    } else if (!checkStringDateTodayOrInFuture(departureDate)) {
      falsyFields.push({
        field: 'departureDate',
        msg: t('forms.fieldErrors.dateMustBeTodayOrInFuture'),
      });
    } else if (!checkStringDateMaxDaysInFuture(departureDate, 7)) {
      falsyFields.push({
        field: 'departureDate',
        msg: t('forms.fieldErrors.departureDateError'),
      });
    }

    if (!typeOfPigs || typeOfPigs === '0') {
      falsyFields.push({
        field: 'typeOfPigs',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (!outdoors) {
      falsyFields.push({
        field: 'outdoors',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (!controlledHousing || controlledHousing === '0') {
      falsyFields.push({
        field: 'controlledHousing',
        msg: t('forms.fieldErrors.pickAnOption'),
      });
    }

    if (falsyFields.length === 0) {
      jumpToStep(2);
    } else {
      setInvalidFields(falsyFields);
    }
  };

  const checkField = fieldName => {
    return invalidFields.find(field => field.field === fieldName)
      ? true
      : false;
  };

  const getMessage = fieldName => {
    return invalidFields.find(field => field.field === fieldName).msg;
  };

  return (
    <div className='step step2 mt-5 mb-5'>
      <Row className='justify-content-md-center mt-5'>
        <Col lg='6'>
          {invalidFields.length > 0 && (
            <div className='alert alert-danger fade show' role='alert'>
              {t('forms.fieldErrors.checkFields')}
            </div>
          )}
          <Form
            className='form-horizontal form-material mt-2'
            onSubmit={e => e.preventDefault()}
          >
            <h4 className='mb-3'>{t('vki.informationPigs')}</h4>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='amountOfPigs'>
                    {t('vki.amountOfPigs')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={amountOfPigs}
                    data-field='amountOfPigs'
                    name='amountOfPigs'
                    onChange={onChange}
                  />
                  {checkField('amountOfPigs') && (
                    <span className='text-danger'>
                      {getMessage('amountOfPigs')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='setupDate'>
                    {t('vki.setupDate')}
                  </Label>
                  <Input
                    type='date'
                    value={setupDate}
                    data-field='setupDate'
                    name='setupDate'
                    onChange={onChange}
                    min='2021-30-03'
                  />
                  {checkField('setupDate') && (
                    <span className='text-danger'>
                      {getMessage('setupDate')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='serialNumber'>{t('vki.serialNumber')}</Label>
                  <Input
                    type='text'
                    value={serialNumber}
                    data-field='serialNumber'
                    name='serialNumber'
                    onChange={onChange}
                    disabled={true}
                  />
                  {checkField('serialNumber') && (
                    <span className='text-danger'>
                      {getMessage('serialNumber')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='departureDate'>
                    {t('vki.departureDate')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='date'
                    value={departureDate}
                    data-field='departureDate'
                    name='departureDate'
                    onChange={onChange}
                  />
                  {checkField('departureDate') && (
                    <span className='text-danger'>
                      {getMessage('departureDate')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md='12'>
                <h5>
                  {t('vki.typeofPigs')} <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='typeOfPigs'
                      name='typeOfPigs'
                      value='1'
                      id='typeRadio1'
                      onChange={onChange}
                      checked={typeOfPigs === '1'}
                    />
                    <Label for='typeRadio1'>{t('vki.fattening')}</Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='typeOfPigs'
                      name='typeOfPigs'
                      value='2'
                      id='typeRadio2'
                      onChange={onChange}
                      checked={typeOfPigs === '2'}
                    />
                    <Label for='typeRadio2'>{t('vki.breeding')}</Label>
                  </div>
                </div>
                {checkField('typeOfPigs') && (
                  <span className='text-danger'>
                    {getMessage('typeOfPigs')}
                  </span>
                )}
              </Col>
              <Col md='12' className='mt-3'>
                <h5>
                  {t('vki.outdoors')} <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='outdoors'
                      name='outdoors'
                      value='1'
                      id='outdoorsRadio1'
                      onChange={onChange}
                      checked={outdoors === '1'}
                    />
                    <Label for='outdoorsRadio1'>{t('general.yes')}</Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='outdoors'
                      name='outdoors'
                      value='2'
                      id='outdoorsRadio2'
                      onChange={onChange}
                      checked={outdoors === '2'}
                    />
                    <Label for='outdoorsRadio2'>{t('general.no')}</Label>
                  </div>
                </div>
                {checkField('outdoors') && (
                  <span className='text-danger'>{getMessage('outdoors')}</span>
                )}
              </Col>
              <Col md='12' className='mt-3'>
                <h5>
                  {t('vki.controlledHousing')}{' '}
                  <span className='text-primary'>*</span>
                </h5>
                <div className='form-inline'>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='controlledHousing'
                      name='controlledHousing'
                      value='1'
                      id='controlledHousingRadio1'
                      onChange={onChange}
                      checked={controlledHousing === '1'}
                    />
                    <Label for='controlledHousingRadio1'>
                      {t('general.yes')}
                    </Label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      data-field='controlledHousing'
                      name='controlledHousing'
                      value='2'
                      id='controlledHousingRadio2'
                      onChange={onChange}
                      checked={controlledHousing === '2'}
                    />
                    <Label for='controlledHousingRadio2'>
                      {t('general.no')}
                    </Label>
                  </div>
                </div>
                {checkField('controlledHousing') && (
                  <span className='text-danger'>
                    {getMessage('controlledHousing')}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
          <div className='mt-4 d-flex justify-content-between'>
            <Button onClick={() => jumpToStep(0)} className='btn-secondary'>
              {t('general.previous')}
            </Button>
            <Button onClick={validateStep} className='btn-primary'>
              {t('general.next')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step2;
