import i18next from 'i18next';

const baseColors = [
  {
    id: 'TW',
    color: '#000',
  },
  {
    id: 'DAV',
    color: '#00ff00',
  },
  {
    id: 'COV',
    color: '#0000ff',
  },
];

// Som aantal varkens
export const graphDEAANTA = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  filters.loadingplaces.forEach(lp => {
    if (lp.active) {
      seriesData.push({
        name: lp.key,
        data: [],
      });
    }
  });

  uniqueDates.forEach(date => {
    seriesData.forEach(val => {
      const result = filteredData.filter(
        item => item.DELPLCE === val.name && item.DELEVDT === date
      );
      let DEAANTA = 0;

      if (result.length >= 0) {
        result.forEach(element => {
          DEAANTA += Number(element.DEAANTA);
        });
        hasSeriesData = true;
      }
      val.data.push(DEAANTA);
    });
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      // colors: ['#ffb22b', '#4caf50', '#d2043b'],
      colors: [
        '#ffb22b',
        '#4caf50',
        '#d2043b',
        '#266DD3',
        '#ff6d00',
        '#ff0000',
        '#ff8700',
        '#ffd300',
        '#deff0a',
        '#a1ff0a',
        '#0aff99',
        '#0aefff',
        '#147df5',
        '#580aff',
        '#be0aff',
        '#ffd100',
      ],
      chart: {
        id: 'sum-amount-pigs',
        type: 'bar',
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        // enabled: true,
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: categories,
      },
    },
    series: seriesData,
  };

  return graphOptions;
};

// Totaalbedrag + gemiddelde euro per vark
export const graphDETOTBG = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = {
    DETOTBG: [],
    gemiddelde: [],
  };
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);

    let DETOTBG = 0;
    let DEAANTA = 0;
    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);
      if (obj.active) {       
        DETOTBG += Number(val.DETOTBG);
        DEAANTA += Number(val.DEAANTA);
      }
    });

    if (Number(DETOTBG) !== 0) {
      hasSeriesData = true;
    }

    seriesData.DETOTBG.push(Number(DETOTBG).toFixed(2));
    seriesData.gemiddelde.push(Number(DETOTBG / DEAANTA).toFixed(2));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      // colors: ['#2b82ca', '#d2043b'],
      chart: {
        type: 'line',
        id: 'sum-total-and-avg',
      },
      stroke: {
        curve: 'smooth',
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          // axisBorder: {
          //   show: true,
          //   color: '#008FFB',
          // },
          labels: {
            style: {
              colors: '#008FFB',
            },
          },
          title: {
            // text: 'Factuurbedrag in EUR',
            text: i18next.t('reporting.labels.invoiceAmountEUR'),
            style: {
              color: '#008FFB',
            },
          },
        },
        {
          min: Math.min(...seriesData.gemiddelde) - 0.3,
          forceNiceScale: true,
          decimalsInFloat: 2,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#00E396',
          },
          labels: {
            style: {
              colors: '#00E396',
            },
          },
          title: {
            // text: 'Gemiddelde prijs per varken in EUR',
            text: i18next.t('reporting.labels.avgPricePerPigEUR'),
            style: {
              color: '#00E396',
            },
          },
        },
      ],
      xaxis: {
        categories: categories,
      },
    },

    series: [
      {
        // name: 'Som totaal factuurbedrag',
        name: i18next.t('reporting.labels.sumTotalInvoiceAmount'),
        data: seriesData.DETOTBG,
        type: 'bar',
      },
      {
        // name: 'Gemiddelde prijs per varken',
        name: i18next.t('reporting.labels.avgPricePerPig'),
        data: seriesData.gemiddelde,
        type: 'line',
      },
    ],
  };

  return graphOptions;
};

// Aantal varkens buiten gewicht
export const graphDEAANTAKLGR = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = {
    DEAANTA: [],
    DEANTGR: [],
    DEANTKL: [],
  };
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);

    let DEAANTA = 0;
    let DEANTGR = 0;
    let DEANTKL = 0;

    helperArray.forEach(val => {
      // 1. Get corresponding object from filters.loadingplaces where key is equal to val.DELPLCE
      // 2. Check if active is true
      // 3. Add values
      // If active is false, don't add values

      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        DEAANTA += Number(val.DEAANTA);
        DEANTGR += Number(val.DEANTGR);
        DEANTKL += Number(val.DEANTKL);
      }
    });

    if (Number(DEAANTA) !== 0) {
      hasSeriesData = true;
    }

    seriesData.DEAANTA.push(DEAANTA);
    seriesData.DEANTGR.push(DEANTGR);
    seriesData.DEANTKL.push(DEANTKL);
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b', '#4caf50', '#d2043b'],
      chart: {
        type: 'bar',
        stacked: true,
        // stackType: '100%',
        id: 'amount-of-pigs-outside-grille',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        // name: 'te klein',
        name: i18next.t('reporting.labels.tooSmall'),
        data: seriesData.DEANTKL,
      },
      {
        // name: 'oké',
        name: i18next.t('reporting.labels.ok'),
        data: seriesData.DEAANTA,
      },
      {
        // name: 'te groot',
        name: i18next.t('reporting.labels.tooBig'),
        data: seriesData.DEANTGR,
      },
    ],
  };

  return graphOptions;
};

// Koud geslacht gemiddeld gewicht
export const graphDEGEMNV = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let totalPercentage = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        totalPercentage += Number(val.DEGEMGW);
      }
    });

    if (Number(totalPercentage) !== 0) {
      hasSeriesData = true;
    }

    const avg = (totalPercentage / helperArray.length) * 0.815;

    seriesData.push(Number(avg.toFixed(1)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#d2043b'],
      chart: {
        id: 'avg-meat-percentage',
      },
      dataLabels: {
        // offsetY: 1950,
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        min: Math.min(...seriesData) - 0.3,
        max: Math.max(...seriesData) + 0.3,
        forceNiceScale: true,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Gemiddelde spekdikte
export const graphDEGEMSD = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let totalPercentage = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        totalPercentage += Number(val.DEGEMSD);
      }
    });

    if (Number(totalPercentage) !== 0) {
      hasSeriesData = true;
    }

    const avg = totalPercentage / helperArray.length;

    seriesData.push(Number(avg.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#4caf50'],
      chart: {
        id: 'avg-bacon-thickness',
      },
      dataLabels: {
        // offsetY: 515,
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        min: Math.min(...seriesData) - 0.3,
        max: Math.max(...seriesData) + 0.3,
        forceNiceScale: true,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Gemiddelde vleesdikte
export const graphDEGEMVD = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let totalPercentage = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        totalPercentage += Number(val.DEGEMVD);
      }
    });

    if (Number(totalPercentage) !== 0) {
      hasSeriesData = true;
    }

    const avg = totalPercentage / helperArray.length;

    seriesData.push(Number(avg.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      chart: {
        id: 'avg-meat-thickness',
      },
      dataLabels: {
        // offsetY: 2240,
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        min: Math.min(...seriesData) - 0.3,
        max: Math.max(...seriesData) + 0.3,
        forceNiceScale: true,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Labelmatrixtoeslag BPG
export const graphDELTMTO = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DELTMTO);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b'],
      chart: {
        id: 'label-matrix',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Toeslag op koud geslacht gewicht (per varken)
export const graphDEBPGTO = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = {
    // DEBPGTO: [],
    DEOPLEG: [],
  };
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    // let total = 0;
    // let pigs = 0;
    let DEOPLEG = 0;
    let countHelper = 0;
    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);
      countHelper ++
      if (obj.active) {
        // total += Number(val.DEBPGTO);
        // pigs += Number(val.DEAANTA);
        DEOPLEG += Number(val.DEOPLEG);
        DEOPLEG = (DEOPLEG / countHelper) || 1
      }
    });

    if (Number(DEOPLEG) !== 0) {
      hasSeriesData = true;
    }
    // seriesData.DEBPGTO.push(Number((total / pigs).toFixed(2)));
    seriesData.DEOPLEG.push(Number(DEOPLEG));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#d2043b'],
      chart: {
        // type: 'bar',
        id: 'cold-weight',
      },
      // stroke: {
      //   curve: 'smooth',
      // },
      yaxis: [
        // {
        //   min: Math.min(...seriesData.DEBPGTO) - 0.3,
        //   forceNiceScale: true,
        //   decimalsInFloat: 2,
        //   axisTicks: {
        //     show: true,
        //   },
        //   labels: {
        //     style: {
        //       colors: '#d2043b',
        //     },
        //   },
        //   title: {
        //     // name: 'Som totaal factuurbedrag',
        //     text: label,
        //     style: {
        //       color: '#d2043b',
        //     },
        //     },
        // },
        {
          min: Math.min(...seriesData.DEOPLEG) - 0.01,
          max: Math.max(...seriesData.DEOPLEG) + 0.002,
          forceNiceScale: true,
          decimalsInFloat: 4,
          // opposite: true,
          axisTicks: {
            show: true,
          },
          // labels: {
          //   style: {
          //     colors: '#d2043b',
          //   },
          // },
          // title: {
          //   // name: 'Totale toeslag boven basisprijs',
          //   text: i18next.t('reporting.labels.toeslagOpBasisprijs'),
          //   style: {
          //     color: '#d2043b',
          //   },
          // },
        },
      ],
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      // {
      //   // name: 'Som totaal factuurbedrag',
      //   name: label,
      //   data: seriesData.DEBPGTO,
      //   type: 'line',
      // },
      {
        // name: 'Totale toeslag boven basisprijs',
        name: i18next.t('reporting.labels.toeslagOpBasisprijs'),
        data: seriesData.DEOPLEG,
        type: 'bar',
      },
    ],
  };
  return graphOptions;
};

// Vergoeding buiten gewicht
export const graphDEMABBU = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEMABBU);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b'],
      chart: {
        id: 'compensation-outside-weight',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Gewicht buiten gewicht
export const graphDEGEWBU = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEGEWBU);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b'],
      chart: {
        id: 'weight-outside-weight',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Aantal leveringen per slachthuis
export const graphDESLAHS = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  // const uniqueDates = getUniqueDates(filteredData);
  const uniqueDESLAHS = getUniqueDESLAHS(filteredData);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDESLAHS.forEach(item => {
    let helperArray = filteredData.filter(val => val.DESLANM === item);
    // let helperArray = filteredData.filter(val => val.DESLAHS === item);
    let i = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        i++;
      }
    });

    if (Number(i) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(Math.round(i)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b', '#4caf50', '#d2043b'],
      chart: {
        id: 'amount-deliveries-slaughterhouse',
      },
      dataLabels: {
        enabled: true,
      },
      labels: uniqueDESLAHS,
    },
    series: seriesData,
  };

  return graphOptions;
};

// Gemiddelde AFI/MBI
export const graphDEGEMMB = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;
    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEGEMMB);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }
    seriesData.push(Number(total.toFixed(2)) / helperArray.length);
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#4caf50'],
      chart: {
        id: 'avg-afi-mbi',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

const getCategories = uniqueDates => {
  const parsed = [...new Set(uniqueDates.map(item => Date.parse(item)))];

  parsed.sort();

  return [
    ...new Set(parsed.map(item => new Date(item).toLocaleDateString('en-GB'))),
  ];
};

const filterData = (data, filters) => {
  const activeLoadingplaces = getActiveLoadingplaces(filters.loadingplaces);

  const activeSlaughterhouses = getActiveSlaughterhouses(
    filters.slaughterhouses
  );

  const filtered = [];

  data.forEach(item => {
    // Check if data item slaughterhouse is in activeSlaughterhouses array
    if (activeSlaughterhouses.findIndex(val => val === item.DESLANM) > -1) {
      // Check if data item loadingplace is in activeLoadingplaces array
      if (activeLoadingplaces.findIndex(val => val === item.DELPLCE) > -1) {
        filtered.push(item);
      }
    }
  });


  return filtered;
};

const getActiveLoadingplaces = loadingplaces => {
  let actives = [];

  loadingplaces.forEach(item => {
    if (item.active) {
      actives.push(item.key);
    }
  });

  return actives;
};

const getActiveSlaughterhouses = slaughterhouses => {
  let actives = [];

  slaughterhouses.forEach(item => {
    if (item.active) {
      actives.push(item.key);
    }
  });

  return actives;
};

const getUniqueDates = data => {
  let allDates = [];

  data.forEach(item => {
    allDates.push(item.DELEVDT);
  });

  return [...new Set(allDates)];
};

const getUniqueDESLAHS = data => {
  let allDESLAHS = [];

  data.forEach(item => {
    allDESLAHS.push(item.DESLANM);
    // allDESLAHS.push(item.DESLAHS);
  });

  return [...new Set(allDESLAHS)];
};

const getColorArray = series => {
  let colorArray = [];
  series.forEach(serie => {
    let obj = baseColors.find(element => element.id === serie.name);
    if (obj) {
      colorArray.push(obj.color);
    } else {
      colorArray.push('#000');
    }
  });
  return colorArray;
};
