import axios from 'axios';

const setAuthToken = (token, set) => {
  if (set && token) {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token.access_token}`;
    axios.defaults.headers.common['X-CSRF-Token'] = token.csrf_token;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
    axios.defaults.headers.common['X-CSRF-Token'] = null;
  }
};

export default setAuthToken;
