import React, { useContext, Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import InlineSpinner from '../layout/InlineSpinner';

// Context
import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import {
  setCurrentOffer,
  setCurrentOfferLine,
  resendOfferLine,
  setCurrentVKI,
  getOffers,
} from '../../store/actions/offers';
import './custom.css';

// Translation
import { useTranslation } from 'react-i18next';

import download from 'downloadjs';
import axios from 'axios';
import i18next from 'i18next';

const OfferLineItem = ({ offerLine, offer, archived}) => {
  const { suppliersState, alertsDispatch, authState, offersDispatch } =
    useContext(GlobalContext);

  const {
    numpigs,
    dropdown1,
    dropdown2,
    dropdown3,
    status,
    field_status_changed,
    filename,
    field_vki_base64,
    slaughterhouse,
    tracy_id
  } = offerLine;
  const ifOwner = () => {
    return authState.user.roles && authState.user.roles.includes('owner');
  };
  function slaughterhouseName(id) {
    switch(id) {
      case '125':
        return  'Covameat';
      case '93':
        return 'Westvlees';
      case 'TBD':
        return 'TBD';
      case '171':
        return 'Comeco';
      case '135':
        return 'Lovenfosse';
      case '11':
        return 'Torhout'
    }
  }
  const [loading, setLoading] = useState(false);
  const [vkiLoading, setVkiLoading] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const createVKI = async () => {
    setVkiLoading(true);
    const headers = {
      'Content-Type': 'application/json'
    }

    try {
      const tracyVKI = await axios.post(`/group/${offerLine.nid}?_format=json`, { headers });
      const response = tracyVKI.data;
      
      if (response) {
        await getOffers()(offersDispatch);
        setVkiLoading(false);
      }
    } 
    catch (error) {
      setVkiLoading(false);
      console.log(error.response);
      if (error.response.data.msg === 'unauthorized') {
        let linkToTracy = (
          <>
            {t(`offers.tracy.${error.response.data.msg}`)}
            <a href="https://www.tracy.be/" target="_blank" rel="noopener noreferrer">
              Tracy
            </a>
          </>
        );
        setAlert(linkToTracy, 'danger')(alertsDispatch);
      }
      else {
        setAlert(t(`offers.tracy.${error.response.data.msg}`), 'danger')(alertsDispatch);
      }

    }

  }

  const makeVKI = () => {
    window.open(`https://www.tracy.be/e-vkis/${offerLine.tracy_id}`);
    // setCurrentOffer(offer)(offersDispatch);
    // setCurrentOfferLine(offerLine)(offersDispatch);
    // history.push('/offers/vki');
  };

  const remakeVKI = async () => {
    setVkiLoading(true);

    setCurrentOffer(offer)(offersDispatch);
    setCurrentOfferLine(offerLine)(offersDispatch);
    const vkiSet = await setCurrentVKI(
      suppliersState.suppliers.activeID,
      offerLine.nid
    )(offersDispatch);

    setVkiLoading(false);

    switch (vkiSet.code) {
      case 200:
        history.push('/offers/vki');
        break;
      case 400:
        setAlert(vkiSet.msg, 'danger')(alertsDispatch);
        break;
      case 504:
        setAlert(vkiSet.msg, 'warning')(alertsDispatch);
        history.push('/offers/vki');
        break;
      default:
        break;
    }
  };

  const downloadVKI = async () => {
    const base64 = `data:application/pdf;base64,${field_vki_base64}`;
    download(base64, filename, 'application/pdf');
  };

  const retrySend = async () => {
    setLoading(true);
    const data = {
      offer_id: offer.nid,
      offerline_id: offerLine.nid,
    };

    const res = await resendOfferLine(data)(offersDispatch);

    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    } else if (res.code === 504) {
      setAlert(res.msg, 'warning')(alertsDispatch);
    } else {
      setAlert(res.msg, 'success')(alertsDispatch);
    }

    setLoading(false);
  };
  return (
    <Fragment>
      <tr>
        <td>{numpigs}</td>
        <td>{dropdown1}</td>
        {/* <td>{dropdown2}</td>
        <td>{dropdown3}</td> */}
        <td>
          {loading ? (
            <InlineSpinner />
          ) : (
            <Fragment>
              <span
                className={`d-block status-${status
                  .toLowerCase()
                  .replace('-', '')}`}
              >
                {t(`offers.status.${status}`)}    
              </span>
              {field_status_changed && (
                <span className='status-container'>
                  <small>
                    {t('general.onDate')} {field_status_changed}
                  </small>
                  {status === 'ACCEP' && slaughterhouse && (
                    <small>
                      {t('offers.delivery')}: {slaughterhouseName(slaughterhouse)}
                    </small>
                  )}
                </span>
              )}
            </Fragment>
          )}

          {status === 'OPMAK' && !loading && !archived && (
            <a href='#!' onClick={retrySend} className='d-block'>
              {t('general.resend')}
            </a>
          )}
        </td>
        <td>
          {status === 'ACCEP' && offerLine.tracy_id && !vkiLoading && (
         
            <Button 
              className='btn-secondary btn-sm d-block'
              disabled={!ifOwner()}
              onClick={makeVKI}
            >
              {t('offers.makeVKI')}
            </Button>
          )}

          {status === 'ACCEP' && !offerLine.tracy_id && vkiLoading && (
            <div className='vki-loader'>             
              <InlineSpinner />
            </div>
          )}

          {status === 'ACCEP' && !offerLine.tracy_id && !vkiLoading && (
            <Button
              className='btn-primary btn-sm d-block'  
              disabled={!ifOwner()}
              onClick={createVKI}
            >
              {t('offers.createVKI')}
            </Button>
          )}

          {/* {status === 'ACCEP' &&
            field_vki_base64 === '' &&
            !archived &&
            !vkiLoading && (
              <Button
                className='btn-primary btn-sm d-block'
                disabled={!ifOwner()}
                onClick={makeVKI}
              >
                {t('offers.makeVKI')}
              </Button>
            )} */}

          {/* {status === 'ACCEP' && field_vki_base64 !== '' && !vkiLoading && ( */}
            {/* <div> */}
              {/* <a href='#!' onClick={downloadVKI}>
                <i className='mdi mdi-file-pdf'></i>&nbsp;
                {t('offers.downloadVKI')}
              </a> */}
              {/* {!archived && (
                <Button
                  className='d-block mt-2 btn-secondary btn-sm'
                  onClick={makeVKI}
                  disabled={!ifOwner()}
                >
                  {t('offers.remakeVKI')}
                </Button>
              )}
            </div> */}
          {/* )} */}

          {/* {status !== 'ACCEP' && !vkiLoading && (
            <Button className='btn-primary btn-sm' disabled={true}>
              {t('offers.makeVKI')}
            </Button>
          )} */}
        </td>
      </tr>
    </Fragment>
  );
};

OfferLineItem.propTypes = {
  offerLine: PropTypes.object.isRequired,
};

export default OfferLineItem;
