/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.loading = payload;
      });
    case 'LOGIN':
      return produce(state, draft => {
        draft.access_token = payload.access_token;
        draft.logout_token = payload.logout_token;
        draft.csrf_token = payload.csrf_token;
        draft.user = payload.user;
        draft.user_data = payload.user_data;
        draft.permissions = payload.permissions;
        draft.isAuthenticated = true;
        draft.loading = false;
        draft.error = null;
      });
    case 'EDIT_USER_DATA':
      return produce(state, draft => {
        draft.user_data = { ...draft.user_data, ...payload };
      });
    case 'SET_IS_AUTHENTICATED':
      return produce(state, draft => {
        draft.isAuthenticated = payload;
      });
    case 'LOGOUT':
      return produce(state, draft => {
        draft.access_token = null;
        draft.logout_token = null;
        draft.csrf_token = null;
        draft.user = null;
        draft.user_data = null;
        draft.permissions = null;
        draft.loading = false;
        draft.error = null;
      });
    case 'RELOAD_PERMISSIONS':
      return produce(state, draft => {
        draft.permissions = payload;
        draft.loading = false;
      });
    default:
      return state;
  }
};
