import React, { Fragment, useContext, useState } from 'react';
import { formatVKIDateString } from '../../utils/Utils';

import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import { removeResearch } from '../../store/actions/loadingplaces';

import { useTranslation } from 'react-i18next';
import ContainerSpinner from '../layout/ContainerSpinner';

const LoadingplaceResearchItem = ({ row }) => {
  const { alertsDispatch, loadingplacesState, loadingplacesDispatch } =
    useContext(GlobalContext);
  const {
    field_research_type,
    field_research_date,
    field_research_result,
    ID,
  } = row;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleRemove = async (lp_id, ID) => {
    setLoading(true);
    const res = await removeResearch(
      loadingplacesState.loadingplaces.current.id,
      ID
    )(loadingplacesDispatch);

    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    } else if (res.code === 504) {
      setAlert(res.msg, 'warning')(alertsDispatch);
    }

    setLoading(false);
  };

  return (
    <Fragment>
      {loading && <ContainerSpinner />}
      <tr>
        <td>{field_research_type}</td>
        <td>{formatVKIDateString(field_research_date)}</td>
        <td>{field_research_result}</td>
        <td>
          <button
            className='btn btn-secondary btn-sm'
            onClick={() => {
              handleRemove(loadingplacesState.loadingplaces.current.id, ID);
            }}
            disabled={loading}
          >
            <i className='icon-trash'></i> <span>{t('general.delete')}</span>
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

export default LoadingplaceResearchItem;
