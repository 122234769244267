import React, { useCallback, useEffect, useState } from 'react'
import { CartesianGrid, ComposedChart, Dot, Legend, Line, LineChart, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import { CSVLink } from "react-csv";
import { CardTitle, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import Hamburger from '../../assets/images/hamburger.svg'
import ZoomIn from '../../assets/images/zoomIn.svg'
import ZoomOut from '../../assets/images/zoomOut.svg'
import './style.css';
import ScrollContainer from 'react-indiana-drag-scroll';

const ChartSchema = ({filter,data, id, deaanta}) => {
    const [getPng, { ref: chartRef }] = useCurrentPng();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const [state, setState] = useState({
        width: 720, height: 1300,
        chartWidth: Math.max(14*50*1, 720)
    })
    const [payloadData, setPayloadData] = useState([
        { stroke:"#4675c3",hide: false, type: 'scatter',dot: true, label: [data?.labelOne, data?.labelTwo, data?.labelThree, data?.labelFour, data?.firstLabel]},
        { stroke:"#ec7d2f",hide: false, type: 'line', dot: true, label: [data?.labelOne, data?.labelTwo, data?.labelThree, data?.labelFour, data?.firstLabel]},
        { stroke:"#6c6c6c", hide: false,type: 'line', dot: false, label: [data?.labelOne, data?.labelTwo, data?.labelThree, data?.labelFour, data?.firstLabel]},
        { stroke:"#7bb353", hide: false,type: 'line', dot: false, label: [data?.labelOne, data?.labelTwo, data?.labelThree, data?.labelFour, data?.firstLabel]},
        { stroke: "#d2043b", hide: false, type: 'line', dot: false, label: [data?.labelOne, data?.labelTwo, data?.labelThree, data?.labelFour, data?.firstLabel] }
    ])


    //--------- DOWNLOAD THE CHART AS PNG ------------------
    const downloadPNG = useCallback(async () => {
        const png = await getPng();
        if (png) {
          FileSaver.saveAs(png, `${Math.round(Math.random() * 100)}Chart.png`);
          setDropdownOpen(false)
        }
    }, [getPng]);
      
    //--------- DOWNLOAD THE CHART AS SVG ------------------
    function downloadSVG() {
        let chartSVG = document.getElementById(id);
        let source = new XMLSerializer().serializeToString(chartSVG);
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
    
        var dl = document.createElement("a");
        document.body.appendChild(dl);
        dl.setAttribute("href", url);
        dl.setAttribute("download", `${Math.round(Math.random() * 100)}Chart.svg`);
        dl.click();
        setDropdownOpen(false)
    }
    //--------- ZOOM IN AND ZOOM OUT ------------------
    function zoomOut() {
        setState({...state, chartWidth:Math.max(14*50*1, state.width) })
    }
    function zoomIn() {
        if(filter.toWeek > 10 || filter.fromYear != filter.toYear) {
            setState({...state, chartWidth:Math.max(24*60*2, state.width) })
        }
    }
    //--------- RENDER CUSTOM DOTS ------------------
    const RenderDot = ({ cx, cy }) => {
        return (
          <Dot cx={cx} cy={cy} fill="#4675c3" r={4} />
        )
    }
    function handleLegend(e){
        let tempArr = []
        payloadData.find((x) => {
            if(x.stroke == e.color) {
                tempArr.push({ stroke:x.stroke ,hide: !x.hide, type: x.type ,dot: x.dot, label: x.label},)
            } else { tempArr.push(x) }
        });
        setPayloadData(tempArr)
    }
    useEffect(() => {
        const elements = document.getElementsByName("year-tick");
    
        let count = 2;
        for(let i = 1; i <= elements.length; i++) {
          if(elements[i]?.getAttribute('class') == 'recharts-text recharts-cartesian-axis-tick-value') {
            if(elements[i].children[0]?.innerHTML == elements[i+count]?.children[0]?.innerHTML) {
              elements[i+count]?.setAttribute("style", "display:none;");
              elements[i+count]?.parentElement?.childNodes[0]?.setAttribute("style", "display:none;");
            } else {
            }
          }
        }
    }, [])

  return (
    <>
        <CardTitle className='d-flex justify-content-end'>
            <span onClick={zoomIn}>
                <img
                    src={ZoomIn}
                    width='20px'
                    alt='BPG AP'
                />
            </span>
            <span onClick={zoomOut}>
                <img
                    src={ZoomOut}
                    width='20px'
                    alt='BPG AP'
                />
            </span>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle tag="span" data-toggle="dropdown">
                <img src={Hamburger} width='20px' alt='BPG AP'/>
                </DropdownToggle>
                <DropdownMenu flip={true} direction='left'>
                <div onClick={downloadSVG}>Download SVG</div>
                <div onClick={downloadPNG}>Download PNG</div>
                { data?.data && <CSVLink data={data?.data}>Download CSV</CSVLink>}
                </DropdownMenu>
            </Dropdown>
        </CardTitle>
        <ScrollContainer vertical={false} style={{ display: 'inline-block', width: state.width, height: '100%'}}>
            <ComposedChart ref={chartRef} width={state.chartWidth} height={state.height/3} data={data.data} id={id} 
                style={{display: 'inline-block', float: 'left'}}
            >
                <XAxis xAxisId="0" type="category" dataKey="week" strokeOpacity={0} width={20} interval={0} />
                { data?.yearLabel && <XAxis xAxisId="2" dataKey="year" name="year-tick" interval={0} />}
                <YAxis strokeOpacity={0} domain={[data?.yRange?.starting, data?.yRange?.ending]} tickCount={8} />
                {/* <YAxis strokeOpacity={0} tickSize={1} ticks={[10, 20, 30, 40, 50, 60, 70]}/> */}
                <CartesianGrid horizontal={true} vertical={false}/>
                <Tooltip />
                <Legend iconType='circle' iconSize={10}  onClick={(e) => handleLegend(e)} wrapperStyle={{ cursor: 'pointer'}}/>
                { payloadData.map((ele, index) => {
                    return (
                        ele.type === 'scatter' ? 
                        ele.label[index] && <Scatter dataKey={ele?.label[index]} fill={ele.stroke} shape={<RenderDot/>} hide={ele.hide} /> 
                        : 
                        ele.label[index] !== undefined && <Line type="monotone"  dataKey={ele.label[index]} stroke={ele.stroke}  strokeWidth={3} hide={ele.hide} dot={ele.dot}/>  
                    )
                }
                )}

            </ComposedChart>
        </ScrollContainer>
    </>
  )
}

export default ChartSchema