import i18next from 'i18next';
import _ from 'lodash';

const baseColors = [
  {
    id: 'TW',
    color: '#000',
  },
  {
    id: 'DAV',
    color: '#00ff00',
  },
  {
    id: 'COV',
    color: '#0000ff',
  },
];


const filterType = (data, type) => {
  const filteredData = data?.filter((ele) => {
    if(ele.DELTMCE === type && parseInt(ele.DEAANTA) > 5) {
      return ele
    }
  })
  return filteredData
}
const groupArray = (data) => {
  let oneArr = []
  let mergedData = [];
  Object.keys(data).forEach((idx) => {
    let item = data[idx]
    item.forEach( subItem => oneArr.push(subItem) )
  })
  const arr1 =  _.chain(oneArr).groupBy("week").value()
  Object.keys(arr1).forEach((idx) => {
    let item = arr1[idx];
    const test = _.chain(item).groupBy("year").value()
    Object.keys(test).forEach((idx) => { 
      let subItem = test[idx];
      const mrgData = subItem.reduce((acc, current) => {
        for (let key in current) {
          if (current.hasOwnProperty(key)) {
              acc[key] = current[key];
          }
        }
        return acc;
      }, {});
      mergedData.push(mrgData)
    })
  })
  mergedData.sort((a, b) => {
    return parseInt(a.year) - parseInt(b.year);
  });
  
  return mergedData
}
//-------------------- Average BAD/AFI ----------------------
export const DEGEMAT_ChartData = (drgmbmkData, drlvbmkData, label) => {
  let DEGEMAT_ChartData = [], DEGEMAT_ChartDataTwo = [], DEGEMAT_ChartDataThree = [],DEGEMAT_ChartDataFour = [], DEGEMAT_ChartDataFirst = [];
  const labelTwoFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DEELTCE);
  const allChartOne = filterType(drgmbmkData,'ALL');
  const labelThreeFiltered = filterType(drgmbmkData,drlvbmkData[0]?.DEEXTLB);
  const labelFirstFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DELTMCE);
  const yRange= { starting: 1.5, ending: 6.0}

  drlvbmkData?.map((ele, index) => DEGEMAT_ChartData.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelOne]: ele.DEGEMAT}))
  labelTwoFiltered?.map((ele, index) => DEGEMAT_ChartDataTwo.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelTwo]: ele.DEGEMAT}) )
  labelThreeFiltered?.map((ele, index) => DEGEMAT_ChartDataThree.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelThree]: ele.DEGEMAT}) )
  labelFirstFiltered?.map((ele, index) => DEGEMAT_ChartDataFirst.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.firstLabel]: ele.DEGEMAT}))
  allChartOne?.map((ele, index) => DEGEMAT_ChartDataFour.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelFour]: ele.DEGEMAT}) )

  let yearLabel = false
  if(label.labelTwo === "-" && label.labelThree !== "-") { 
    let obj = {
     DEGEMAT_ChartData:DEGEMAT_ChartData,
     DEGEMAT_ChartDataThree:DEGEMAT_ChartDataThree,
     DEGEMAT_ChartDataFour:DEGEMAT_ChartDataFour,
     DEGEMAT_ChartDataFirst: DEGEMAT_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo !== "-" && label.labelThree === "-") { 
    let obj = {
     DEGEMAT_ChartData:DEGEMAT_ChartData,
     DEGEMAT_ChartDataTwo:DEGEMAT_ChartDataTwo,
     DEGEMAT_ChartDataFour:DEGEMAT_ChartDataFour,
     DEGEMAT_ChartDataFirst: DEGEMAT_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel, yRange: yRange}
  } else if(label.labelTwo !== "-" && label.labelThree !== "-") {
    const arr = []
    let obj = {
     DEGEMAT_ChartData:DEGEMAT_ChartData,
     DEGEMAT_ChartDataTwo:DEGEMAT_ChartDataTwo,
     DEGEMAT_ChartDataThree:DEGEMAT_ChartDataThree,
     DEGEMAT_ChartDataFour:DEGEMAT_ChartDataFour,
     DEGEMAT_ChartDataFirst: DEGEMAT_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange}
  } else {
    let obj = {
     DEGEMAT_ChartDataTwo:DEGEMAT_ChartDataTwo,
     DEGEMAT_ChartDataFour:DEGEMAT_ChartDataFour,
     DEGEMAT_ChartDataFirst: DEGEMAT_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: yearLabel,yRange: yRange}
  }
}
//-------------------- Average AFI per delivery ----------------------
export const DEGEMMB_ChartData = (drgmbmkData, drlvbmkData, label) => {


  let DEGEMMB_ChartData = [], DEGEMMB_ChartDataTwo = [], DEGEMMB_ChartDataThree = [],DEGEMMB_ChartDataFour = [], DEGEMMB_ChartDataFirst = [];

  const labelTwoFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DEELTCE);
  const allChartOne = filterType(drgmbmkData,'ALL');
  const labelThreeFiltered = filterType(drgmbmkData,drlvbmkData[0]?.DEEXTLB);
  const labelFirstFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DELTMCE);
  const yRange= { starting: 1.6, ending: 6.0}



  drlvbmkData.map((ele, index) => DEGEMMB_ChartData.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelOne]: ele.DEGEMMB}))
  labelTwoFiltered?.map((ele, index) => DEGEMMB_ChartDataTwo.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelTwo]: ele.DEGEMMB}) )
  labelThreeFiltered?.map((ele, index) => DEGEMMB_ChartDataThree.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelThree]: ele.DEGEMMB}) )
  labelFirstFiltered?.map((ele, index) => DEGEMMB_ChartDataFirst.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.firstLabel]: ele.DEGEMMB}))
  allChartOne?.map((ele, index) => DEGEMMB_ChartDataFour.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelFour]: ele.DEGEMMB}) )
  let yearLabel = false

  if(label.labelTwo === "-" && label.labelThree !== "-" && label.firstLabel !== '-') { 

    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataThree: DEGEMMB_ChartDataThree,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
      DEGEMMB_ChartDataFirst: DEGEMMB_ChartDataFirst
    }

    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
  
    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataTwo: DEGEMMB_ChartDataTwo,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
      DEGEMMB_ChartDataFirst: DEGEMMB_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel !== '-') { 

    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
      DEGEMMB_ChartDataFirst: DEGEMMB_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel === '-') { 
 
    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataTwo: DEGEMMB_ChartDataTwo,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
      DEGEMMB_ChartDataThree: DEGEMMB_ChartDataThree
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelThree: label.labelThree, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel === '-') { 
 
    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataTwo: DEGEMMB_ChartDataTwo,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel === '-') { 
 
    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange}
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel !== '-') {

    const arr = []
    let obj = {
      DEGEMMB_ChartData: DEGEMMB_ChartData,
      DEGEMMB_ChartDataTwo: DEGEMMB_ChartDataTwo,
      DEGEMMB_ChartDataThree: DEGEMMB_ChartDataThree,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
      DEGEMMB_ChartDataFirst: DEGEMMB_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange,}
  } else {

    let obj = {
      DEGEMMB_ChartDataTwo: DEGEMMB_ChartDataTwo,
      DEGEMMB_ChartDataFour: DEGEMMB_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: yearLabel,yRange: yRange}
    
  }

}
//-------------------- Average meat percentage ----------------------
export const DEGEMNV_ChartData = (drgmbmkData, drlvbmkData, label) => {
  let DEGEMNV_ChartData = [], DEGEMNV_ChartDataTwo = [], DEGEMNV_ChartDataThree = [],DEGEMNV_ChartDataFour = [], DEGEMNV_ChartDataFirst = [];

  const labelTwoFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DEELTCE);
  const labelFirstFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DELTMCE);
  const allChartOne = filterType(drgmbmkData,'ALL');
  const labelThreeFiltered = filterType(drgmbmkData,drlvbmkData[0]?.DEEXTLB);
  const yRange= { starting: 55, ending: 70}

  drlvbmkData.map((ele, index) => DEGEMNV_ChartData.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelOne]: ele.DEGEMNV}))
  labelTwoFiltered?.map((ele, index) => DEGEMNV_ChartDataTwo.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelTwo]: ele.DEGEMNV}) )
  labelThreeFiltered?.map((ele, index) => DEGEMNV_ChartDataThree.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelThree]: ele.DEGEMNV}) )
  labelFirstFiltered?.map((ele, index) => DEGEMNV_ChartDataFirst.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.firstLabel]: ele.DEGEMNV}))
  allChartOne?.map((ele, index) => DEGEMNV_ChartDataFour.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelFour]: ele.DEGEMNV}) )

  let yearLabel = false
  if(label.labelTwo === "-" && label.labelThree !== "-" && label.firstLabel !== "-") { 
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataThree: DEGEMNV_ChartDataThree,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
      DEGEMNV_ChartDataFirst: DEGEMNV_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataTwo: DEGEMNV_ChartDataTwo,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
      DEGEMNV_ChartDataFirst: DEGEMNV_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
      DEGEMNV_ChartDataFirst: DEGEMNV_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel === '-') {
    const arr = []
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataTwo: DEGEMNV_ChartDataTwo,
      DEGEMNV_ChartDataThree: DEGEMNV_ChartDataThree,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree == "-" && label.firstLabel === '-') {
    const arr = []
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataTwo: DEGEMNV_ChartDataTwo,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, labelOne: label.labelOne,labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: false,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel !== '-') {
    const arr = []
    let obj = {
      DEGEMNV_ChartData: DEGEMNV_ChartData,
      DEGEMNV_ChartDataTwo: DEGEMNV_ChartDataTwo,
      DEGEMNV_ChartDataThree: DEGEMNV_ChartDataThree,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
      DEGEMNV_ChartDataFirst: DEGEMNV_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange }
  } else {
    let obj = {
      DEGEMNV_ChartDataTwo: DEGEMNV_ChartDataTwo,
      DEGEMNV_ChartDataFour: DEGEMNV_ChartDataFour,
      DEGEMNV_ChartDataFirst: DEGEMNV_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: yearLabel,yRange: yRange }
  }
}
//-------------------- Average bacon thickness ----------------------
export const DEGEMSD_ChartData = (drgmbmkData, drlvbmkData, label) => {
  let DEGEMSD_ChartData = [], DEGEMSD_ChartDataTwo = [], DEGEMSD_ChartDataThree = [],DEGEMSD_ChartDataFour = [], DEGEMSD_ChartDataFirst = [];

  const labelTwoFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DEELTCE);
  const labelFirstFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DELTMCE);
  const allChartOne = filterType(drgmbmkData,'ALL');
  const labelThreeFiltered = filterType(drgmbmkData,drlvbmkData[0]?.DEEXTLB);
  const yRange= { starting: 10, ending: 20}

  drlvbmkData.map((ele, index) => DEGEMSD_ChartData.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelOne]: ele.DEGEMSD}))
  labelTwoFiltered?.map((ele, index) => DEGEMSD_ChartDataTwo.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelTwo]: ele.DEGEMSD}) )
  labelThreeFiltered?.map((ele, index) => DEGEMSD_ChartDataThree.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelThree]: ele.DEGEMSD}) )
  labelFirstFiltered?.map((ele, index) => DEGEMSD_ChartDataFirst.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.firstLabel]: ele.DEGEMSD}))
  allChartOne?.map((ele, index) => DEGEMSD_ChartDataFour.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelFour]: ele.DEGEMSD}) )
  let yearLabel = false
  if(label.labelTwo === "-" && label.labelThree !== "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataThree: DEGEMSD_ChartDataThree,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataFirst: DEGEMSD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataTwo: DEGEMSD_ChartDataTwo,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataFirst: DEGEMSD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataFirst: DEGEMSD_ChartDataFirst,
    }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataFirst: DEGEMSD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataTwo: DEGEMSD_ChartDataTwo,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataTwo: DEGEMSD_ChartDataTwo,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataThree: DEGEMSD_ChartDataThree
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel !== '-') {
    const arr = []
    let obj = {
      DEGEMSD_ChartData: DEGEMSD_ChartData,
      DEGEMSD_ChartDataTwo: DEGEMSD_ChartDataTwo,
      DEGEMSD_ChartDataThree: DEGEMSD_ChartDataThree,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataFirst: DEGEMSD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange }
  } else {
    let obj = {
      DEGEMSD_ChartDataTwo: DEGEMSD_ChartDataTwo,
      DEGEMSD_ChartDataFour: DEGEMSD_ChartDataFour,
      DEGEMSD_ChartDataFirst: DEGEMSD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: yearLabel,yRange: yRange }
  }
}
//-------------------- Average Meat Thickness ----------------------
export const DEGEMVD_ChartData = (drgmbmkData, drlvbmkData, label) => {
  let DEGEMVD_ChartData = [], DEGEMVD_ChartDataTwo = [], DEGEMVD_ChartDataThree = [],DEGEMVD_ChartDataFour = [], DEGEMVD_ChartDataFirst = [];
 
  const labelTwoFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DEELTCE);
  const labelFirstFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DELTMCE);
  const allChartOne = filterType(drgmbmkData,'ALL');
  const labelThreeFiltered = filterType(drgmbmkData,drlvbmkData[0]?.DEEXTLB);
  const yRange= { starting: 60, ending: 77}

  drlvbmkData.map((ele, index) => DEGEMVD_ChartData.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelOne]: ele.DEGEMVD}))
  labelTwoFiltered?.map((ele, index) => DEGEMVD_ChartDataTwo.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelTwo]: ele.DEGEMVD}) )
  labelThreeFiltered?.map((ele, index) => DEGEMVD_ChartDataThree.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelThree]: ele.DEGEMVD}) )
  labelFirstFiltered?.map((ele, index) => DEGEMVD_ChartDataFirst.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.firstLabel]: ele.DEGEMVD}))
  allChartOne?.map((ele, index) => DEGEMVD_ChartDataFour.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelFour]: ele.DEGEMVD}) )
  let yearLabel = false
  if(label.labelTwo === "-" && label.labelThree !== "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataThree: DEGEMVD_ChartDataThree,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
      DEGEMVD_ChartDataFirst: DEGEMVD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataTwo: DEGEMVD_ChartDataTwo,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
      DEGEMVD_ChartDataFirst: DEGEMVD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataTwo: DEGEMVD_ChartDataTwo,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
      DEGEMVD_ChartDataThree: DEGEMVD_ChartDataThree
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataFirst: DEGEMVD_ChartDataFirst,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, firstLabel: label.firstLabel, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataTwo: DEGEMVD_ChartDataTwo,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel !== '-') {
    const arr = []
    let obj = {
      DEGEMVD_ChartData: DEGEMVD_ChartData,
      DEGEMVD_ChartDataTwo: DEGEMVD_ChartDataTwo,
      DEGEMVD_ChartDataThree: DEGEMVD_ChartDataThree,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
      DEGEMVD_ChartDataFirst: DEGEMVD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange }
  } else {
    let obj = {
      DEGEMVD_ChartDataTwo: DEGEMVD_ChartDataTwo,
      DEGEMVD_ChartDataFour: DEGEMVD_ChartDataFour,
      DEGEMVD_ChartDataFirst: DEGEMVD_ChartDataFirst,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: yearLabel,yRange: yRange }
  }
}
//-------------------- Average weight ----------------------
export const DEGEMGW_ChartData = (drgmbmkData, drlvbmkData, label) => {
  let DEGEMGW_ChartData = [], DEGEMGW_ChartDataTwo = [], DEGEMGW_ChartDataThree = [],DEGEMGW_ChartDataFour = [], DEGEMGW_ChartDataFirst = [];

  const labelTwoFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DEELTCE);
  const labelFirstFiltered = filterType(drgmbmkData, drlvbmkData[0]?.DELTMCE);
  const allChartOne = filterType(drgmbmkData,'ALL');
  const labelThreeFiltered = filterType(drgmbmkData,drlvbmkData[0]?.DEEXTLB);
  const yRange= { starting: 65, ending: 115}

  drlvbmkData.map((ele, index) => DEGEMGW_ChartData.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelOne]: ele.DEGEMGW}))
  labelTwoFiltered?.map((ele, index) => DEGEMGW_ChartDataTwo.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelTwo]: ele.DEGEMGW}) )
  labelThreeFiltered?.map((ele, index) => DEGEMGW_ChartDataThree.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelThree]: ele.DEGEMGW}) )
  labelFirstFiltered?.map((ele, index) => DEGEMGW_ChartDataFirst.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.firstLabel]: ele.DEGEMGW}))
  allChartOne?.map((ele, index) => DEGEMGW_ChartDataFour.push({name: ele.DEWEENR, week: ele.DEWEENR, year: ele.DEJAART ,[label.labelFour]: ele.DEGEMGW}) )
  let yearLabel = false
  if(label.labelTwo === "-" && label.labelThree !== "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataThree: DEGEMGW_ChartDataThree,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
      DEGEMGW_ChartDataFirst: DEGEMGW_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelThree: label.labelThree, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataTwo: DEGEMGW_ChartDataTwo,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
      DEGEMGW_ChartDataFirst: DEGEMGW_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataTwo: DEGEMGW_ChartDataTwo,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataTwo: DEGEMGW_ChartDataTwo,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
      DEGEMGW_ChartDataThree: DEGEMGW_ChartDataThree
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelThree: label.labelThree, labelTwo: label.labelTwo, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel === '-') { 
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo === "-" && label.labelThree === "-" && label.firstLabel !== '-') { 
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
      DEGEMGW_ChartDataFirst: DEGEMGW_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne, labelFour: label.labelFour,  yearLabel: yearLabel,yRange: yRange }
  } else if(label.labelTwo !== "-" && label.labelThree !== "-" && label.firstLabel !== '-') {
    const arr = []
    let obj = {
      DEGEMGW_ChartData: DEGEMGW_ChartData,
      DEGEMGW_ChartDataTwo: DEGEMGW_ChartDataTwo,
      DEGEMGW_ChartDataThree: DEGEMGW_ChartDataThree,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
      DEGEMGW_ChartDataFirst: DEGEMGW_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(arr.map(item => item.year))];
    if(unique.length > 1) yearLabel = true
    return { data: _res, firstLabel: label.firstLabel, labelOne: label.labelOne,labelTwo: label.labelTwo, labelThree: label.labelThree, labelFour: label.labelFour, yearLabel: false,yRange: yRange }
  } else {
    let obj = {
      DEGEMGW_ChartDataTwo: DEGEMGW_ChartDataTwo,
      DEGEMGW_ChartDataFour: DEGEMGW_ChartDataFour,
      DEGEMGW_ChartDataFirst: DEGEMGW_ChartDataFirst
    }
    const _res = groupArray(obj)
    const unique = [...new Set(_res.map(item => item.year))];
    if(unique.length > 1) yearLabel = true;
    return { data: _res, firstLabel: label.firstLabel, labelTwo: label.labelTwo, labelFour: label.labelFour, yearLabel: yearLabel,yRange: yRange }
  }
}

// Som aantal varkens
export const graphAvgAFIMBI = (data, label) => {
  // const filteredData = filterData(data);
  // const uniqueDates = getUniqueDates(filteredData);

  // const categories = getCategories(uniqueDates);

  // const seriesData = [];
  // let hasSeriesData = false;

  // console.log(
  //   'GRAPHAVGAFIMBI in utils:  ',
  //   data
  // )


  // filters.loadingplaces.forEach(lp => {
  //   if (lp.active) {
  //     seriesData.push({
  //       name: lp.key,
  //       data: [],
  //     });
  //   }
  // });

  // uniqueDates.forEach(date => {
  //   seriesData.forEach(val => {
  //     const index = filteredData.findIndex(
  //       item => item.DELPLCE === val.name && item.DELEVDT === date
  //     );

  //     if (index >= 0) {
  //       val.data.push(Number(filteredData[index].DEAANTA));
  //       if (Number(filteredData[index].DEAANTA) !== 0) {
  //         hasSeriesData = true;
  //       }
  //     } else {
  //       val.data.push(0);
  //     }
  //   });
  // });

  // const graphOptions = {
  //   hasSeriesData: hasSeriesData,
  //   options: {
  //     // colors: ['#ffb22b', '#4caf50', '#d2043b'],
  //     colors: [
  //       '#ffb22b',
  //       '#4caf50',
  //       '#d2043b',
  //       '#266DD3',
  //       '#ff6d00',
  //       '#ff0000',
  //       '#ff8700',
  //       '#ffd300',
  //       '#deff0a',
  //       '#a1ff0a',
  //       '#0aff99',
  //       '#0aefff',
  //       '#147df5',
  //       '#580aff',
  //       '#be0aff',
  //       '#ffd100',
  //     ],
  //     chart: {
  //       id: 'sum-amount-pigs',
  //       type: 'bar',
  //       stacked: true,
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: true,
  //         columnWidth: '55%',
  //         endingShape: 'rounded',
  //       },
  //     },
  //     dataLabels: {
  //       // enabled: true,
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent'],
  //     },
  //     xaxis: {
  //       categories: categories,
  //     },
  //   },
  //   series: seriesData,
  // };

  // return graphOptions;
};

// Totaalbedrag + gemiddelde euro per vark
export const graphDETOTBG = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = {
    DETOTBG: [],
    gemiddelde: [],
  };
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);

    let DETOTBG = 0;
    let DEAANTA = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        DETOTBG += Number(val.DETOTBG);
        DEAANTA += Number(val.DEAANTA);
      }
    });

    if (Number(DETOTBG) !== 0) {
      hasSeriesData = true;
    }

    seriesData.DETOTBG.push(Number(DETOTBG).toFixed(2));
    seriesData.gemiddelde.push(Number(DETOTBG / DEAANTA).toFixed(2));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      // colors: ['#2b82ca', '#d2043b'],
      chart: {
        type: 'line',
        id: 'sum-total-and-avg',
      },
      stroke: {
        curve: 'smooth',
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          // axisBorder: {
          //   show: true,
          //   color: '#008FFB',
          // },
          labels: {
            style: {
              colors: '#008FFB',
            },
          },
          title: {
            // text: 'Factuurbedrag in EUR',
            text: i18next.t('reporting.labels.invoiceAmountEUR'),
            style: {
              color: '#008FFB',
            },
          },
        },
        {
          min: Math.min(...seriesData.gemiddelde) - 0.3,
          forceNiceScale: true,
          decimalsInFloat: 2,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#00E396',
          },
          labels: {
            style: {
              colors: '#00E396',
            },
          },
          title: {
            // text: 'Gemiddelde prijs per varken in EUR',
            text: i18next.t('reporting.labels.avgPricePerPigEUR'),
            style: {
              color: '#00E396',
            },
          },
        },
      ],
      xaxis: {
        categories: categories,
      },
    },

    series: [
      {
        // name: 'Som totaal factuurbedrag',
        name: i18next.t('reporting.labels.sumTotalInvoiceAmount'),
        data: seriesData.DETOTBG,
        type: 'bar',
      },
      {
        // name: 'Gemiddelde prijs per varken',
        name: i18next.t('reporting.labels.avgPricePerPig'),
        data: seriesData.gemiddelde,
        type: 'line',
      },
    ],
  };

  return graphOptions;
};

// Aantal varkens buiten gewicht
export const graphDEAANTAKLGR = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = {
    DEAANTA: [],
    DEANTGR: [],
    DEANTKL: [],
  };
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);

    let DEAANTA = 0;
    let DEANTGR = 0;
    let DEANTKL = 0;

    helperArray.forEach(val => {
      // 1. Get corresponding object from filters.loadingplaces where key is equal to val.DELPLCE
      // 2. Check if active is true
      // 3. Add values
      // If active is false, don't add values

      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        DEAANTA += Number(val.DEAANTA);
        DEANTGR += Number(val.DEANTGR);
        DEANTKL += Number(val.DEANTKL);
      }
    });

    if (Number(DEAANTA) !== 0) {
      hasSeriesData = true;
    }

    seriesData.DEAANTA.push(DEAANTA);
    seriesData.DEANTGR.push(DEANTGR);
    seriesData.DEANTKL.push(DEANTKL);
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b', '#4caf50', '#d2043b'],
      chart: {
        type: 'bar',
        stacked: true,
        // stackType: '100%',
        id: 'amount-of-pigs-outside-grille',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        // name: 'te klein',
        name: i18next.t('reporting.labels.tooSmall'),
        data: seriesData.DEANTKL,
      },
      {
        // name: 'oké',
        name: i18next.t('reporting.labels.ok'),
        data: seriesData.DEAANTA,
      },
      {
        // name: 'te groot',
        name: i18next.t('reporting.labels.tooBig'),
        data: seriesData.DEANTGR,
      },
    ],
  };

  return graphOptions;
};

// Gemiddeld vleespercentage
export const graphDEGEMNV = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let totalPercentage = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        totalPercentage += Number(val.DEGEMNV);
      }
    });

    if (Number(totalPercentage) !== 0) {
      hasSeriesData = true;
    }

    const avg = totalPercentage / helperArray.length;

    seriesData.push(Number(avg.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#d2043b'],
      chart: {
        id: 'avg-meat-percentage',
      },
      dataLabels: {
        // offsetY: 1950,
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        min: Math.min(...seriesData) - 0.3,
        max: Math.max(...seriesData) + 0.3,
        forceNiceScale: true,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Gemiddelde spekdikte
export const graphDEGEMSD = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let totalPercentage = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        totalPercentage += Number(val.DEGEMSD);
      }
    });

    if (Number(totalPercentage) !== 0) {
      hasSeriesData = true;
    }

    const avg = totalPercentage / helperArray.length;

    seriesData.push(Number(avg.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#4caf50'],
      chart: {
        id: 'avg-bacon-thickness',
      },
      dataLabels: {
        // offsetY: 515,
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        min: Math.min(...seriesData) - 0.3,
        max: Math.max(...seriesData) + 0.3,
        forceNiceScale: true,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Gemiddelde vleesdikte
export const graphDEGEMVD = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let totalPercentage = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        totalPercentage += Number(val.DEGEMVD);
      }
    });

    if (Number(totalPercentage) !== 0) {
      hasSeriesData = true;
    }

    const avg = totalPercentage / helperArray.length;

    seriesData.push(Number(avg.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      chart: {
        id: 'avg-meat-thickness',
      },
      dataLabels: {
        // offsetY: 2240,
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        min: Math.min(...seriesData) - 0.3,
        max: Math.max(...seriesData) + 0.3,
        forceNiceScale: true,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Labelmatrixtoeslag BPG
export const graphDELTMTO = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DELTMTO);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b'],
      chart: {
        id: 'label-matrix',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Toeslag op koud geslacht gewicht (per varken)
export const graphDEBPGTO = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;
    let pigs = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEBPGTO);
        pigs += Number(val.DEAANTA);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }
    seriesData.push((Number(total) / Number(pigs)).toFixed(2));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#d2043b'],
      chart: {
        id: 'cold-weight',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Vergoeding buiten gewicht
export const graphDEMABBU = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEMABBU);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b'],
      chart: {
        id: 'compensation-outside-weight',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Gewicht buiten gewicht
export const graphDEGEWBU = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEGEWBU);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b'],
      chart: {
        id: 'weight-outside-weight',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

// Aantal leveringen per slachthuis
export const graphDESLAHS = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  // const uniqueDates = getUniqueDates(filteredData);
  const uniqueDESLAHS = getUniqueDESLAHS(filteredData);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDESLAHS.forEach(item => {
    let helperArray = filteredData.filter(val => val.DESLANM === item);
    // let helperArray = filteredData.filter(val => val.DESLAHS === item);
    let i = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        i++;
      }
    });

    if (Number(i) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(Math.round(i)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#ffb22b', '#4caf50', '#d2043b'],
      chart: {
        id: 'amount-deliveries-slaughterhouse',
      },
      dataLabels: {
        enabled: true,
      },
      labels: uniqueDESLAHS,
    },
    series: seriesData,
  };

  return graphOptions;
};

// Gemiddelde AFI/MBI
export const graphDEGEMMB = (data, label, filters) => {
  const filteredData = filterData(data, filters);

  const uniqueDates = getUniqueDates(filteredData);

  const categories = getCategories(uniqueDates);

  const seriesData = [];
  let hasSeriesData = false;

  uniqueDates.forEach(item => {
    let helperArray = filteredData.filter(val => val.DELEVDT === item);
    let total = 0;

    helperArray.forEach(val => {
      const obj = filters.loadingplaces.find(item => item.key === val.DELPLCE);

      if (obj.active) {
        total += Number(val.DEGEMMB);
      }
    });

    if (Number(total) !== 0) {
      hasSeriesData = true;
    }

    seriesData.push(Number(total.toFixed(2)));
  });

  const graphOptions = {
    hasSeriesData: hasSeriesData,
    options: {
      colors: ['#4caf50'],
      chart: {
        id: 'avg-afi-mbi',
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: label,
        data: seriesData,
      },
    ],
  };

  return graphOptions;
};

const getCategories = uniqueDates => {
  const parsed = [...new Set(uniqueDates.map(item => Date.parse(item)))];

  parsed.sort();

  return [
    ...new Set(parsed.map(item => new Date(item).toLocaleDateString('en-GB'))),
  ];
};

const filterData = (data, filters) => {
  const activeLoadingplaces = getActiveLoadingplaces(filters.loadingplaces);

  const activeSlaughterhouses = getActiveSlaughterhouses(
    filters.slaughterhouses
  );

  const filtered = [];

  data.forEach(item => {
    // Check if data item slaughterhouse is in activeSlaughterhouses array
    if (activeSlaughterhouses.findIndex(val => val === item.DESLANM) > -1) {
      // Check if data item loadingplace is in activeLoadingplaces array
      if (activeLoadingplaces.findIndex(val => val === item.DELPLCE) > -1) {
        filtered.push(item);
      }
    }
  });

  // console.log('filtered', filtered);

  return filtered;
};

const getActiveLoadingplaces = loadingplaces => {
  let actives = [];

  loadingplaces.forEach(item => {
    if (item.active) {
      actives.push(item.key);
    }
  });

  return actives;
};

const getActiveSlaughterhouses = slaughterhouses => {
  let actives = [];

  slaughterhouses.forEach(item => {
    if (item.active) {
      actives.push(item.key);
    }
  });

  return actives;
};

const getUniqueDates = data => {
  let allDates = [];

  data.forEach(item => {
    allDates.push(item.DELEVDT);
  });

  return [...new Set(allDates)];
};

const getUniqueDESLAHS = data => {
  let allDESLAHS = [];

  data.forEach(item => {
    allDESLAHS.push(item.DESLANM);
    // allDESLAHS.push(item.DESLAHS);
  });

  return [...new Set(allDESLAHS)];
};

const getColorArray = series => {
  let colorArray = [];
  series.forEach(serie => {
    let obj = baseColors.find(element => element.id === serie.name);
    if (obj) {
      colorArray.push(obj.color);
    } else {
      colorArray.push('#000');
    }
  });
  return colorArray;
};
