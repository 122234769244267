/* eslint-disable import/no-anonymous-default-export */

import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_FETCHING':
      return produce(state, draft => {
        draft.stables.fetching = payload;
      });
    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.stables.list = [];
        draft.stables.current = null;
        draft.stables.loading = false;
        draft.stables.fetching = true;
      });
    case 'GET_STABLES':
      return produce(state, draft => {
        draft.stables.list = payload;
        draft.stables.loading = false;
        draft.stables.fetching = false;
      });
    case 'ADD_CONTRIBUTOR_TO_STABLE':
      return produce(state, draft => {
        draft.stables.current.users.push({ ...payload.user });
        const index = draft.stables.list.findIndex(
          stable => stable.id === payload.stables_id
        );
        draft.stables.list[index].users.push({ ...payload.user });
      });
    case 'REMOVE_CONTRIBUTOR_FROM_STABLE':
      return produce(state, draft => {
        const contributorIndex = draft.stables.current.users.findIndex(
          contributor => contributor.id === payload.user_id
        );
        draft.stables.current.users.splice(contributorIndex, 1);

        const stableIndex = draft.stables.list.findIndex(
          stable => stable.id === payload.group_id
        );
        draft.stables.list[stableIndex].users.splice(contributorIndex, 1);
      });
    case 'SET_CURRENT_STABLE': {
      return produce(state, draft => {
        draft.stables.current = payload;
      });
    }
    case 'CLEAR_CURRENT_STABLE': {
      return produce(state, draft => {
        draft.stables.current = null;
      });
    }
    case 'SET_LOADING': {
      return produce(state, draft => {
        draft.stables.loading = payload;
      });
    }
    default:
      return state;
  }
};
