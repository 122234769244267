import axios from 'axios';
import i18next from 'i18next';

export const setFetching = value => dispatch => {
  dispatch({
    type: 'SET_FETCHING',
    payload: value,
  });
};

// Get Documents
export const getDocuments = permissions => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {
    const res = await axios.get('/jsonapi/entity/documents?_format=json');
    console.log('respp--', res);
    //const docs = res.data;
    const docs = res.data.filter(
      doc => Number(permissions[doc.filetypecode]) === 1
    );

    console.log('docs--', docs);

    dispatch({
      type: 'GET_DOCUMENTS',
      payload: res.data,
    });

    dispatch({
      type: 'GET_FILETYPES',
      payload: res.data,
    });
    response.code = 200;
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.documents.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

// Filter documents
export const filterDocuments = filter => dispatch => {
  dispatch({ type: 'FILTER_DOCUMENTS', payload: filter });
};

// Clear Filter
export const clearFilter = () => dispatch => {
  dispatch({ type: 'CLEAR_DOCUMENTS_FILTER' });
};

export const setLoading = value => dispatch => {
  dispatch({ type: 'SET_LOADING', payload: value });
};

export const clearDocumentState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};
