import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Button,
  Table,
} from 'reactstrap';
import ContainerSpinner from '../layout/ContainerSpinner';

// Context
import { GlobalContext } from '../../store/Provider';

import { setAlert } from '../../store/actions/alerts';

import {
  clearCurrentStable,
  addContributorToStable,
  setLoading,
} from '../../store/actions/stables';

// Translation
import { useTranslation } from 'react-i18next';

import StableContributorItem from './StableContributorItem';

const StableDetail = () => {
  const {
    contributorsState,
    stablesState,
    stablesDispatch,
    alertsDispatch,
    authState,
  } = useContext(GlobalContext);

  const { t } = useTranslation();
  const history = useHistory();

  const { title, address_line1, postcode, locality, users } =
    stablesState.stables.current;

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const ifOwner = () => {
    return authState.user.roles && authState.user.roles.includes('owner');
  };

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true)(stablesDispatch);

    const contributor_id = e.target.querySelector('select').value;
    const currentStable = stablesState.stables.current.id;

    const contributor = contributorsState.contributors.list.find(
      x => x.uid === contributor_id
    );

    const contributorToAdd = {
      uid: contributor_id,
      id: contributor_id,
      name: contributor.name,
      roles: '',
      email: contributor.email,
      status: contributor.status,
      uuid: contributor.uuid,
      first_name: contributor.first_name,
    };

    const res = await addContributorToStable(
      contributorToAdd,
      currentStable,
      authState.user.uid
    )(stablesDispatch);

    e.target.querySelector('select').value = '';
    setLoading(false)(stablesDispatch);

    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    } else if (res.code === 504) {
      setAlert(res.msg, 'warning')(alertsDispatch);
    } else {
      setAlert(res.msg, 'success')(alertsDispatch);
    }
  };

  const redirect = () => {
    clearCurrentStable()(stablesDispatch);
    history.push('/stables');
  };

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <button className='btn btn-primary' onClick={redirect}>
            {t('general.prev')}
          </button>
        </Col>
      </Row>
      <Row>
        {stablesState.stables.loading ? <ContainerSpinner /> : ''}
        <Col xs='12' md='12' lg='4'>
          <Card>
            <CardBody>
              <div className='text-center mt-4'>
                <CardTitle className='mt-2'>{title}</CardTitle>
                <CardSubtitle>
                  <i className='icon-location-pin'></i>
                  &nbsp;
                  <span>
                    {decodeStr(address_line1)}, {postcode} {locality}
                  </span>
                </CardSubtitle>
              </div>
            </CardBody>
            {ifOwner() && (
              <CardBody className='border-top'>
                <CardTitle>{t('stables.addContributorToStable')}</CardTitle>
                <Form onSubmit={onSubmit}>
                  <FormGroup>
                    <Input type='select'>
                      <option value=''>{t('general.makeChoice')}</option>
                      {contributorsState.contributors.list.map(contributor => {
                        let index = users.findIndex(
                          x => x.uuid === contributor.uuid
                        );
                        if (index === -1) {
                          return (
                            <option
                              key={contributor.uid}
                              value={contributor.uid}
                            >
                              {contributor.first_name}
                            </option>
                          );
                        } else {
                          return '';
                        }
                      })}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Button className='btn-primary'>{t('general.add')}</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
        <Col xs='12' md='12' lg='8'>
          <Card>
            <CardBody>
              <CardTitle className='mt-2'>{t('contributors.name')}</CardTitle>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{t('general.name')}</th>
                    <th>{t('general.email')}</th>
                    <th>{t('general.roles')}</th>
                    {ifOwner() && <th>{t('general.actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {users.map(contributor => {
                    if (Number(contributor.id) !== 0) {
                      return (
                        <StableContributorItem
                          key={contributor.id}
                          contributor={contributor}
                        />
                      );
                    } else {
                      return '';
                    }
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StableDetail;
