import React, { useContext, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../../store/Provider';
import { filterDocuments } from '../../store/actions/documents';

const DocumentFilter = ({ filetypes }) => {
  const { documentsDispatch } = useContext(GlobalContext);

  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    fileType: '0',
    fileDateOne: '',
    fileDateTwo: '',
  });

  useEffect(() => {
    setFilter({
      fileType: '0',
      fileDateOne: '',
      fileDateTwo: '',
    });
    // eslint-disable-next-line
  }, []);

  let options = [
    {
      label: t('general.makeChoice'),
      value: '0',
    },
  ];

  filetypes.forEach(type => {
    options.push({
      label: type,
      value: type.toLowerCase(),
    });
  });

  const handleTypeChange = e => {
    setFilter({ ...filter, fileType: e.target.value });
  };

  const handleFileDateOneChange = e => {
    setFilter({ ...filter, fileDateOne: e.target.value });
  };

  const handleFileDateTwoChange = e => {
    setFilter({ ...filter, fileDateTwo: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    filterDocuments(filter)(documentsDispatch);
  };

  const resetFilter = e => {
    e.preventDefault();
    setFilter({
      ...filter,
      fileType: '0',
      fileDateOne: '',
      fileDateTwo: '',
    });
  };

  return (
    <Card>
      <CardBody>
        <Form className='form-material' onSubmit={handleSubmit}>
          <FormGroup>
            <Label for='fileTypeSelect'>{t('documents.filterFiletype')}</Label>
            <Input
              type='select'
              name='fileTypeSelect'
              id='fileTypeSelect'
              onChange={handleTypeChange}
              value={filter.fileType}
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for='fileDateOne'>{t('general.from')}</Label>
            <Input
              type='date'
              name='fileDateOne'
              id='fileDateOne'
              onChange={handleFileDateOneChange}
              value={filter.fileDateOne}
            />
          </FormGroup>
          <FormGroup>
            <Label for='fileDateTwo'>{t('general.to')}</Label>
            <Input
              type='date'
              name='fileDateTwo'
              id='fileDateTwo'
              onChange={handleFileDateTwoChange}
              value={filter.fileDateTwo}
            />
          </FormGroup>
          <FormGroup>
            <Button className='btn-primary'>{t('documents.filter')}</Button>{' '}
            <Button className='btn-secondary' onClick={resetFilter}>
              {t('documents.clearFilter')}
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

DocumentFilter.propTypes = {
  filetypes: PropTypes.array.isRequired,
};

export default DocumentFilter;
