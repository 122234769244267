/* eslint-disable import/no-anonymous-default-export */

import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_FETCHING':
      return produce(state, draft => {
        draft.contributors.fetching = payload;
      });
    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.contributors = {
          loading: false,
          fetching: false,
          current: null,
          error: null,
          list: [],
        };
      });
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.contributors.loading = payload;
      });
    case 'GET_CONTRIBUTORS':
      return produce(state, draft => {
        draft.contributors.list = payload;
        draft.contributors.loading = false;
        draft.contributors.fetching = false;
      });
    case 'ADD_CONTRIBUTOR':
      return produce(state, draft => {
        draft.contributors.loading = false;
        draft.contributors.list.push({ ...payload });
      });
    case 'UPDATE_CONTRIBUTOR':
      return produce(state, draft => {
        draft.contributors.loading = false;
        const index = draft.contributors.list.findIndex(
          contributor => contributor.uid === payload.uid
        );
        draft.contributors.list[index] = payload;
      });
    case 'REMOVE_CONTRIBUTOR':
      return produce(state, draft => {
        const index = draft.contributors.list.findIndex(
          contributor => contributor.uid === payload
        );
        draft.contributors.list.splice(index, 1);
        draft.contributors.loading = false;
      });

    case 'SET_CURRENT_CONTRIBUTOR':
      return produce(state, draft => {
        draft.contributors.current = payload;
      });
    case 'CLEAR_CURRENT_CONTRIBUTOR':
      return produce(state, draft => {
        draft.contributors.current = null;
      });
    case 'CONTRIBUTOR_ERROR':
      return produce(state, draft => {
        draft.contributors.error = payload;
      });
    default:
      return state;
  }
};
