/* eslint-disable import/no-anonymous-default-export */
import produce from "immer";

export default (state, { payload, type }) => {
    switch (type) {
      case "RESET":
        return { offers: [] }; // Set it back to the initial state
      default:
        return produce(state, (draft) => {
          if (payload && payload.offers) {
            draft.offers = [...payload?.data];
            // draft.offers = [...draft.offers , ...payload?.data];
          }
        });
    }
  };