import React, { Fragment } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

// Layout
import VeterinarianForm from './VeterinarianForm'
import VeterinariansList from './VeterinariansList'

const VeterinariansComponent = () => {

  const match = useRouteMatch()

  return (
    <Fragment>
      <Switch>
      <Route path={`${match.path}/edit`} component={VeterinarianForm} />
      <Route path={`${match.path}/new`} component={VeterinarianForm} />
      <Route path={match.path} component={VeterinariansList} />
      </Switch>
    </Fragment>
  )
}

export default VeterinariansComponent
