import React, { useContext } from 'react';

// Load layout components
import Header from '../components/layout/Header';
import Sidebar from '../components/layout/Sidebar';

import { useTranslation } from 'react-i18next';

import BenchmarkingComponent from '../components/benchmarking/BenchmarkingComponent';

import { GlobalContext } from '../store/Provider';
//tooltip questionmark
import GraphTooltip from '../components/reporting/GraphTooltip';
const Benchmarking = () => {
  const { layoutState } = useContext(GlobalContext);
  const { t } = useTranslation();


  const getSidebarType = () => {
    if (window.innerWidth >= 768) {
      return layoutState.sidebarOpen ? 'iconbar' : 'mini-sidebar';
    } else {
      return 'mini-sidebar';
    }
  };

  return (
    <div
      id='main-wrapper'
      data-layout='vertical'
      data-sidebartype={getSidebarType()}
      data-sidebarposition='fixed'
      data-header-position='fixed'
      data-boxed-layout='full'
      data-theme='light'
    >
      <Header />
      <Sidebar />
      <div className='page-wrapper d-block'>
        <div className='page-content container-fluid'>
          <div className='benchmarking-title-wrapper'>
              <h1>{t('benchmarking.name')}</h1>
              <GraphTooltip
                  id='AvgPigsPerDelivery'
                  question= 'true'
                  message= {`${t('benchmarking.mainInfoOne') + t('benchmarking.mainInfoTwo') + t('benchmarking.mainInfoThree')}`}
              />
          </div>

          <div className='mt-4'>
            <BenchmarkingComponent />
          </div>
        </div>
      </div>
      {/* Footer */}
    </div>
  );
};

export default Benchmarking;
