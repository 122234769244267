import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import OfferItem from './OfferItem';


// Translation
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GlobalContext } from '../../store/Provider';
import { offersListAction, pageNumberAction } from '../../store/actions/offers';
// import offersList from '../../store/reducers/offersList';

const OffersList = () => {
  const { offersListState, offersListDispatch, pageNumberState, pageNumberDispatch } = useContext(GlobalContext);
  const match = useRouteMatch();
  const [hasMore, setHasMore] = useState(true);

  const getAchievedOffers = useCallback(async () => {
      try {
          const data = {
              pageNumber: pageNumberState,
          };
          const res = await offersListAction(
              offersListState,
              data
          )(offersListDispatch);
          if (res?.data?.data.length === 0 || res?.data?.data?.length === offersListState?.offers?.length) {
              setHasMore(false);
          } else {
              pageNumberAction(pageNumberState)(pageNumberDispatch);
          }
      } catch (error) {
          console.error(error);
      }
  }, [
      offersListDispatch,
      offersListState,
      pageNumberDispatch,
      pageNumberState,
  ]);

  useEffect(() => {
    offersListState?.offers?.length === 0 && setHasMore(true)
    }, [offersListState])

  const { t } = useTranslation();
  return (
      <Fragment>
          <h1>{t("offers.name")}</h1>
          <Row className="mb-4 mt-4">
              <Col md="12">
                  <Link
                      className="btn btn-primary mr-2"
                      to={`${match.path}/new`}
                  >
                      {t("offers.add")}
                  </Link>
                  <Link
                      className="btn btn-secondary"
                      to={`${match.path}/archive`}
                  >
                      {t("general.viewArchive")}
                  </Link>
              </Col>
          </Row>
          <Row className="mt-4">
              {offersListState.offers.length === 0 ? (
                  <Col lg="6" md="12">
                      <Card>
                          <CardBody>
                              <h4>{t("offers.notfound")}</h4>
                          </CardBody>
                      </Card>
                  </Col>
              ) : (
                  <InfiniteScroll
                      dataLength={offersListState.offers.length}
                      next={getAchievedOffers}
                      hasMore={hasMore}
                      style={{ width: "fit-content !important" }}
                      loader={<h4>Loading...</h4>}
                      endMessage={
                          <p style={{ textAlign: "center" }}>
                              <b>{t("offers.recordsNotFound")}</b>
                          </p>
                      }
                  >
                      {offersListState.offers.map((offer) => (
                          <OfferItem
                              key={offer.nid}
                              offer={offer}
                              archived={false}
                          />
                      ))}
                  </InfiniteScroll>
              )}
          </Row>
      </Fragment>
  );
};

export default OffersList;
