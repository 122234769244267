/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'RESEND_OFFER_LINE':
      return produce(state, draft => {
        draft.offers.list = draft.offers.list.map(offer =>
          offer.nid === payload.offer_id
            ? {
                ...offer,
                offer_lines: offer.offer_lines.map(offerLine =>
                  offerLine.nid === payload.offerline_id
                    ? {
                        ...offerLine,
                        status: payload.status,
                      }
                    : offerLine
                ),
              }
            : offer
        );
      });

    case 'SAVE_VKI':
      return produce(state, draft => {
        draft.offers.list = draft.offers.list.map(offer =>
          offer.nid === payload.offer_nid
            ? {
                ...offer,
                offer_lines: offer.offer_lines.map(offerLine =>
                  offerLine.nid === payload.offerLine_nid
                    ? {
                        ...offerLine,
                        filename: payload.filename,
                        field_vki_base64: payload.base64,
                      }
                    : offerLine
                ),
              }
            : offer
        );
      });
    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.offers.list = [];
        draft.offers.archivedOffers = [];
        draft.offers.loading = false;
        draft.offers.current = null;
        draft.offers.currentOfferLine = null;
        draft.offers.currentVKI = null;
        draft.offers.error = null;
        draft.offers.fetching = true;
      });
    case 'SET_FETCHING':
      return produce(state, draft => {
        draft.offers.fetching = payload;
      });
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.offers.loading = payload;
      });
    case 'GET_OFFERS':
      return produce(state, draft => {
        draft.offers.loading = false;
        draft.offers.fetching = false;
        draft.offers.list = payload.active;
        draft.offers.archivedOffers = payload.archived;
      });
    case 'ADD_OFFER':
      return produce(state, draft => {
        draft.offers.list = [payload, ...draft.offers.list];
        draft.offers.loading = false;
      });
    case 'UPDATE_OFFER':
      return produce(state, draft => {
        draft.offers.loading = false;
        draft.offers.list.map(offer =>
          offer.oid === payload.nid ? payload : offer
        );
      });
    case 'ARCHIVE_OFFER':
      return produce(state, draft => {
        draft.offers.loading = false;
        draft.offers.archivedOffers = [payload, ...draft.offers.archivedOffers];
        draft.offers.list.filter(offer => offer.nid !== payload.nid);
      });
    case 'GET_ARCHIVED_OFFERS':
      return produce(state, draft => {
        draft.offers.archivedOffers = payload;
        draft.offers.loading = false;
      });
    case 'SET_CURRENT_OFFER':
      return produce(state, draft => {
        draft.offers.current = payload;
      });
    case 'CLEAR_CURRENT_OFFER':
      return produce(state, draft => {
        draft.offers.current = null;
      });
    case 'SET_CURRENT_OFFER_LINE':
      return produce(state, draft => {
        draft.offers.currentOfferLine = { ...payload };
      });
    case 'CLEAR_CURRENT_OFFER_LINE':
      return produce(state, draft => {
        draft.offers.currentOfferLine = null;
      });
    case 'OFFER_ERROR':
      return produce(state, draft => {
        draft.offers.error = payload;
      });
    case 'SET_CURRENT_VKI':
      return produce(state, draft => {
        draft.offers.currentVKI = payload;
      });
    case 'CLEAR_CURRENT_VKI':
      return produce(state, draft => {
        draft.offers.currentVKI = null;
      });
    default:
      return state;
  }
};
