import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import ContainerSpinner from '../layout/ContainerSpinner';

import { GlobalContext } from '../../store/Provider';

import { setAlert } from '../../store/actions/alerts';

import {
  addVeterinarian,
  updateVeterinarian,
  clearCurrentVeterinarian,
  setLoading,
} from '../../store/actions/veterinarians';

// Translation
import { useTranslation } from 'react-i18next';

const VeterinarianForm = () => {
  const { veterinariansState, veterinariansDispatch, alertsDispatch } =
    useContext(GlobalContext);

  const { t } = useTranslation();
  const history = useHistory();

  const { suppliersState } = useContext(GlobalContext);

  useEffect(() => {
    if (veterinariansState.veterinarians.current !== null) {
      setVeterinarian(veterinariansState.veterinarians.current);
    } else {
      setVeterinarian({
        title: '',
        email: '',
        telephone: '',
        address_line1: '',
        postcode: '',
        locality: '',
        country_code: '',
      });
    }
  }, [veterinariansState.veterinarians]);

  const [veterinarian, setVeterinarian] = useState({
    title: '',
    email: '',
    telephone: '',
    address_line1: '',
    postcode: '',
    locality: '',
    country_code: '',
  });

  const {
    title,
    email,
    telephone,
    address_line1,
    postcode,
    locality,
    country_code,
  } = veterinarian;

  const validateTel = e => {
    const re = /^[\+ \d() -]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      setVeterinarian({ ...veterinarian, [e.target.name]: e.target.value });
   }
  }

  const onChange = e =>
    setVeterinarian({ ...veterinarian, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true)(veterinariansDispatch);
    let res;
    if (veterinariansState.veterinarians.current === null) {
      res = await addVeterinarian(
        veterinarian,
        suppliersState.suppliers.list.find(
          x => x.id === suppliersState.suppliers.activeID
        ).uuid
      )(veterinariansDispatch);
    } else {
      res = await updateVeterinarian(
        veterinarian,
        suppliersState.suppliers.list.find(
          x => x.id === suppliersState.suppliers.activeID
        ).uuid
      )(veterinariansDispatch);
    }
    setLoading(false)(veterinariansDispatch);
    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    } else {
      if (res.code === 504) {
        setAlert(res.msg, 'warning')(alertsDispatch);
      } else {
        setAlert(res.msg, 'success')(alertsDispatch);
      }
      redirect();
    }
  };

  const redirect = () => {
    clearCurrentVeterinarian()(veterinariansDispatch);
    history.push('/veterinarians');
  };

  console.log(telephone)
  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <button className='btn btn-primary' onClick={redirect}>
            {t('general.prev')}
          </button>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <Card>
            <CardBody>
              {veterinariansState.veterinarians.loading ? (
                <ContainerSpinner />
              ) : (
                ''
              )}
              <Form
                className='form-material'
                onSubmit={onSubmit}
                autoComplete='off'
              >
                <h4>
                  {veterinariansState.veterinarians.current
                    ? t('veterinarians.edit')
                    : t('veterinarians.add')}
                </h4>
                <Row className='mt-3'>
                  <Col md='12'>
                    <FormGroup>
                      <Label>{t('general.name')}</Label>
                      <Input
                        type='text'
                        name='title'
                        value={decodeStr(title)}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>{t('general.email')}</Label>
                      <Input
                        type='email'
                        name='email'
                        value={email}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>
                        {t('general.phone')}{' '}
                        <span className='text-secondary'>(+32123456789)</span>
                      </Label>
                      <Input
                        type='text'
                        name='telephone'
                        value={telephone}
                        onChange={validateTel}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>{t('general.address')}</Label>
                      <Input
                        type='text'
                        name='address_line1'
                        value={decodeStr(address_line1)}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>{t('general.zip')}</Label>
                      <Input
                        type='text'
                        name='postcode'
                        value={postcode}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>{t('general.city')}</Label>
                      <Input
                        type='text'
                        name='locality'
                        value={locality}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label>{t('general.country')}</Label>
                      <Input
                        type='select'
                        value={country_code}
                        onChange={onChange}
                        autoComplete='false'
                        required={true}
                        name='country_code'
                      >
                        <option value=''>{t('general.makeChoice')}</option>
                        <option value='BE'>{t('countries.Belgium')}</option>
                        <option value='FR'>{t('countries.France')}</option>
                        <option value='LU'>{t('countries.Luxembourg')}</option>
                        <option value='NL'>{t('countries.Netherlands')}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md='6'>
                    <input
                      type='submit'
                      value={
                        veterinariansState.veterinarians.current
                          ? t('general.save')
                          : t('general.add')
                      }
                      className='btn btn-primary'
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default VeterinarianForm;
