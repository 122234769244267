import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { GlobalContext } from '../../store/Provider';
import checkPermission from '../../utils/checkPermission';

const OwnerRoute = ({ component: Component, slug, ...rest }) => {
  const { authState } = useContext(GlobalContext);

  if (!authState.isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        authState.user.roles.includes('owner') ||
        checkPermission(authState.permissions, slug) ? (
          <Component {...props} />
        ) : (
          <Redirect to='/dashboard' />
        )
      }
    />
  );
};

export default OwnerRoute;
