import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Button,
} from 'reactstrap';
import ContainerSpinner from '../layout/ContainerSpinner';
import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import {
  getLoadingplaces,
  setLPLoading,
} from '../../store/actions/loadingplaces';
import {
  addOffer,
  clearCurrentOffer,
  offersListAction,
  pageNumberAction,
  setLoading,
} from '../../store/actions/offers';
import { useTranslation } from 'react-i18next';
import { getWeekOptions } from '../../utils/Utils';
// import axios from 'axios';

const useLPO = (selectedLoadingplace, activeSupplier, user_data) => {
  const [lpoResults, setLPOResults] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [housingOptions, setHousingOptions] = useState([]);

  useEffect(() => {
    // async function fetchData() {
    //   try {
    //     const res = await axios.get(
    //       `/jsonapi/lp_offer_type?supplier_id=${activeSupplier}&loading_place_id=${selectedLoadingplace.id}&lang=${user_data.langcode[0].value}`
    //     );

    //     setLPOResults(res.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    function fetchData() {
      setLPOResults(selectedLoadingplace.options);
    }

    if (
      selectedLoadingplace &&
      Object.keys(selectedLoadingplace).length !== 0 &&
      selectedLoadingplace.constructor === Object
    ) {
      fetchData();
    }
  }, [selectedLoadingplace, activeSupplier, user_data]);

  useEffect(() => {
    if (
      lpoResults !== undefined &&
      lpoResults !== null &&
      lpoResults.length > 0
    ) {
      let uniqueCountryCodes = [];
      let uniqueTypes = [];
      let uniqueHousings = [];
      
      lpoResults.forEach(item => {
        if(item.deingeb) {
          uniqueCountryCodes.push(item.dropdown1)
          uniqueTypes.push(item.dropdown2);
          uniqueHousings.push(item.dropdown3);
        }
        // if (!uniqueCountryCodes.includes(item.dropdown1)) {
        //   uniqueCountryCodes.push(item.dropdown1);
        // }
        // if (!uniqueTypes.includes(item.dropdown2)) {
        //   uniqueTypes.push(item.dropdown2);
        // }
        // if (!uniqueHousings.includes(item.dropdown3)) {
        //   uniqueHousings.push(item.dropdown3);
        // }
      });
      setCountryOptions(uniqueCountryCodes);
      setTypeOptions(uniqueTypes);
      setHousingOptions(uniqueHousings);
    }
    // eslint-disable-next-line
  }, [lpoResults]);

  return [lpoResults, countryOptions, typeOptions, housingOptions];
};

const OfferForm = () => {
  const {
    suppliersState,
    alertsDispatch,
    authState,
    loadingplacesState,
    loadingplacesDispatch,
    offersState,
    offersDispatch,
    offersListDispatch,
    pageNumberDispatch,
    offersListState,
    pageNumberState
  } = useContext(GlobalContext);

  const { t } = useTranslation();
  const history = useHistory();

  const today = new Date();
  const localeDateString = today.toLocaleDateString();

  const [offer, setOffer] = useState({
    created: localeDateString,
    departure: '',
    // departure: getWeekOptions()[1],
    field_loading_place: '',
    field_remarks: '',
    field_supplier: suppliersState.suppliers.activeID,
    offer_lines: [],
  });

  const [activeLoadingplace, setActiveLoadingplace] = useState({});

  const [lpoResults, countryOptions, typeOptions, housingOptions] = useLPO(
    activeLoadingplace,
    suppliersState.suppliers.activeID,
    authState.user_data
  );

  useEffect(() => {
    if (offer.departure === '') {
      const defaultWeek = getWeekOptions()[1];
      setOffer({
        ...offer,
        departure: `${defaultWeek.year}-${defaultWeek.week}`,
      });
    }
  }, [offer]);

  useEffect(() => {
    if (loadingplacesState.loadingplaces.list === null) {
      getLoadingplaces()(loadingplacesDispatch);
    }
    if (loadingplacesState.loadingplaces.list.length === 1 && offer.field_loading_place === '') {
      const defaultLP = loadingplacesState.loadingplaces.list[0].id;
      setOffer({
        ...offer,
        field_loading_place: defaultLP,
      });
      

      let selected = loadingplacesState.loadingplaces.list.find(
        loadingplace => loadingplace.id === defaultLP
      );
      setActiveLoadingplace(selected);
    }
  }, [loadingplacesState.loadingplaces.list, loadingplacesDispatch, offer]);

  const [loadingPlacesOption, setLoadingPlacesOption] = useState([]);

  useEffect(() => {
    if (
      countryOptions.length > 0 &&
      typeOptions.length > 0 &&
      housingOptions.length > 0
    ) {
      setOffer({
        ...offer,
        field_loading_place: activeLoadingplace.id,
        offer_lines: [
          {
            dropdown1: countryOptions.length === 1 ? countryOptions[0] : '',
            dropdown2: typeOptions.length === 1 ? typeOptions[0] : '',
            dropdown3: housingOptions.length === 1 ? housingOptions[0] : '',
            numpigs: 0,
            error: false,
          },
        ],
      });
      let arr = []
      let newCountryOptions = [...countryOptions]
      let newTypeOptions = [...typeOptions]
      let newHousingOptions = [...housingOptions]
      newCountryOptions.map((v, index) => {
        arr.push(`${v} | ${newTypeOptions[index]} | ${newHousingOptions[index]}`)
      })
      setLoadingPlacesOption(arr);
    }
    // eslint-disable-next-line
  }, [countryOptions, typeOptions, housingOptions]);

  

  useEffect(() => {
    if (offer.offer_lines.length > 0) {
      setLPLoading(false)(loadingplacesDispatch);
    }

    // eslint-disable-next-line
  }, [offer.offer_lines]);

  const onLoadingplaceChange = e => {
    setLPLoading(true)(loadingplacesDispatch);
    let selected = null;
    if (e.target.value !== 'null') {
      selected = loadingplacesState.loadingplaces.list.find(
        loadingplace => loadingplace.id === e.target.value
      );

      setActiveLoadingplace(selected);
    } else {
      setActiveLoadingplace({});
    }
    setLPLoading(false)(loadingplacesDispatch);
  };

  const handleAddOfferLine = () => {
    setOffer({
      ...offer,
      offer_lines: [
        ...offer.offer_lines,
        {
          dropdown1: countryOptions.length === 1 ? countryOptions[0] : '',
          dropdown2: typeOptions.length === 1 ? typeOptions[0] : '',
          dropdown3: housingOptions.length === 1 ? housingOptions[0] : '',
          numpigs: 0,
          error: false,
        },
      ],
    });
  };

  const handleOfferLineCountryChange = e => {
    const val = e.target.value;
    const index = Number(e.target.name.split('-')[1]);

    setOffer({
      ...offer,
      offer_lines: offer.offer_lines.map((line, i) => {
        let res = {};
        index === i
          ? (res = {
              dropdown1: val,
              dropdown2: line.dropdown2,
              dropdown3: line.dropdown3,
              numpigs: line.numpigs,
              error: line.error,
            })
          : (res = line);
        return res;
      }),
    });
  };

  const handleOfferLineTypeChange = e => {
    const val = e.target.value;
    const index = Number(e.target.name.split('-')[1]);

    setOffer({
      ...offer,
      offer_lines: offer.offer_lines.map((line, i) => {
        let res = {};
        index === i
          ? (res = {
              dropdown1: line.dropdown1,
              dropdown2: val,
              dropdown3: line.dropdown3,
              numpigs: line.numpigs,
              error: line.error,
            })
          : (res = line);
        return res;
      }),
    });
  };

  const handleOfferLineHousingChange = e => {
    const val = e.target.value;
    const index = Number(e.target.name.split('-')[1]);

    setOffer({
      ...offer,
      offer_lines: offer.offer_lines.map((line, i) => {
        let res = {};
        index === i
          ? (res = {
              dropdown1: line.dropdown1,
              dropdown2: line.dropdown2,
              dropdown3: val,
              numpigs: line.numpigs,
              error: line.error,
            })
          : (res = line);
        return res;
      }),
    });
  };

  const handleOfferLineAmountChange = e => {
    const val = e.target.value;
    const index = Number(e.target.name.split('-')[1]);

    setOffer({
      ...offer,
      offer_lines: offer.offer_lines.map((line, i) => {
        let res = {};
        index === i
          ? (res = {
              dropdown1: line.dropdown1,
              dropdown2: line.dropdown2,
              dropdown3: line.dropdown3,
              numpigs: val,
              error: line.error,
            })
          : (res = line);
        return res;
      }),
    });
  };

  const handleOfferLineRemove = e => {
    const tr = e.target.parentElement.parentElement.parentElement;
    const index = Number(tr.dataset.key);
    if (offer.offer_lines.length === 1) {
      setAlert(
        t('alerts.warnings.offers.offerLineAtleastOne'),
        'warning'
      )(alertsDispatch);
    } else {
      setOffer({
        ...offer,
        offer_lines: offer.offer_lines.filter((line, id) => id !== index),
      });
    }
  };

  const onChange = e => setOffer({ ...offer, [e.target.name]: e.target.value });

  const prepareOfferSubmit = () => {
    const offerLines = offer.offer_lines;
    let preparedOfferLines = [];
    offerLines.forEach(line => {
      let lpoItem = [];
      if(line.dropdown1 !== '' && line.dropdown2 !== '' && line.dropdown3 !== '') {
        lpoItem = lpoResults.filter(
          item =>
            item.dropdown1  === line.dropdown1 &&
            item.dropdown2 === line.dropdown2 &&
            item.dropdown3 === line.dropdown3
        )[0];
      } else {
        lpoItem = lpoResults.filter(
          item =>
            `${item.dropdown1} | ${item.dropdown2} | ${item.dropdown3}`  === line.dropdown1 
            // &&
            // item.dropdown2 === line.dropdown2 &&
            // item.dropdown3 === line.dropdown3
        )[0];
      }

      if (lpoItem && line.numpigs > 0) {
        preparedOfferLines.push({
          country_code: lpoItem.country_code,
          offertype_code: lpoItem.offertype_code,
          dropdown1: line.dropdown1,
          // dropdown2: line.dropdown2,
          // dropdown3: line.dropdown3,
          numpigs: line.numpigs,
          error: false,
        });
      } else {
        preparedOfferLines.push({
          dropdown1: line.dropdown1,
          // dropdown2: line.dropdown2,
          // dropdown3: line.dropdown3,
          numpigs: line.numpigs,
          error: true,
        });
      }
    });
    const preparedOffer = {
      ...offer,
      offer_lines: preparedOfferLines,
    };

    setOffer(preparedOffer);

    return preparedOffer;
  };

  const handleSubmit = async () => {
    setLoading(true)(offersDispatch);

    let allowSubmit = true;
    let errorMessage = '';
    const offerToAdd = prepareOfferSubmit();

    offerToAdd.offer_lines.forEach(line => {
      if (line.error === true) {
        allowSubmit = false;
        errorMessage +=
          t('alerts.errors.offers.OfferLineCombinationNotValid') + '\n';
      }
    });

    if (offer.departure === '') {
      allowSubmit = false;
      errorMessage += t('alerts.errors.offers.selectWeek') + '\n';
    }

    if (activeLoadingplace.default_veterinarian === '') {
      allowSubmit = false;
      errorMessage +=
        t('alerts.errors.offers.selectedLoadingplaceHasNoVeterinarian') + '\n';
    }

    if (allowSubmit) {
      const status = 'ANVR';
      const res = await addOffer(offerToAdd, status)(offersDispatch);
      if (res.code === 400) {
        setAlert(res.msg, 'danger')(alertsDispatch);
      } else if (res.code === 300) {
        setAlert(res.msg, 'warning', 10000)(alertsDispatch);
        redirect();
      } else {
        setAlert(res.msg, 'success')(alertsDispatch);
        redirect();
      }
    } else {
      setAlert(errorMessage, 'danger', 10000)(alertsDispatch);
    }

    setLoading(false)(offersDispatch);
  };

  const redirect = async () => {
    offersListDispatch({ type: 'RESET' });
    pageNumberDispatch({ type: 'RESET' });

    const offers = {
      pageNumber:  1,
    };
    await offersListAction(offersListState, offers)(offersListDispatch);
    pageNumberAction(pageNumberState)(pageNumberDispatch);
    clearCurrentOffer()(offersDispatch);
    history.push('/offers');
  };

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col md='12'>
          <button className='btn btn-secondary' onClick={redirect}>
            {t('general.prev')}
          </button>
        </Col>
      </Row>
      <Form
        className='form-material'
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Row>
          <Col lg='4' md='12'>
            <Card>
              <CardBody>
                {offersState.offers.loading && <ContainerSpinner />}
                <CardTitle>{t('general.generalInfo')}</CardTitle>

                <Row className='mt-3'>
                  <Col md='12'>
                    <FormGroup>
                      <Label>{t('loadingplaces.singular')}</Label>
                      <Input
                        type='select'
                        onChange={onLoadingplaceChange}
                        name='field_loading_place'
                        value={activeLoadingplace.id}
                        required={true}
                      >
                        { !activeLoadingplace.id  ? (
                         <option value=''>{t('general.makeChoice')}</option>
                        ):''}
                        {loadingplacesState.loadingplaces.list.map((loadingplace) => {
                          if(loadingplace.options !== undefined) {
                            const _res = loadingplace?.options.filter((data) => data.deingeb)
                            // if(_res.length === loadingplace?.options.length) {
                              return (
                                <option
                                  key={loadingplace.id}
                                  value={loadingplace.id}
                                >
                                  {loadingplace.lp_code} -{' '}
                                  {decodeStr(loadingplace.address_line1)},{' '}
                                  {loadingplace.postcode} {loadingplace.locality}
                                </option>
                              )
                            // }
                          }
                        })}
                      </Input>
                    </FormGroup>
                    {activeLoadingplace.default_veterinarian === '' && (
                      <span className='text-danger mb-3 d-block'>
                        {t(
                          'alerts.errors.offers.selectedLoadingplaceHasNoVeterinarian'
                        )}
                      </span>
                    )}
                  </Col>
                  <Col md='12'>
                    <FormGroup>
                      <Label>{t('offers.weekOfDeparture')}</Label>

                      <Input
                        type='select'
                        name='departure'
                        value={offer.departure}
                        onChange={onChange}
                        required={true}
                      >
                        <option disabled={true} value=''>
                          {t('general.makeChoice')}
                        </option>
                        {getWeekOptions().map(option => (
                          <option
                            value={`${option.year}-${option.week}`}
                            key={option.week}
                          >
                            {option.year} W{option.week} (
                            {option.monday.getDate()}/
                            {option.monday.getMonth() + 1} -{' '}
                            {option.friday.getDate()}/
                            {option.friday.getMonth() + 1})
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md='12'>
                    <FormGroup>
                      <Label>{t('general.remarks')}</Label>
                      <Input
                        type='textarea'
                        rows='4'
                        onChange={onChange}
                        name='field_remarks'
                        value={offer.field_remarks}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg='8' md='12'>
            <Card>
              {loadingplacesState.loadingplaces.loading ? (
                <ContainerSpinner />
              ) : (
                ''
              )}
              <CardBody>
                <CardTitle>{t('offers.loadingplaceOffer')}</CardTitle>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t('offers.countryCode')} | {t('offers.typeOfPigs')} | {t('offers.housingStandard')}</th>
                      {/* <th>{t('offers.typeOfPigs')}</th>
                      <th>{t('offers.housingStandard')}</th>*/}
                      <th>{t('offers.amount')}</th> 
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {offer.offer_lines.length > 0 &&
                      offer.offer_lines.map((line, index) => {
                        return (
                          <tr key={index} data-key={index}>
                            <td className={line.error && `alert-danger`}>
                              <Input
                                type='select'
                                name={`dropdown1-${index}`}
                                required={true}
                                value={line.dropdown1}
                                onChange={handleOfferLineCountryChange}
                              >
                                <option value='' disabled={true}>
                                  {t('general.makeChoice')}
                                </option>
                                { loadingPlacesOption.map((option, index) => {
                                  return (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  );
                                })}
                              </Input>
                            </td>
                            {/* <td className={line.error && `alert-danger`}>
                              <Input
                                type='select'
                                name={`dropdown2-${index}`}
                                required={true}
                                value={line.dropdown2}
                                onChange={handleOfferLineTypeChange}
                              >
                                <option value='' disabled={true}>
                                  {t('general.makeChoice')}
                                </option>

                                {typeOptions.map((option, index) => {
                                  return (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  );
                                })}
                              </Input>
                            </td>
                            <td className={line.error && `alert-danger`}>
                              <Input
                                type='select'
                                name={`dropdown3-${index}`}
                                required={true}
                                value={line.dropdown3}
                                onChange={handleOfferLineHousingChange}
                              >
                                <option value='' disabled={true}>
                                  {t('general.makeChoice')}
                                </option>
                                {housingOptions.map((option, index) => {
                                  if (option === 'nohousing') {
                                    return (
                                      <option key={index} value={option}>
                                        {t('offers.noHousing')}
                                      </option>
                                    );
                                  }
                                  return (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  );
                                })}
                              </Input>
                            </td> */}
                            <td className={line.error && `alert-danger`}>
                              <Input
                                onChange={handleOfferLineAmountChange}
                                type='number'
                                name={`numpigs-${index}`}
                                value={line.numpigs}
                                required={true}
                              />
                            </td>
                            <td
                              className={`table-action-fix text-center ${
                                line.error && `alert-danger`
                              }`}
                            >
                              {index !== 0 && (
                                <span
                                  onClick={handleOfferLineRemove}
                                  className='span-action text-primary'
                                >
                                  <i className='mdi mdi-close'></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <div className='text-right'>
                  <Button
                    className='btn-outline-primary'
                    onClick={handleAddOfferLine}
                    disabled={offer.field_loading_place === ''}
                  >
                    {t('offers.addNewLine')}
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Row>
              <Col md='12' className='text-right'>
                <button type='submit' className='btn btn-primary' disabled={offersState.offers.loading}>
                  {t('general.saveAndSend')}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default OfferForm;
