import axios from 'axios';
import i18next from 'i18next';

import setAuthToken from '../../utils/setAuthToken';
import setCSRFToken from '../../utils/setCSRFToken';
import setLocalStorageLogin from '../../utils/setLocalStorageLogin';
import clearLocalStorageLogin from '../../utils/clearLocalStorageLogin';

// Exported login function
export const login =
  (formData, withLocalStorage = false) =>
  async dispatch => {
    // Login flow

    // Set loading to true
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });

    // Init empty response object to return to the component
    let response = {
      code: 0,
      msg: '',
    };

    // Check if user is online, if not then return error message
    // Clear localStorage login to prevent infinite loop
    // Set loading to false
    if (!navigator.onLine) {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.auth.internetConnectionRequired');
      localStorage.setItem('localstorage_login', false);
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
      return response;
    }

    // Init empty user id
    let uid;

    // Check if we're logging in with localStorage
    if (withLocalStorage) {
      // Set axios default headers with util funcion
      setAuthToken(
        {
          access_token: localStorage.getItem('access_token'),
          csrf_token: localStorage.getItem('csrf_token'),
        },
        true
      );
      // Get the user id from localStorage
      uid = JSON.parse(localStorage.getItem('user_data')).uid;
    } else {
      try {
        // Request to get initial information about the user
        const res = await axios.post(
          '/jsonapi/user_login?_format=json',
          formData
        );
        // Set axios default headers with util funcion
        setAuthToken(
          {
            access_token: res.data.access_token,
            csrf_token: res.data.csrf_token,
          },
          true
        );
        // Get the user id from the response
        uid = res.data.current_user.uid;
        // Set localStorage login with util function
        setLocalStorageLogin(res.data);
      } catch (error) {
        // If error is caught in request, set correct codes & messages
        // Set loading to false and return the response to halt the login flow
        response.code = error.response.status;
        switch (response.code) {
          case 406:
            response.msg = i18next.t('alerts.errors.auth.verificationError');
            break;
          case 418:
            response.msg = i18next.t('alerts.errors.auth.verificationRequired');
            break;
          case 500:
            response.msg = i18next.t('alerts.errors.500');
            break;
          default:
            if (
              error.response.data.message ===
              'Sorry, unrecognized username or password.'
            ) {
              response.msg = i18next.t(
                'alerts.errors.auth.userOrPassIncorrect'
              );
            } else {
              response.msg = i18next.t('alerts.errors.500');
            }
            break;
        }
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
        return response;
      }
    }

    // User is authenticated at this point, we have the uid and other tokens/data set in localStorage

    // Get user data & permissions with helper function
    const userDataObject = await getUserDataFromServer(uid);
    // Check if we got an error during these fetches, if so then halt the flow and let the user know
    if (userDataObject.error) {
      response.code = 500;
      response.msg = i18next.t('alerts.errors.500');
      localStorage.setItem('localstorage_login', false);
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
      return response;
    }

    // User is authenticated, we fetched the user_data and permissions succesfuly
    // Set all of this in state
    dispatch({
      type: 'LOGIN',
      payload: {
        access_token: localStorage.getItem('access_token'),
        logout_token: localStorage.getItem('logout_token'),
        csrf_token: localStorage.getItem('csrf_token'),
        user: JSON.parse(localStorage.getItem('user_data')),
        user_data: userDataObject.data.user_data,
        permissions: userDataObject.data.permissions,
      },
    });

    response.code = 200;

    return response;
  };

// Helper function to get user data & permissions from the server
const getUserDataFromServer = async uid => {
  let userDataObject = {
    data: {
      user_data: null,
      permissions: null,
    },
    error: false,
  };

  try {
    // Fetch the user_data portion
    const res = await axios.get(`/user/${uid}?_format=json`);

    // Set the correct language for the user in localStorage
    res.data.langcode[0].value
      ? localStorage.setItem('language', res.data.langcode[0].value)
      : localStorage.setItem('language', 'nl');

    userDataObject.data.user_data = res.data;
  } catch (error) {
    userDataObject.error = true;
  }

  try {
    // Fetch the permissions portion
    const res = await axios.get(
      `/jsonapi/contributor_get_permissions?uid=${uid}&activeSupplier=${userDataObject.data.user_data.field_active_supplier[0].target_id}`
    );
    userDataObject.data.permissions = res.data;
  } catch (error) {
    userDataObject.error = true;
  }

  return userDataObject;
};

// Exported logout function
export const logout = () => async dispatch => {
  // Logout flow

  // Get logout token from localStorage
  const logoutToken = localStorage.getItem('logout_token');
  // Clear localStorage
  clearLocalStorageLogin();
  // Set isAuthenticated to false to let the component know (via state) we're handling the logout
  dispatch({
    type: 'SET_IS_AUTHENTICATED',
    payload: false,
  });

  // Attempt to make the cleanup requests
  try {
    const res = await axios.get(`/rest/session/token?_format=json`);
    setCSRFToken(res.data);
    await axios.post(`/user/logout?token=${logoutToken}`);
  } catch (error) {
    console.log(error);
  }

  setAuthToken(false, false);

  dispatch({
    type: 'LOGOUT',
  });
};

// Simple exported function to set loading
export const setLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

// Exported function to make changes to the user data
export const editUserData = modifications => dispatch => {
  dispatch({
    type: 'EDIT_USER_DATA',
    payload: modifications,
  });
};

// Exported function to request a password reset
export const resetPassword = formData => async dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: true,
  });

  let response = {
    code: 0,
    msg: '',
  };

  try {
    await axios.post('/user/lost-password-reset?_format=json', formData);
    response.code = 200;
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.auth.failedToResetPassword');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });

  return response;
};

// Exported function to update the user permissions to state
// Used when supplier is switched
export const getUserPermissions = (uid, activeSupplier) => async dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: true,
  });

  const res = await axios.get(
    `/jsonapi/contributor_get_permissions?uid=${uid}&activeSupplier=${activeSupplier}`
  );

  dispatch({
    type: 'RELOAD_PERMISSIONS',
    payload: res.data,
  });
};
