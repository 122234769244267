import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { formatVKIDateString } from '../../../utils/Utils';

const OverviewVKI = ({ vki }) => {
  const { t } = useTranslation();

  return (
    <div className='mt-2'>
      <h4 className='mb-4'>Overzicht VKI</h4>
      <Row>
        <Col md='12'>
          <h5>1. {t('vki.informationProducerAndVeterinarian')}</h5>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.nameProducer')}</td>
                <td>{vki.nameProducer}</td>
              </tr>
              <tr>
                <td>{t('vki.addressProducer')}</td>
                <td>
                  {vki.addressProducer}, {vki.zipProducer} {vki.cityProducer}{' '}
                  {vki.countryProducer}
                </td>
              </tr>
              <tr>
                <td>{t('vki.codeFittings')}</td>
                <td>{vki.codeFittings}</td>
              </tr>
              <tr>
                <td>{t('vki.emailProducer')}</td>
                <td>{vki.emailProducer}</td>
              </tr>
              <tr>
                <td>{t('vki.phoneProducer')}</td>
                <td>{vki.phoneProducer}</td>
              </tr>
              <tr>
                <td>{t('vki.nameVeterinarian')}</td>
                <td>{vki.nameVeterinarian}</td>
              </tr>
              <tr>
                <td>{t('vki.addressVeterinarian')}</td>
                <td>
                  {vki.addressVeterinarian}, {vki.zipVeterinarian}{' '}
                  {vki.cityVeterinarian} {vki.countryVeterinarian}
                </td>
              </tr>
              <tr>
                <td>{t('vki.emailVeterinarian')}</td>
                <td>{vki.emailVeterinarian}</td>
              </tr>
              <tr>
                <td>{t('vki.phoneVeterinarian')}</td>
                <td>{vki.phoneVeterinarian}</td>
              </tr>
            </tbody>
          </table>

          <h5 className='mt-4'>2. {t('vki.informationPigs')}</h5>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.amountOfPigs')}</td>
                <td>{vki.amountOfPigs}</td>
              </tr>
              <tr>
                <td>{t('vki.setupDate')}</td>
                <td>{formatVKIDateString(vki.setupDate)}</td>
              </tr>
              <tr>
                <td>{t('vki.serialNumber')}</td>
                <td>{vki.serialNumber}</td>
              </tr>
              <tr>
                <td>{t('vki.departureDate')}</td>
                <td>{formatVKIDateString(vki.departureDate)}</td>
              </tr>
              <tr>
                <td>{t('vki.typeofPigs')}</td>
                <td>
                  {Number(vki.typeOfPigs) === 1
                    ? t('vki.fattening')
                    : t('vki.breeding')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.outdoors')}</td>
                <td>
                  {Number(vki.outdoors) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.controlledHousing')}</td>
                <td>
                  {Number(vki.controlledHousing) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className='mt-4'>3. {t('vki.medicationAndAdditives')}</h5>

          <p className='mt-3'>{t('vki.infoMedication')}</p>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.antiparasiticDrugs')}</td>
                <td>
                  {Number(vki.antiparasiticDrugs) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.antibiotics')}</td>
                <td>
                  {Number(vki.antibiotics) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.antiInflamatoryDrugs')}</td>
                <td>
                  {Number(vki.antiInflamatoryDrugs) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
            </tbody>
          </table>

          <p className='mt-3'>{t('vki.infoExtraMedication')}</p>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.nameMedication')} 1</td>
                <td>{vki.nameMedication1 ? vki.nameMedication1 : '/'}</td>
              </tr>
              <tr>
                <td>{t('vki.dateAdministrationMedication')} 1</td>
                <td>
                  {vki.dateMedication1
                    ? formatVKIDateString(vki.dateMedication1)
                    : '/'}
                </td>
              </tr>
              <tr>
                <td>{t('vki.waitingDurationMedication')} 1</td>
                <td>
                  {vki.durationMedication1 ? vki.durationMedication1 : '/'}
                </td>
              </tr>
              <tr>
                <td>{t('vki.nameMedication')} 2</td>
                <td>{vki.nameMedication2 ? vki.nameMedication2 : '/'}</td>
              </tr>
              <tr>
                <td>{t('vki.dateAdministrationMedication')} 2</td>
                <td>
                  {vki.dateMedication2
                    ? formatVKIDateString(vki.dateMedication2)
                    : '/'}
                </td>
              </tr>
              <tr>
                <td>{t('vki.waitingDurationMedication')} 2</td>
                <td>
                  {vki.durationMedication2 ? vki.durationMedication2 : '/'}
                </td>
              </tr>
              <tr>
                <td>{t('vki.nameMedication')} 3</td>
                <td>{vki.nameMedication3 ? vki.nameMedication3 : '/'}</td>
              </tr>
              <tr>
                <td>{t('vki.dateAdministrationMedication')} 3</td>
                <td>
                  {vki.dateMedication3
                    ? formatVKIDateString(vki.dateMedication3)
                    : '/'}
                </td>
              </tr>
              <tr>
                <td>{t('vki.waitingDurationMedication')} 3</td>
                <td>
                  {vki.durationMedication3 ? vki.durationMedication3 : '/'}
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className='mt-4'>4. {t('vki.problemsIdentifiedStables')}</h5>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.deaths')}</td>
                <td>
                  {Number(vki.deaths) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.causeOfDeaths')}</td>
                <td>{vki.causeOfDeaths ? vki.causeOfDeaths : '/'}</td>
              </tr>
              <tr>
                <td>{t('vki.sickness')}</td>
                <td>
                  {Number(vki.sickness) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.causeOfSickness')}</td>
                <td>{vki.causeOfSickness ? vki.causeOfSickness : '/'}</td>
              </tr>
            </tbody>
          </table>

          <h5 className='mt-4'>5. {t('vki.investigationsCarriedOutTitle')}</h5>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.investigationsCarriedOutDesc')}</td>
                <td>
                  {Number(vki.investigated) === 1
                    ? t('general.yes')
                    : t('general.no')}
                </td>
              </tr>
              <tr>
                <td>{t('vki.typeofInvestigation')}</td>
                <td>{t('vki.salmonella')}</td>
              </tr>
              <tr>
                <td>{t('vki.dateofInvestigation')}</td>
                <td>{formatVKIDateString(vki.investigationDate)}</td>
              </tr>
              <tr>
                <td>{t('vki.analysisResult')}</td>
                <td>{vki.investigationResult}</td>
              </tr>
              <tr>
                <td>{t('vki.finding')}</td>
                <td>
                  {Number(vki.typeOfCompany) === 1 && t('vki.companyTypeRisk')}
                  {Number(vki.typeOfCompany) === 2 &&
                    t('vki.companyTypeNoRisk')}
                  {Number(vki.typeOfCompany) === 3 &&
                    t('vki.companyTypeFittings')}
                </td>
              </tr>
              <tr>
                <td>
                  {t('vki.optional')} {t('vki.dateofInvestigation')}
                </td>
                <td>
                  {vki.optionalInvestigation ? vki.optionalInvestigation : '/'}
                </td>
              </tr>
              <tr>
                <td>
                  {t('vki.optional')} {t('vki.typeofInvestigation')}
                </td>
                <td>
                  {vki.optionalInvestigationDate
                    ? formatVKIDateString(vki.optionalInvestigationDate)
                    : '/'}
                </td>
              </tr>
              <tr>
                <td>
                  {t('vki.optional')} {t('vki.analysisResult')}
                </td>
                <td>
                  {vki.optionalInvestigationResult
                    ? vki.optionalInvestigationResult
                    : '/'}
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className='mt-4'>6. {t('vki.exportTitle')}</h5>
          <table className='table table-sm table-td-width-even mt-3'>
            <tbody>
              <tr>
                <td>{t('vki.listOfCountries')}</td>
                <td>{vki.listOfCountries}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default OverviewVKI;
