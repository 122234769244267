import React from 'react';
import { Input, Label } from 'reactstrap';

const CountryCheckbox = ({ ISO, label, onChange, checked }) => {
  return (
    <div className='form-check col-md-6'>
      <Input
        name={ISO}
        type='checkbox'
        value={ISO}
        onChange={onChange}
        checked={checked}
      />
      <Label for={ISO}>{label}</Label>
    </div>
  );
};

export default CountryCheckbox;
