import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../../store/Provider';
import { setCurrentArticle } from '../../store/actions/articles';
import "./dashboard.css";

const SocialCard = ({ title, summary, image, category, url, nid }) => {
  const { articlesDispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const onClick = () => {
    setCurrentArticle(nid)(articlesDispatch);
  };

  return (
    <Card style={{ height: '100%', marginBottom: 0 }} className='featured-blog-card'>
      <CardImg top width='100%' className='featured-blog-image' src={image} alt={title} />
      <CardBody>
        <CardTitle dangerouslySetInnerHTML={{__html: title}}/>
        <CardText>
          <small className={`text-gray`}>{category}</small>
        </CardText>
        <CardText dangerouslySetInnerHTML={{__html: summary}}/>
        <div className='mt-2'>
          <Link className='btn btn-secondary' onClick={onClick} to={url}>
            {t('general.readMore')}
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

export default SocialCard;