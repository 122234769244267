import React, { createContext, useReducer } from 'react';

import suppliersReducer from './reducers/suppliers';
import suppliersInitialState from './initialStates/suppliers';

import contributorsReducer from './reducers/contributors';
import contributorsInitialState from './initialStates/contributors';

import veterinariansReducer from './reducers/veterinarians';
import veterinariansInitialState from './initialStates/veterinarians';

import stablesReducer from './reducers/stables';
import stablesInitialState from './initialStates/stables';

import alertsReducer from './reducers/alerts';
import alertsInitialState from './initialStates/alerts';

import articlesReducer from './reducers/articles';
import articlesInitialState from './initialStates/articles';

import authReducer from './reducers/auth';
import authInitialState from './initialStates/auth';

import layoutReducer from './reducers/layout';
import layoutInitialState from './initialStates/layout';

import documentsReducer from './reducers/documents';
import documentsInitialState from './initialStates/documents';

import loadingplacesReducer from './reducers/loadingplaces';
import loadingplacesInitialState from './initialStates/loadingplaces';

import offersReducer from './reducers/offers';
import offersInitialState from './initialStates/offers';

import offerListReducer from './reducers/offersList';
import pageNumberReducer from './reducers/pageNumber';

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [suppliersState, suppliersDispatch] = useReducer(
    suppliersReducer,
    suppliersInitialState
  );
  const [contributorsState, contributorsDispatch] = useReducer(
    contributorsReducer,
    contributorsInitialState
  );
  const [veterinariansState, veterinariansDispatch] = useReducer(
    veterinariansReducer,
    veterinariansInitialState
  );
  const [stablesState, stablesDispatch] = useReducer(
    stablesReducer,
    stablesInitialState
  );
  const [alertsState, alertsDispatch] = useReducer(
    alertsReducer,
    alertsInitialState
  );
  const [articlesState, articlesDispatch] = useReducer(
    articlesReducer,
    articlesInitialState
  );
  const [authState, authDispatch] = useReducer(authReducer, authInitialState);
  const [layoutState, layoutDispatch] = useReducer(
    layoutReducer,
    layoutInitialState
  );
  const [documentsState, documentsDispatch] = useReducer(
    documentsReducer,
    documentsInitialState
  );
  const [loadingplacesState, loadingplacesDispatch] = useReducer(
    loadingplacesReducer,
    loadingplacesInitialState
  );

  const [offersState, offersDispatch] = useReducer(
    offersReducer,
    offersInitialState
  );

  const [offersListState, offersListDispatch] = useReducer(
    offerListReducer,
    {"offers": []}
  );
  const [pageNumberState, pageNumberDispatch] = useReducer(
    pageNumberReducer,
    1
  );

  return (
    <GlobalContext.Provider
      value={{
        offersState,
        offersDispatch,
        offersListState, 
        offersListDispatch,
        pageNumberState, 
        pageNumberDispatch,
        loadingplacesState,
        loadingplacesDispatch,
        alertsState,
        alertsDispatch,
        articlesState,
        articlesDispatch,
        authState,
        authDispatch,
        layoutState,
        layoutDispatch,
        suppliersState,
        suppliersDispatch,
        contributorsState,
        contributorsDispatch,
        veterinariansState,
        veterinariansDispatch,
        stablesState,
        stablesDispatch,
        documentsState,
        documentsDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
