import React, { useContext, Fragment, useEffect, useState, useCallback } from 'react';
// import Language from './Language';
import { decodeStr } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import logo from '../../assets/images/logo.svg';
import PageSpinner from './PageSpinner';
import Alerts from './Alerts';

import { useTranslation } from 'react-i18next';

import checkPermission from '../../utils/checkPermission';

import { GlobalContext } from '../../store/Provider';
import {
  loadAllSuppliers,
  loadActiveSupplier,
  setActiveSupplier,
  clearSupplierState,
  setSupplierLoading,
} from '../../store/actions/suppliers';
import {
  getContributors,
  clearContributorState,
} from '../../store/actions/contributors';
import {
  getVeterinarians,
  clearVeterinarianState,
} from '../../store/actions/veterinarians';
import { getStables, clearStableState } from '../../store/actions/stables';
import { setAlert } from '../../store/actions/alerts';
import { getArticles } from '../../store/actions/articles';
import { getUserPermissions, logout } from '../../store/actions/auth';
import { changeTopbar, changeSidebar } from '../../store/actions/layout';
import {
  getDocuments,
  clearDocumentState,
} from '../../store/actions/documents';
import {
  getLoadingplaces,
  clearLoadingplaceState,
} from '../../store/actions/loadingplaces';
import { getOffers, clearOfferState, offersListAction, pageNumberAction } from '../../store/actions/offers';

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return status;
};

const Header = () => {
  const {
      stablesDispatch,
      suppliersState,
      suppliersDispatch,
      contributorsDispatch,
      veterinariansDispatch,
      alertsDispatch,
      articlesDispatch,
      authState,
      authDispatch,
      layoutState,
      layoutDispatch,
      documentsDispatch,
      loadingplacesDispatch,
      offersDispatch,
      offersListState,
      offersListDispatch,
      pageNumberState,
      pageNumberDispatch,
  } = useContext(GlobalContext);

  const { t, i18n } = useTranslation();

  const isOnline = useNavigatorOnLine();

  useEffect(() => {
    const ifOwner = () => {
      return authState.user.roles && authState.user.roles.includes('owner');
    };

    const offerInterval = setInterval(() => {
      if (isOnline) {
        getOffers()(offersDispatch);
        console.log('Syncing offers...');
      }
    }, 120000);

    const documentInterval = setInterval(() => {
      if (
        (ifOwner() || checkPermission(authState.permissions, 'documents')) &&
        isOnline
      ) {
        getDocuments(authState.permissions)(documentsDispatch);
        console.log('Syncing documents...');
      }
    }, 180000);

    const dataInterval = setInterval(() => {
      if (isOnline) {
        console.log('Syncing data...');
        if (
          ifOwner() ||
          checkPermission(authState.permissions, 'contributors')
        ) {
          getContributors(suppliersState.suppliers.activeID)(
            contributorsDispatch
          );
        }

        getVeterinarians()(veterinariansDispatch);
        getStables()(stablesDispatch);

        getLoadingplaces(
          suppliersState.suppliers.activeID,
          authState.user_data.langcode[0].value
        )(loadingplacesDispatch);
      }
    }, 8640000);

    return () => {
      clearInterval(offerInterval);
      clearInterval(documentInterval);
      clearInterval(dataInterval);
    };
  }, [
    authState.permissions,
    authState.user.roles,
    authState.user_data.langcode,
    contributorsDispatch,
    stablesDispatch,
    veterinariansDispatch,
    documentsDispatch,
    loadingplacesDispatch,
    offersDispatch,
    isOnline,
    suppliersState.suppliers.activeID,
  ]);

  const getOffersList = useCallback(async () => {
    try {
      offersListDispatch({ type: 'RESET' });
      pageNumberDispatch({ type: 'RESET' });

      const offers = {
          pageNumber:  1,
      };
        await offersListAction(offersListState, offers)(offersListDispatch);
        pageNumberAction(pageNumberState)(pageNumberDispatch);

    } catch (error) {
        console.error(error);
    }
}, [offersListDispatch, offersListState, pageNumberDispatch, pageNumberState]);

  useEffect(() => {
    if (
      suppliersState.suppliers.activeID === null &&
      suppliersState.suppliers.list.length === 0 &&
      suppliersState.suppliers.loading === false
    ) {
      initSuppliers();
    } else {
      fetchData();
    }
    getOffersList()

    // eslint-disable-next-line
  }, [suppliersState.suppliers.activeID]);

  const initSuppliers = async () => {
    const alerts = [];

    setSupplierLoading(true)(suppliersDispatch);

    getArticles()(articlesDispatch);

    alerts.push(
      await loadActiveSupplier(
        authState.user.uid,
        authState.user.name
      )(suppliersDispatch)
    );
    alerts.push(
      await loadAllSuppliers(
        authState.user.uid,
        authState.user.name
      )(suppliersDispatch)
    );

    setSupplierLoading(false)(suppliersDispatch);

    showAlerts(alerts);
  };

  const fetchData = async () => {
    if(suppliersState.suppliers.list.length > 0 && suppliersState.suppliers.loading && suppliersState.suppliers.activeID !== null) {
      console.log({   suppliersState: suppliersState.suppliers });
      setSupplierLoading(true)(suppliersDispatch);
    }
    const alerts = [];
    const ifOwner = () => {
      return authState.user.roles && authState.user.roles.includes('owner');
    };
    // NOTE: Order in which data is fetched is important here!

    if (ifOwner() || checkPermission(authState.permissions, 'contributors')) {
      alerts.push(
        await getContributors(suppliersState.suppliers.activeID)(
          contributorsDispatch
        )
      );
    }
    if (
      ifOwner() || checkPermission(authState.permissions, 'documents')
    ) {
      alerts.push(getDocuments(authState.permissions)(documentsDispatch));
    }

    alerts.push(await getVeterinarians()(veterinariansDispatch));
    alerts.push(await getStables()(stablesDispatch));

    if (suppliersState.suppliers.activeID === null) {
      alerts.push(
        await getLoadingplaces(
          authState.user_data.field_active_supplier[0].target_id,
          authState.user_data.langcode[0].value
        )(loadingplacesDispatch)
      );
    } else {
      alerts.push(
        await getLoadingplaces(
          suppliersState.suppliers.activeID,
          authState.user_data.langcode[0].value
        )(loadingplacesDispatch)
      );
    }

    alerts.push(await getOffers()(offersDispatch));

    showAlerts(alerts);
    setSupplierLoading(false)(suppliersDispatch);
  };

  const showAlerts = alerts => {
    alerts.forEach(alert => {
      if (alert.code === 400) {
        setAlert(alert.msg, 'danger')(alertsDispatch);
      } else if (alert.code === 503) {
        setAlert(alert.msg, 'warning')(alertsDispatch);
      }
    });
  };

  useEffect(() => {
    const sidebarLocalStorage = JSON.parse(localStorage.getItem('sidebarOpen'));
    if (sidebarLocalStorage === null) {
      localStorage.setItem('sidebarOpen', true);
    }

    if (
      localStorage.getItem('language') &&
      localStorage.getItem('language') !== localStorage.getItem('i18nextLng')
    ) {
      i18n.changeLanguage(localStorage.getItem('language'));
    }
    // eslint-disable-next-line
  }, []);

  const showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  };

  const toggle = () => {
    if (window.innerWidth >= 768) {
      changeSidebar()(layoutDispatch);
    } else {
      changeTopbar()(layoutDispatch);
    }
  };

  const handleLogout = async () => {
    clearVeterinarianState()(veterinariansDispatch);
    clearSupplierState()(suppliersDispatch);
    clearStableState()(stablesDispatch);
    clearOfferState()(offersDispatch);
    clearLoadingplaceState()(loadingplacesDispatch);
    clearContributorState()(contributorsDispatch);
    clearDocumentState()(documentsDispatch);

    logout()(authDispatch);
  };

  const preventSubmit = e => {
    e.preventDefault();
  };

  const handleSupplierChange = async e => {
    setSupplierLoading(true)(suppliersDispatch);

    const supplierID = e.target.value;

    const uuid = suppliersState.suppliers.list.find(
      supplier => Number(supplier.id) === Number(supplierID)
    );

    getUserPermissions(authState.user.uid, supplierID)(authDispatch);

    const res = await setActiveSupplier(
      e.target.value,
      uuid,
      authState.user.uid,
      authState.user.name
    )(suppliersDispatch);
    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    }
    setSupplierLoading(false)(suppliersDispatch);
  };

  return (
    <Fragment>
      {suppliersState.suppliers.loading ? (
        <PageSpinner msg={t('general.weAreGettingNewData')} />
      ) : (
        ''
      )}
      <header className='topbar navbarbg'>
        <Navbar className='top-navbar navbar-dark' expand='md'>
          <div className='navbar-header' id='logobg'>
            <span
              className='nav-toggler d-block d-md-none text-white'
              onClick={showMobilemenu}
            >
              <i className='ti-menu ti-close' />
            </span>
            <NavbarBrand>
              <b className='logo-icon'>
                <img
                  src={logo}
                  width='25px'
                  alt='BPG AP'
                  className='light-logo'
                />
              </b>
              {layoutState.sidebarOpen &&
                window.innerWidth >= 768 &&
                t('general.appName')}
              {window.innerWidth < 768 && t('general.appName')}
            </NavbarBrand>
            <span
              className='topbartoggler d-block d-md-none text-white'
              onClick={toggle}
            >
              <i className='ti-more' />
            </span>
          </div>
          <Collapse
            className='navbarbg'
            isOpen={layoutState.topbarOpen}
            navbar
            data-navbarbg='skin1'
          >
            <Nav className='float-left' navbar>
              <NavLink href='#' className='d-none d-md-block' onClick={toggle}>
                <i className='ti-menu' />
              </NavLink>
            </Nav>
            <Nav className='ml-auto float-right' navbar>
              {suppliersState.suppliers.list !== null &&
              suppliersState.suppliers.list.length > 1 &&
              suppliersState.suppliers.activeID !== null ? (
                <Form
                  className='mt-3 supplier-switcher'
                  onSubmit={preventSubmit}
                >
                  <FormGroup>
                    <Input
                      type='select'
                      value={suppliersState.suppliers.activeID}
                      onChange={handleSupplierChange}
                    >
                      {Object.entries(suppliersState.suppliers.list).map(
                        ([key, value]) => {
                          return (
                            <option key={key} value={value.id}>
                              {decodeStr(value.title)}
                            </option>
                          );
                        }
                      )}
                    </Input>
                  </FormGroup>
                </Form>
              ) : (
                ''
              )}

              {/* <Language /> */}
              <UncontrolledDropdown nav inNavbar className='ml-4 mr-2'>
                <DropdownToggle nav caret className='pro-pic'>
                  <i className='icon-user'></i>
                </DropdownToggle>
                <DropdownMenu right className='user-dd pb-0'>
                  <div className='d-flex no-block align-items-center p-3 mb-0 border-bottom'>
                    <div className='ml-3'>
                      <h4 className='mb-0'>
                        {typeof authState.user_data.field_first_name[0] !==
                        'undefined'
                          ? decodeStr(
                              authState.user_data.field_first_name[0].value
                            )
                          : ''}
                      </h4>
                      <p className='text-muted mb-0'>
                        {authState.user_data.mail[0].value}
                      </p>
                    </div>
                  </div>
                  <Link to='/account'>
                    <DropdownItem className='border-bottom'>
                      <i className='ti-settings mr-1 ml-1' />{' '}
                      {t('account.settings')}
                    </DropdownItem>
                  </Link>
                  <DropdownItem onClick={handleLogout}>
                    <i className='fa fa-power-off mr-1 ml-1' />{' '}
                    {t('general.logout')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </header>

      <div className='alert-region'>
        <Alerts />
      </div>
    </Fragment>
  );
};

export default Header;
