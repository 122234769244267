import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  graphDEAANTA,
  graphDETOTBG,
  graphDEAANTAKLGR,
  graphDEGEMNV,
  graphDEGEMSD,
  graphDEGEMVD,
  graphDELTMTO,
  graphDEBPGTO,
  graphDEMABBU,
  graphDEGEWBU,
  graphDESLAHS,
  graphDEGEMMB,
} from '../../utils/ReportingUtils';

import ContainerSpinner from '../layout/ContainerSpinner';
// import MissingDataOverlay from './MissingDataOverlay';
import GraphTooltip from './GraphTooltip';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  UncontrolledTooltip,
} from 'reactstrap';

import { GlobalContext } from '../../store/Provider';

const ReportingComponent = () => {
  const { t } = useTranslation();

  const { suppliersState } = useContext(GlobalContext);

  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 2);

  const [supplierHasData, setSupplierHasData] = useState(false);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchNewData, setFetchNewData] = useState(false);
  const [filterData, setFilterData] = useState(false);
  const [filters, setFilters] = useState({
    start: startDate.toISOString().slice(0, 10),
    end: new Date().toISOString().slice(0, 10),
    loadingplaces: [],
    slaughterhouses: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setFetchNewData(false);

      try {
        await axios
          .get(
            `/jsonapi/reporting_get_data?date_from=${filters.start}&date_to=${filters.end}&supplierid=${suppliersState.suppliers.activeID}&supplieruuid=${suppliersState.suppliers.activeUUID}`
          )
          .then(res => {
            setData(res.data);
          });
      } catch (error) {
        setSupplierHasData(false);
        setData([]);
        setChartData({});
        setFilters({
          start: startDate.toISOString().slice(0, 10),
          end: new Date().toISOString().slice(0, 10),
          loadingplaces: [],
          slaughterhouses: [],
        });
      }

      setLoading(false);
    };

    if (fetchNewData) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [fetchNewData]);

  useEffect(() => {
    setFetchNewData(true);
  }, [suppliersState.suppliers.activeID]);

  useEffect(() => {
    if (data.length > 0) {
      setSupplierHasData(true);

      const uniqueLoadingplaces = [...new Set(data.map(item => item.DELPLCE))];

      const uniqueSlaughterhouses = [
        ...new Set(data.map(item => item.DESLANM)),
      ];

      let loadingplaceFilter = [];
      uniqueLoadingplaces.forEach(item => {
        loadingplaceFilter.push({
          key: item,
          active: true,
        });
      });

      let slaughterhouseFilter = [];
      uniqueSlaughterhouses.forEach(item => {
        slaughterhouseFilter.push({
          key: item,
          active: true,
        });
      });

      setFilters({
        ...filters,
        loadingplaces: loadingplaceFilter,
        slaughterhouses: slaughterhouseFilter,
      });

      setFilterData(true);
    } else {
      setChartData({});
      setFilters({
        start: startDate.toISOString().slice(0, 10),
        end: new Date().toISOString().slice(0, 10),
        loadingplaces: [],
        slaughterhouses: [],
      });
      setSupplierHasData(false);
    }
    // eslint-disable-next-line
  }, [data]);


  useEffect(() => {
    if (filterData) {
      setChartData({
        DEAANTA: graphDEAANTA(
          data,
          t('reporting.graphLabels.DEAANTA'),
          filters
        ),
        DETOTBG: graphDETOTBG(
          data,
          t('reporting.graphLabels.DETOTBG'),
          filters
        ),
        // Deze is een combinatie van DEAANTA, DEANTKL en DEANTGR
        DEAANTAKLGR: graphDEAANTAKLGR(
          data,
          t('reporting.graphLabels.DEAANTAKLGR'),
          filters
        ),
        DEGEMNV: graphDEGEMNV(
          data,
          t('reporting.graphLabels.DEGEMNV'),
          filters
        ),
        DEGEMSD: graphDEGEMSD(
          data,
          t('reporting.graphLabels.DEGEMSD'),
          filters
        ),
        DEGEMVD: graphDEGEMVD(
          data,
          t('reporting.graphLabels.DEGEMVD'),
          filters
        ),
        DELTMTO: graphDELTMTO(
          data,
          t('reporting.graphLabels.DELTMTO'),
          filters
        ),
        DEBPGTO: graphDEBPGTO(
          data,
          t('reporting.graphLabels.DEBPGTO'),
          filters
        ),
        DEMABBU: graphDEMABBU(
          data,
          t('reporting.graphLabels.DEMABBU'),
          filters
        ),
        DEGEWBU: graphDEGEWBU(
          data,
          t('reporting.graphLabels.DEGEWBU'),
          filters
        ),
        DESLAHS: graphDESLAHS(
          data,
          t('reporting.graphLabels.DESLAHS'),
          filters
        ),
        DEGEMMB: graphDEGEMMB(
          data,
          t('reporting.graphLabels.DEGEMMB'),
          filters
        ),
      });

      setFilterData(false);
    }
    // eslint-disable-next-line
  }, [filterData]);

  const DEAANTAKLGRRef = useRef();
  useEffect(() => {
    DEAANTAKLGRRef?.current?.chart.toggleSeries(t('reporting.labels.ok'));
  });


  // const baseColors = [
  //   {
  //     id: 'TW',
  //     color: '#ff0000',
  //   },
  //   {
  //     id: 'DAV',
  //     color: '#00ff00',
  //   },
  //   {
  //     id: 'COV',
  //     color: '#0000ff',
  //   },
  // ];

  // const series = [
  //   {
  //     name: 'TW',
  //     data: [30, 40, 35, 60, 45],
  //   },
  //   {
  //     name: 'DAV',
  //     data: [40, 42, 36, 30, 50],
  //   },
  // ];

  // const getColorArray = series => {
  //   let colorArray = [];
  //   series.forEach(serie => {
  //     let obj = baseColors.find(element => element.id === serie.name);
  //     if (obj) {
  //       colorArray.push(obj.color);
  //     } else {
  //       colorArray.push('#000');
  //     }
  //   });
  //   return colorArray;
  // };

  // const testData = {
  //   options: {
  //     colors: getColorArray(series),
  //     chart: {
  //       type: 'bar',
  //     },
  //     xaxis: {
  //       categories: [1, 2, 3, 4, 5],
  //     },
  //   },
  //   series: series,
  // };

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        {/* {supplierHasData === false && <MissingDataOverlay />} */}
        <Row>
          <Col lg='6' sm='12' style={{ height: 'calc(100%-30px)' }}>
            <Card>
              <CardTitle className='border-bottom p-3 mb-0'>
                {t('reporting.filtering.period')}
              </CardTitle>
              <CardBody>
                <Form
                  className='form-material'
                  onSubmit={e => {
                    e.preventDefault();
                    setFetchNewData(true);
                  }}
                >
                  <FormGroup>
                    <Label>{t('reporting.filtering.startDate')}</Label>
                    <Input
                      type='date'
                      name='start'
                      value={filters.start}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          start: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{t('reporting.filtering.endDate')}</Label>
                    <Input
                      type='date'
                      name='end'
                      value={filters.end}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          end: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button type='submit' className='btn-primary'>
                      {t('reporting.filtering.apply')}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col lg='6' sm='12' style={{ height: 'calc(100%-30px)' }}>
            <Card>
              <CardTitle className='border-bottom p-3 mb-0'>
                {t('reporting.filtering.filters')}
              </CardTitle>
              <CardBody>
                <Form
                  className='form-material'
                  onSubmit={e => {
                    e.preventDefault();
                    setFilterData(true);
                  }}
                >
                  <FormGroup>
                    <Label className='d-block'>
                      {t('reporting.filtering.loadingplaces')}:
                    </Label>
                    {filters.loadingplaces.map(item => (
                      <div
                        key={item.key}
                        className='form-check form-check-inline'
                      >
                        <Input
                          className='form-check-input'
                          type='checkbox'
                          id={item.key}
                          value={item.key}
                          checked={item.active}
                          onChange={e => {
                            setFilters({
                              ...filters,
                              loadingplaces: filters.loadingplaces.map(item => {
                                if (item.key === e.target.value) {
                                  return {
                                    key: item.key,
                                    active: !item.active,
                                  };
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                        />
                        <Label className='my-0' for={item.key}>
                          {item.key}
                        </Label>
                      </div>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <Label className='d-block'>
                      {t('reporting.filtering.slaughterHouses')}:
                    </Label>
                    {filters.slaughterhouses.map(item => (
                      <div
                        key={item.key}
                        className='form-check form-check-inline'
                      >
                        <Input
                          className='form-check-input'
                          type='checkbox'
                          id={item.key}
                          value={item.key}
                          checked={item.active}
                          onChange={e => {
                            setFilters({
                              ...filters,
                              slaughterhouses: filters.slaughterhouses.map(
                                item => {
                                  if (item.key === e.target.value) {
                                    return {
                                      key: item.key,
                                      active: !item.active,
                                    };
                                  } else {
                                    return item;
                                  }
                                }
                              ),
                            });
                          }}
                        />
                        <Label className='my-0' for={item.key}>
                          {item.key}
                        </Label>
                      </div>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <Button type='submit' className='btn-primary'>
                      {t('reporting.filtering.apply')}
                    </Button>
                    <Button
                      type='button'
                      className='ml-2 btn-secondary'
                      onClick={() => {
                        setFilters({
                          ...filters,
                          loadingplaces: filters.loadingplaces.map(item => {
                            return { key: item.key, active: true };
                          }),
                          slaughterhouses: filters.slaughterhouses.map(item => {
                            return { key: item.key, active: true };
                          }),
                        });
                        setFilterData(true);
                      }}
                    >
                      {t('reporting.filtering.reset')}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
          <Col md='12'>
            <Chart
              options={testData.options}
              series={testData.series}
              type='bar'
              width='100%'
            />
          </Col>
        </Row> */}

        <Row>
          {chartData.DEAANTA && chartData.DEAANTA.hasSeriesData && (
            // Som aantal varkens
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    <span>{t('reporting.graphLabels.DEAANTA')}</span>
                    <GraphTooltip
                      id='DEAANTA'
                      content={t('reporting.graphLabels.DEAANTA')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DEAANTA.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEAANTA.options}
                      series={chartData.DEAANTA.series}
                      type='bar'
                      // type='scatter'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DETOTBG && chartData.DETOTBG.hasSeriesData && (
            // Totaalbedrag + gemiddelde euro per vark
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DETOTBG')}
                    <GraphTooltip
                      id='DETOTBG'
                      content={t('reporting.graphLabels.DETOTBG')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DETOTBG.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DETOTBG.options}
                      series={chartData.DETOTBG.series}
                      type='line'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DEAANTAKLGR && chartData.DEAANTAKLGR.hasSeriesData && (
            // Aantal varkens buiten gewicht
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEAANTAKLGR')}
                    <GraphTooltip
                      id='DEAANTAKLGR'
                      content={t('reporting.graphLabels.DEAANTAKLGR')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DEAANTAKLGR.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEAANTAKLGR.options}
                      series={chartData.DEAANTAKLGR.series}
                      type='bar'
                      width='100%'
                      ref={DEAANTAKLGRRef}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {/* {chartData.DEGEWBU && (
            // Gewicht buiten gewicht
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6 d-none'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEGEWBU')}
                    <GraphTooltip
                      id='DEGEWBU'
                      content={t('reporting.graphLabels.DEGEWBU')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    <Chart
                      options={chartData.DEGEWBU.options}
                      series={chartData.DEGEWBU.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DEMABBU && (
            // Vergoeding buiten gewicht
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6 d-none'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEMABBU')}
                    <GraphTooltip
                      id='DEMABBU'
                      content={t('reporting.graphLabels.DEMABBU')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    <Chart
                      options={chartData.DEMABBU.options}
                      series={chartData.DEMABBU.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )} */}

          {chartData.DEBPGTO && chartData.DEBPGTO.hasSeriesData && (
            // Toeslag op koud geslacht gewicht (per varken)
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.labels.toeslagOpBasisprijs')}
                    <GraphTooltip
                      id='DEBPGTO'
                      content={t('reporting.labels.toeslagOpBasisprijs')}
                    />
                  </CardTitle>
                  <div className='mixed-chart'>
                    {/* {chartData.DEBPGTO.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEBPGTO.options}
                      series={chartData.DEBPGTO.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DEGEMMB && chartData.DEGEMMB.hasSeriesData && (
            // Gemiddelde AFI/MBI
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEGEMMB')}
                    <GraphTooltip
                      id='DEGEMMB'
                      content={t('reporting.graphLabels.DEGEMMB')}
                    />
                  </CardTitle>
                  <div className='mixed-chart'>
                    {/* {chartData.DEGEMMB.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEGEMMB.options}
                      series={chartData.DEGEMMB.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DEGEMGW && chartData.DEGEMGW.hasSeriesData && (
            // Koud geslacht gemiddeld gewicht
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEGEMNV')}
                    <GraphTooltip
                      id='DEGEMGW'
                      content={t('reporting.graphLabels.DEGEMNV')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DEGEMNV.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEGEMGW.options}
                      series={chartData.DEGEMGW.series}
                      type='bar'
                      // type='scatter'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DEGEMSD && chartData.DEGEMSD.hasSeriesData && (
            // Gemiddelde spekdikte
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEGEMSD')}
                    <GraphTooltip
                      id='DEGEMSD'
                      content={t('reporting.graphLabels.DEGEMSD')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DEGEMSD.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEGEMSD.options}
                      series={chartData.DEGEMSD.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DEGEMVD && chartData.DEGEMVD.hasSeriesData && (
            // Gemiddelde vleesdikte
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DEGEMVD')}
                    <GraphTooltip
                      id='DEGEMVD'
                      content={t('reporting.graphLabels.DEGEMVD')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DEGEMVD.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DEGEMVD.options}
                      series={chartData.DEGEMVD.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DELTMTO && chartData.DELTMTO.hasSeriesData && (
            // Labelmatrixtoeslag BPG
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DELTMTO')}
                    <GraphTooltip
                      id='DELTMTO'
                      content={t('reporting.graphLabels.DELTMTO')}
                    />
                  </CardTitle>
                  <div className='mixed-chart'>
                    {/* {chartData.DELTMTO.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DELTMTO.options}
                      series={chartData.DELTMTO.series}
                      type='bar'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {chartData.DESLAHS && chartData.DESLAHS.hasSeriesData && (
            // Aantal leveringen per slachthuis
            <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
              <Card>
                <CardBody>
                  {loading && <ContainerSpinner />}
                  <CardTitle className='d-flex justify-content-between'>
                    {t('reporting.graphLabels.DESLAHS')}
                    <GraphTooltip
                      id='DESLAHS'
                      content={t('reporting.graphLabels.DESLAHS')}
                    />
                  </CardTitle>

                  <div className='mixed-chart'>
                    {/* {chartData.DESLAHS.hasSeriesData === false && (
                      <MissingDataOverlay />
                    )} */}
                    <Chart
                      options={chartData.DESLAHS.options}
                      series={chartData.DESLAHS.series}
                      type='pie'
                      width='100%'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </Fragment>
  );
};

export default ReportingComponent;
