import React, { Component, Fragment } from 'react';

const questionMarkTip = ({question, message, id}) => {
    var iconClass;
    var tooltipText;
    let msgArr;
    if(question == 'true') {
      if(id === 'AvgPigsPerDelivery') {
        var msg = message.split("#")
        msgArr = msg
      }
      iconClass = 'mdi mdi-help tooltip-icon';
      tooltipText = message;
    } else {
        iconClass = '';
        tooltipText = '';
    }
  return (
    <Fragment>
        <div className='tooltip-full'>
          <i className={iconClass} />
          { id === 'AvgPigsPerDelivery' && msgArr.length > 1 ? 
            <div className='tooltip-content'>
              <ul>
                { msgArr.length > 0 && msgArr.map((ele) => ( <li>{ele}</li>))}
              </ul>
            </div> :  <div className='tooltip-content'>{tooltipText}</div>
          }
        
        </div>

    </Fragment>
  );
};


export default questionMarkTip;
