import React, { Fragment } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Layout
import OfferForm from './OfferForm';
// import OfferEditForm from './OfferEditForm';
import OffersList from './OffersList';
import OffersArchive from './OffersArchive';
import OfferVKIWizard from './OfferVKIWizard';

const OffersComponent = () => {
  const match = useRouteMatch();

  return (
    <Fragment>
      <Switch>
        <Route path={`${match.path}/vki`} component={OfferVKIWizard} />
        <Route path={`${match.path}/archive`} component={OffersArchive} />
        <Route path={`${match.path}/new`} component={OfferForm} />
        {/* <Route path={`${match.path}/edit`} component={OfferEditForm} /> */}
        <Route path={match.path} component={OffersList} />
      </Switch>
    </Fragment>
  );
};

export default OffersComponent;
