import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';

// Context
import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import {
  removeContributor,
  clearCurrentContributor,
  setContributorLoading,
  setCurrentContributor,
} from '../../store/actions/contributors';
import { getStables } from '../../store/actions/stables';

// Translation
import { useTranslation } from 'react-i18next';

const ContributorItem = ({ contributor }) => {
  const {
    suppliersState,
    contributorsDispatch,
    stablesDispatch,
    alertsDispatch,
    authState,
  } = useContext(GlobalContext);

  const { uid, first_name, mobile, email } = contributor;

  const { t } = useTranslation();

  const onDelete = async () => {
    var r = window.confirm(t('contributors.confirmRemovecontributor'));
    if (r === true) {
      setContributorLoading(true)(contributorsDispatch);
      const res = await removeContributor(
        uid,
        suppliersState.suppliers.activeID,
        authState.user.uid
      )(contributorsDispatch);
      await getStables()(stablesDispatch);
      if (res.code === 400) {
        setAlert(res.msg, 'danger')(alertsDispatch);
      } else if (res.code === 504) {
        setAlert(res.msg, 'warning')(alertsDispatch);
      } else {
        setAlert(res.msg, 'success')(alertsDispatch);
      }
      clearCurrentContributor()(contributorsDispatch);
      setContributorLoading(false)(contributorsDispatch);
    }
  };

  let match = useRouteMatch();

  return (
    <tr>
      <td>{decodeStr(first_name)}</td>
      <td>{email}</td>
      <td>{mobile}</td>
      <td>
        <Link
          onClick={() =>
            setCurrentContributor(contributor)(contributorsDispatch)
          }
          to={`${match.path}/edit`}
          className='btn btn-secondary mr-1 btn-sm'
        >
          <i className='icon-pencil'></i> <span>{t('general.edit')}</span>
        </Link>
        <button className='btn btn-secondary btn-sm' onClick={onDelete}>
          <i className='icon-trash'></i> <span>{t('general.delete')}</span>
        </button>
      </td>
    </tr>
  );
};

ContributorItem.propTypes = {
  contributor: PropTypes.object.isRequired,
};

export default ContributorItem;
