import React, { Fragment } from 'react';

import { Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TasteAndWelfare = ({ content }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {content && (
        <Card style={{ height: '100%', marginBottom: 0 }}>
          <CardBody>
            <CardTitle>{t('dashboard.tasteAndWelfare')}</CardTitle>
          </CardBody>
          <CardImg top width='100%' src={content.img} alt={content.title} />
          <CardBody>
            <CardTitle>{content.title}</CardTitle>
            <CardText>{content.body} </CardText>
            <div className='mt-2'>
              <a
                className='btn btn-secondary'
                target='_blank'
                rel='noreferrer'
                href={content.url}
              >
                {t('general.readMore')}
              </a>
            </div>
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default TasteAndWelfare;
