import React, { Fragment } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const MapsCard = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Card style={{ height: '100%', marginBottom: 0 }}>
        <CardBody style={{ flex: 0 }}>
          <CardTitle>{t('dashboard.marketInfoAFS')}</CardTitle>
        </CardBody>
        <CardBody className='p-0'>
          <iframe
            title='Marktinfo AFS'
            src='https://www.google.com/maps/d/embed?mid=1OJTC93Opyyh3SHef4kjx4I3ErrSTfhIB'
            width='100%'
            height='100%'
            style={{ minHeight: '480px' }}
            frameBorder='0'
          ></iframe>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default MapsCard;
