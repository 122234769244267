import axios from 'axios';
import i18next from 'i18next';

export const getContributors = activeSupplier => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {
    const res = await axios.get('/jsonapi/entity/users?_format=json');
    const contributors = res.data;

    let queryString = '';
    contributors.forEach(contributor => {
      queryString += `uids[]=${contributor.uid}&`;
    });
    queryString += `activeSupplier=${activeSupplier}`;

    const res2 = await axios.get(
      `/jsonapi/contributors_permissions?${queryString}`
    );

    const permissions = res2.data;

    contributors.forEach((contributor, index) => {
      const permissionID = permissions.findIndex(
        permissionObj => contributor.uid === permissionObj.uid
      );

      if (permissionID > -1) {
        contributors[index] = {
          ...contributors[index],
          permissions: permissions[permissionID],
        };
      }
    });

    dispatch({
      type: 'GET_CONTRIBUTORS',
      payload: contributors,
    });
    response.code = 200;
  } catch (err) {
    dispatch({
      type: 'SET_FETCHING',
      payload: false,
    });

    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.contributors.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

export const addContributor =
  (contributor, selectedPermissions, activeSupplier, owner_id) =>
  async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    contributor.name = contributor.email;
    contributor.group_id = activeSupplier;
    contributor.status = true;
    contributor.roles = 'Contributor';

    const permissions = {
      contributors: selectedPermissions.includes('contributors') ? '1' : '0',
      veterinarians: selectedPermissions.includes('veterinarians') ? '1' : '0',
      stables: selectedPermissions.includes('stables') ? '1' : '0',
      loadingplaces: selectedPermissions.includes('loadingplaces') ? '1' : '0',
      offers: selectedPermissions.includes('offers') ? '1' : '0',
      documents: selectedPermissions.includes('documents') ? '1' : '0',
      AI: selectedPermissions.includes('AI') ? '1' : '0',
      BO: selectedPermissions.includes('BO') ? '1' : '0',
      CN: selectedPermissions.includes('CN') ? '1' : '0',
      FA: selectedPermissions.includes('FA') ? '1' : '0',
      FL: selectedPermissions.includes('FL') ? '1' : '0',
      IN: selectedPermissions.includes('IN') ? '1' : '0',
      OF: selectedPermissions.includes('OF') ? '1' : '0',
      SL: selectedPermissions.includes('SL') ? '1' : '0',
      reporting: selectedPermissions.includes('reporting') ? '1' : '0',
      benchmarking: selectedPermissions.includes('benchmarking') ? '1' : '0',
    };

    const formData = {
      name: contributor.email,
      status: 1,
      roles: 'contributor',
      mail: contributor.email,
      mobile: contributor.mobile,
      first_name: contributor.first_name,
      group_id: activeSupplier,
      owner_id: owner_id,
      permissions: permissions,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(
        '/jsonapi/contributor?_format=json',
        JSON.stringify(formData),
        config
      );

      if (res.data.contributorAlreadyInGroup) {
        response.code = 406;
        response.msg = i18next.t(
          'alerts.errors.contributors.contributorAlreadyExistsInGroup'
        );
      } else {
        const payloadContributor = {
          name: contributor.name,
          first_name: contributor.first_name,
          uid: res.data.uid,
          uuid: res.data.uuid,
          email: contributor.email,
          mobile: contributor.mobile,
          status: 'On',
          permissions: permissions,
        };

        dispatch({
          type: 'ADD_CONTRIBUTOR',
          payload: payloadContributor,
        });

        response.code = 200;
        response.msg = i18next.t('alerts.success.contributors.added');
      }
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t('alerts.errors.contributors.couldNotAdd');
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const removeContributor =
  (user_id, group_id, owner_id) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    const formData = {
      user_id: user_id,
      group_id: group_id,
      owner_id: owner_id,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.post(
        '/jsonapi/removefromgroup?_format=json',
        JSON.stringify(formData),
        config
      );

      dispatch({
        type: 'REMOVE_CONTRIBUTOR',
        payload: user_id,
      });
      response.code = 200;
      response.msg = i18next.t('alerts.success.contributors.removed');
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t('alerts.errors.contributors.couldNotRemove');
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const updateContributor =
  (contributor, selectedPermissions, activeSupplier) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    const permissions = {
      contributors: selectedPermissions.includes('contributors') ? '1' : '0',
      veterinarians: selectedPermissions.includes('veterinarians') ? '1' : '0',
      stables: selectedPermissions.includes('stables') ? '1' : '0',
      loadingplaces: selectedPermissions.includes('loadingplaces') ? '1' : '0',
      offers: selectedPermissions.includes('offers') ? '1' : '0',
      documents: selectedPermissions.includes('documents') ? '1' : '0',
      AI: selectedPermissions.includes('AI') ? '1' : '0',
      BO: selectedPermissions.includes('BO') ? '1' : '0',
      CN: selectedPermissions.includes('CN') ? '1' : '0',
      FA: selectedPermissions.includes('FA') ? '1' : '0',
      FL: selectedPermissions.includes('FL') ? '1' : '0',
      IN: selectedPermissions.includes('IN') ? '1' : '0',
      OF: selectedPermissions.includes('OF') ? '1' : '0',
      SL: selectedPermissions.includes('SL') ? '1' : '0',
      reporting: selectedPermissions.includes('reporting') ? '1' : '0',
      benchmarking: selectedPermissions.includes('benchmarking') ? '1' : '0',
    };

    const formData = {
      uid: contributor.uid,
      activeSupplier: activeSupplier,
      ...permissions,
    };

    const payload = {
      ...contributor,
      permissions: permissions,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.post(
        '/jsonapi/contributor_set_permissions?_format=json',
        JSON.stringify(formData),
        config
      );

      dispatch({
        type: 'UPDATE_CONTRIBUTOR',
        payload: payload,
      });
      response.code = 200;
      response.msg = i18next.t('alerts.success.savedChanges');
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t('alerts.errors.contributors.couldNotUpdate');
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const setCurrentContributor = contributor => dispatch => {
  dispatch({
    type: 'SET_CURRENT_CONTRIBUTOR',
    payload: contributor,
  });
};

export const clearCurrentContributor = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_CONTRIBUTOR' });
};

export const setContributorLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const setContributorFetching = value => dispatch => {
  dispatch({
    type: 'SET_FETCHING',
    payload: value,
  });
};

export const clearContributorState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};
