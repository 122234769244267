import React, { Fragment, useState } from 'react';
// import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { InputGroup, Input, Form, Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Language from '../components/layout/Language';

import img1 from '../assets/images/logo.svg';
import img2 from '../assets/images/bg-pig.jpg';

import Alerts from '../components/layout/Alerts';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

const Register = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = {
      name: e.target[0].value,
      firstname: e.target[1].value,
      company: e.target[2].value,
      email: e.target[3].value,
      phone: e.target[4].value,
      // address: e.target[5].value,
      // zip: e.target[6].value,
      // city: e.target[7].value,
      // country: e.target[8].value,
    };

    console.log(data);

    const res = await axios.post('/bpgregister?_format=json', data, config);

    console.log(res.data);

    setLoading(false);

    // TODO: Redirect
  };

  return (
    <Fragment>
      <div
        className='auth-wrapper  align-items-center d-flex'
        style={sidebarBackground}
      >
        <div className='auth-nav'>
          <ul className='ml-auto navbar-nav'>
            {/* <li>
              <Link className='nav-link mr-3' to='/about'>
                {t('general.aboutPP')}
              </Link>
            </li> */}
            <Language />
          </ul>
        </div>
        <div className='container'>
          <div>
            <Row className='no-gutters justify-content-center'>
              <Col
                md='6'
                lg='4'
                className='bg-dark text-white d-flex align-items-center justify-content-center'
              >
                <div className='text-center p-4'>
                  <img src={img1} width='50%' alt={t('general.appName')} />
                  <h3 className='font-medium mt-3 text-white'>
                    {t('general.appName')}
                  </h3>
                </div>
              </Col>
              <Col md='6' lg='4' className='bg-white'>
                <div className='p-4'>
                  <Form id='registerform' onSubmit={onSubmit}>
                    <InputGroup className='mb-2' size='lg'>
                      <Input
                        disabled={loading}
                        name='name'
                        type='text'
                        placeholder={t('general.name')}
                        required
                      />
                    </InputGroup>

                    <InputGroup className='mb-2' size='lg'>
                      <Input
                        disabled={loading}
                        name='firstname'
                        type='text'
                        placeholder={t('general.firstname')}
                        required
                      />
                    </InputGroup>

                    <InputGroup className='mb-2' size='lg'>
                      <Input
                        disabled={loading}
                        name='company'
                        type='text'
                        placeholder={t('general.company')}
                        required
                      />
                    </InputGroup>

                    <InputGroup className='mb-2' size='lg'>
                      <Input
                        disabled={loading}
                        name='email'
                        type='email'
                        placeholder={t('general.email')}
                        required
                      />
                    </InputGroup>

                    <InputGroup className='mb-2' size='lg'>
                      <Input
                        disabled={loading}
                        name='phone'
                        type='text'
                        placeholder={`${t('general.phone')} (+32123456789)`}
                        required
                      />
                    </InputGroup>

                    <Row className='mb-3'>
                      <Col xs='12'>
                        <Button
                          disabled={loading}
                          color='primary'
                          size='lg'
                          type='submit'
                          block
                        >
                          {!loading
                            ? t('login.signUp')
                            : `${t('general.loading')}...`}
                        </Button>
                      </Col>
                    </Row>
                    <div className='text-center'>
                      {t('login.iHaveAnAccount')}{' '}
                      <Link to='/' className='text-info ml-1'>
                        <b>{t('login.signIn')}</b>
                      </Link>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-nav'>
          &copy; 2021 - <Link to='/legal-info'>Legal info</Link>
        </div>
      </div>
      <div className='alert-region'>
        <Alerts />
      </div>
    </Fragment>
  );
};

export default Register;
