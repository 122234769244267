import React, { useEffect, useState, useContext } from 'react';
import StepZilla from 'react-stepzilla';
import { Card, CardBody } from 'reactstrap';
import { formatVKIDate } from '../../../utils/Utils';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

// Translation
import { useTranslation } from 'react-i18next';

// Context
import { GlobalContext } from '../../../store/Provider';
import {
  clearCurrentOffer,
  clearCurrentOfferLine,
  clearCurrentVKI,
} from '../../../store/actions/offers';

const Formwizard = () => {
  const {
    veterinariansState,
    loadingplacesState,
    offersState,
    offersDispatch,
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const loadingplace = loadingplacesState.loadingplaces.list.filter(
    lp => lp.id === offersState.offers.current.field_loading_place
  )[0];

  const vet = veterinariansState.veterinarians.list.filter(
    vet => vet.id === loadingplace.default_veterinarian
  )[0];

  useEffect(() => {
    if (offersState.offers.currentVKI) {
      setVKI(offersState.offers.currentVKI);
    }

    return () => {
      clearCurrentOffer()(offersDispatch);
      clearCurrentOfferLine()(offersDispatch);
      clearCurrentVKI()(offersDispatch);
    };
  }, [offersDispatch, offersState.offers.currentVKI]);

  const getMostRecentSalmonella = () => {
    if (loadingplace.researches.length > 0) {
      return loadingplace.researches.find(
        research => research.field_research_type.toLowerCase() === 'salmonella'
      );
    } else {
      return undefined;
    }
  };

  const getMostRecentResearch = () => {
    let research = undefined;
    loadingplace.researches.forEach(item => {
      if (
        research === undefined &&
        item.field_research_type.toLowerCase() !== 'salmonella'
      ) {
        research = item;
      }
    });

    return research;
  };

  const getMostRecentSalmonellaDate = () => {
    const salmonella = getMostRecentSalmonella();
    return salmonella !== undefined ? salmonella.field_research_date : '';
  };

  const getMostRecentSalmonellaResult = () => {
    const salmonella = getMostRecentSalmonella();
    return salmonella !== undefined ? salmonella.field_research_result : '';
  };

  const getMostRecentResearchType = () => {
    const research = getMostRecentResearch();
    return research !== undefined ? research.field_research_type : '';
  };

  const getMostRecentResearchDate = () => {
    const research = getMostRecentResearch();
    return research !== undefined ? research.field_research_date : '';
  };

  const getMostRecentResearchResult = () => {
    const research = getMostRecentResearch();
    return research !== undefined ? research.field_research_result : '';
  };

  const [vki, setVKI] = useState({
    codeFittings: loadingplace.degrsan,
    nameProducer: loadingplace.deeflpn,
    addressProducer: loadingplace.address_line1,
    zipProducer: loadingplace.postcode,
    cityProducer: loadingplace.locality,
    countryProducer: loadingplace.country_code,
    emailProducer: loadingplace.deemail,
    phoneProducer: loadingplace.detlfnr,
    selectedVeterinarian: loadingplace.default_veterinarian,
    nameVeterinarian: vet.title,
    addressVeterinarian: vet.address_line1,
    zipVeterinarian: vet.postcode,
    cityVeterinarian: vet.locality,
    countryVeterinarian: vet.country_code,
    emailVeterinarian: vet.email,
    phoneVeterinarian: vet.telephone,
    amountOfPigs: Number(offersState.offers.currentOfferLine.numpigs),
    // setupDate: formatVKIDate(new Date()),
    setupDate: '',
    serialNumber: loadingplace.lp_code,
    // departureDate: formatVKIDate(new Date()),
    departureDate: '',
    typeOfPigs: '0',
    // outdoors: '2',
    outdoors: '',
    controlledHousing: '0',
    // controlledHousing: '1',
    antiparasiticDrugs: '',
    antibiotics: '',
    antiInflamatoryDrugs: '',
    nameMedication1: '',
    dateFromMedication1: '',
    dateUntilMedication1: '',
    durationMedication1: '',
    nameMedication2: '',
    dateFromMedication2: '',
    dateUntilMedication2: '',
    durationMedication2: '',
    nameMedication3: '',
    dateFromMedication3: '',
    dateUntilMedication3: '',
    durationMedication3: '',
    deaths: '0',
    // deaths: '2',
    causeOfDeaths: '',
    sickness: '0',
    // sickness: '2',
    causeOfSickness: '',
    investigated: loadingplace.field_research_carried_out.toString(),
    investigationDate: getMostRecentSalmonellaDate(),
    investigationResult: getMostRecentSalmonellaResult(),
    typeOfCompany: loadingplace.field_type_of_company.toString(),
    optionalInvestigation: getMostRecentResearchType(),
    optionalInvestigationDate: getMostRecentResearchDate(),
    optionalInvestigationResult: getMostRecentResearchResult(),
    listOfCountries: loadingplace.field_default_countries
      ? loadingplace.field_default_countries
      : '',
    signatureProducer: '',
    signatureDate: formatVKIDate(new Date()),
    finished: false,
  });

  const steps = [
    {
      name: t('vki.steps.contacts'),
      component: <Step1 vki={vki} setVKI={setVKI} />,
    },

    {
      name: t('vki.steps.pigs'),
      component: <Step2 vki={vki} setVKI={setVKI} />,
    },

    {
      name: t('vki.steps.medical'),
      component: <Step3 vki={vki} setVKI={setVKI} />,
    },

    {
      name: t('vki.steps.foodSafety'),
      component: <Step4 vki={vki} setVKI={setVKI} />,
    },

    {
      name: t('vki.steps.confirmation'),
      component: <Step5 vki={vki} setVKI={setVKI} />,
    },

    {
      name: t('vki.steps.submission'),
      component: <Step6 vki={vki} setVKI={setVKI} />,
    },
  ];

  return (
    <Card className='mt-4'>
      <CardBody>
        <div className='step-progress'>
          <StepZilla
            steps={steps}
            preventEnterSubmission={true}
            showNavigation={false}
            stepsNavigation={false}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Formwizard;
