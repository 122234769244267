import React, { Fragment, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { DEGEMAT_ChartData, DEGEMGW_ChartData, DEGEMMB_ChartData, DEGEMNV_ChartData, DEGEMSD_ChartData, DEGEMVD_ChartData,  } from '../../utils/BenchmarkingUtils';
import GraphTooltip from '../reporting/GraphTooltip';
import {Card, CardBody,Row,Col,FormGroup,Form,Label,Input,Button,CardTitle } from 'reactstrap';
import { GlobalContext } from '../../store/Provider';
import PageSpinner from '../layout/PageSpinner';
import ChartSchema from './ChartSchema';

// eslint-disable-next-line
const BenchmarkingComponent = () => {
  const { t } = useTranslation();
  const { suppliersState } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [benchmarkData, setBenchmarkData] = useState(null);
  const [drlvbmkData, setDrlvbmkData] = useState([])
  const [drgmbmkData, setDrgmbmkData] = useState([])

  //---------------------------------- ALL GRAPH STATE-------------------------------
  const [degematChart, setDegematChart] = useState([])
  const [degemmbChart, setDegemmbChart] = useState([])
  const [degemnvChart, setDegemnvChart] = useState([])
  const [degemsdChart, setDegemsdChart] = useState([])
  const [degemvdChart, setDegemvdChart] = useState([])
  const [degemgwChart, setDegemgwChart] = useState([])
  const [deaanta, setDeaanta] =  useState(false)



  //date
  const startDate = new Date();
  var currentDate = new Date()
  var currentYear = new Date(currentDate.getFullYear(), 0, 1);
  var days = Math.floor((currentDate - currentYear) / (24 * 60 * 60 * 1000));
  var currentWeek = Math.ceil(( currentDate.getDay() + 1 + days) / 7) - 1;
  var weeksAgo = currentWeek - 15
  var neededYear = new Date().getFullYear()
  if(weeksAgo < 1) {
    if(!weeksAgo == 0) {
      weeksAgo = 1 + weeksAgo;
      weeksAgo = 53 + weeksAgo;
      var neededYear = neededYear - 1
    } else {
      weeksAgo = 1
      var neededYear = neededYear
    }
  }

  startDate.setMonth(startDate.getMonth() -2);
  const [retrieveLoadingPlaces, setLoadingPlaces] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState();
  const [filters, setFilters] = useState({
    fromWeek: weeksAgo,
    fromYear: neededYear, 
    toWeek: currentWeek,
    toYear: new Date().getFullYear(),
    loadingplaces: [],
});



  //------------------- API CALL TO FETCH DATA -------------------
  const fetchBenchmarkData = async () => {
    setLoading(true)
    try  {
      await axios.get(`/jsonapi/benchmarking_get_data?supplierid=${suppliersState.suppliers.activeID}&from_week=${filters.fromWeek}&from_year=${filters.fromYear}&to_week=${filters.toWeek}&to_year=${filters.toYear}`)
        .then(async(res) => {
          if(res.data.length > 0) {
            let drgmbmk = []
            drgmbmk= res.data.filter(item => item.type === "drgmbmk")
            setDrgmbmkData(drgmbmk)

            let drlvbmk = []
            drlvbmk = res.data.filter(item => item.type === 'drlvbmk')
            setDrlvbmkData(drlvbmk);

            const loadingPlaces = [];
            res.data.map(item => {
              if(item.type === "drlvbmk") {
                 loadingPlaces.push(item.DELPLCE)
              }
            });
  
            const uniqueLoadingplaces = [];
            loadingPlaces.map((item) => {
              var findItem = uniqueLoadingplaces.find((x) => x === item);
              if (!findItem) uniqueLoadingplaces.push(item);
            });
  
            let loadingplaceFilter = []
            uniqueLoadingplaces.forEach((item, i) => {
              if(i === 0) {
                loadingplaceFilter.unshift({
                  key: item,
                  active: true,
                })
              } else  {
                loadingplaceFilter.push({
                  key: item,
                  active: false,
                })
              }
            })
            
            setFilters({ ...filters, loadingplaces: loadingplaceFilter });
            
            setBenchmarkData(res.data);
            setLoading(false)
          } else { 
            setLoading(false)
            setDrlvbmkData([]);
          }
        });
      } catch(error) {
        setLoading(false)
      }
  };

  useEffect(() => {
    fetchBenchmarkData();
  }, [suppliersState.suppliers.activeID]);


  useEffect(() => {
    // setLoading(true)
    const noLoadingPlaces = filters.loadingplaces.every(loadingplace => loadingplace.active === false)
    if(noLoadingPlaces === false) {
      const selected = filters.loadingplaces.filter(item => item.active === true)
      setLoadingFilter(selected)
    } 
    setLoadingPlaces(false);
    // setLoading(false)
  }, [retrieveLoadingPlaces, benchmarkData])

  useEffect(() => {
    if(loadingFilter) {
      // setLoading(true)
      let drlvbmk = []

      drlvbmk = benchmarkData.filter(item => item.type === 'drlvbmk' && item.DELPLCE === loadingFilter[0].key)
      console.log('dlrvbmk => ', drlvbmk[0])
      console.log('One =>', loadingFilter[0].key)
      console.log('Two => ', drlvbmk[0]?.DEELTCE)
      console.log('Three => ', drlvbmk[0]?.DEEXTLB)
      console.log('firstLabel => ', drlvbmk[0]?.DELTMCE)
      const DEGEMAT = DEGEMAT_ChartData(drgmbmkData, drlvbmk, {labelOne: loadingFilter[0].key, labelTwo: drlvbmk[0]?.DEELTCE,labelThree: drlvbmk[0]?.DEEXTLB, labelFour: 'ALL', firstLabel: drlvbmk[0]?.DELTMCE} );
      const DEGEMMB = DEGEMMB_ChartData(drgmbmkData, drlvbmk, {labelOne: loadingFilter[0].key, labelTwo: drlvbmk[0]?.DEELTCE,labelThree: drlvbmk[0]?.DEEXTLB, labelFour: 'ALL', firstLabel: drlvbmk[0]?.DELTMCE} );
      const DEGEMNV = DEGEMNV_ChartData(drgmbmkData, drlvbmk, {labelOne: loadingFilter[0].key, labelTwo: drlvbmk[0]?.DEELTCE,labelThree: drlvbmk[0]?.DEEXTLB, labelFour: 'ALL', firstLabel: drlvbmk[0]?.DELTMCE} );
      const DEGEMSD = DEGEMSD_ChartData(drgmbmkData, drlvbmk, {labelOne: loadingFilter[0].key, labelTwo: drlvbmk[0]?.DEELTCE,labelThree: drlvbmk[0]?.DEEXTLB, labelFour: 'ALL', firstLabel: drlvbmk[0]?.DELTMCE} );
      const DEGEMVD = DEGEMVD_ChartData(drgmbmkData, drlvbmk, {labelOne: loadingFilter[0].key, labelTwo: drlvbmk[0]?.DEELTCE,labelThree: drlvbmk[0]?.DEEXTLB, labelFour: 'ALL', firstLabel: drlvbmk[0]?.DELTMCE} );
      const DEGEMGW = DEGEMGW_ChartData(drgmbmkData, drlvbmk, {labelOne: loadingFilter[0].key, labelTwo: drlvbmk[0]?.DEELTCE,labelThree: drlvbmk[0]?.DEEXTLB, labelFour: 'ALL', firstLabel: drlvbmk[0]?.DELTMCE} );
      DEGEMMB?.data.map((ele) => {
        var hasKey = (ele[loadingFilter[0].key] !== undefined);
        if(hasKey) {
          setDeaanta(true)
        }
      })
    
      setDegematChart(DEGEMMB);
      setDegemmbChart(DEGEMMB);
      setDegemnvChart(DEGEMNV);
      setDegemsdChart(DEGEMSD);
      setDegemvdChart(DEGEMVD);
      setDegemgwChart(DEGEMGW);



      setTimeout(() => {
        setLoading(false)
      }, 2000);
      setDrlvbmkData(drlvbmk)
      setLoadingFilter()
    } 
  }, [loadingFilter])

  
  //------------------------ RESET BUTTON FUNCTIONALITY-------------------
  function resetFilter() {
    setLoading(true)
    let tempArr = []
    filters?.loadingplaces.map((ele, idx) => {
     if(idx === 0) {
       tempArr.push({ key: ele.key, active: true})
       setLoadingFilter([{ key: ele.key, active: true}])
     } else {
      tempArr.push({ key: ele.key, active: false})
     }
    })
    setFilters({...filters, loadingplaces: tempArr})
    setLoading(false)
  }
   //------------------------ HANDLE ONCHANGE FILTER-------------------
  function handleLoadingChange(e, item) {
    let tempArr = []
    filters.loadingplaces.map((ele) => {
      if(ele.key === item.key){
        tempArr.push({ key: ele.key, active: true})
      } else {
        tempArr.push({ key: ele.key, active: false})
      }
    })
    setFilters({...filters, loadingplaces: tempArr})
  }
  return (
    <Fragment>
      {loading ?  <PageSpinner msg={t('general.weAreGettingNewData')} /> : ''}
      <div style={{ position: 'relative' }}>
        {/* Filters */}
        <Row>
          <Col lg='6' sm='12' style={{ height:'calc(100%-30px)' }}>
            <Card>
              <CardTitle className='border-bottom p-3 mb-0'>
                {t('benchmarking.filtering.period')}
              </CardTitle>
              <CardBody>
                <Form className='form-material' onSubmit={e => {
                  e.preventDefault(); 
                  fetchBenchmarkData()
                  }}>
                  <FormGroup>
                    <Label className='text-primary benchmarking-filter-title'>{t('benchmarking.filtering.from')}</Label>
                    <div className='benchmarking-filter-group'>
                      <div className='filter-group-1'>
                        <Label>{t('benchmarking.filtering.week')}</Label>
                        <Input 
                          required={true}
                          type='number' 
                          min={0} 
                          max={53} 
                          name='fromWeek' 
                          defaultValue={filters.fromWeek} 
                          onChange={e => {
                            setFilters({
                              ...filters,
                              fromWeek: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className='filter-group-2'>
                        <Label>{t('benchmarking.filtering.year')}</Label>
                        <Input type='number' name='fromYear' defaultValue={filters.fromYear} onChange={e => {
                          setFilters({
                            ...filters,
                            fromYear: e.target.value,
                          });
                        }} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className='text-primary benchmarking-filter-title'>{t('benchmarking.filtering.to')}</Label>
                    <div className='benchmarking-filter-group'>
                      <div className='filter-group-1'>
                        <Label>{t('benchmarking.filtering.week')}</Label>
                        <Input type='number' min={0} max={53} name='toWeek' defaultValue={filters.toWeek} onChange={e => {
                          setFilters({
                            ...filters,
                            toWeek: e.target.value,
                          });
                        }} />
                      </div>
                      <div className='filter-group-2'>
                        <Label>{t('benchmarking.filtering.year')}</Label>
                        <Input type='number' name='toYear' defaultValue={filters.toYear} onChange={e => {
                          setFilters({
                            ...filters,
                            toYear: e.target.value,
                          });
                        }} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Button type='submit' className='btn-primary'>
                      {t('benchmarking.filtering.apply')}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col lg='6' sm='12' style={{ height: 'calc(100%-30px)' }}>
            <Card>
              <CardTitle className='border-bottom p-3 mb-0'>
                {t('benchmarking.filtering.filters')}
              </CardTitle>
              <CardBody>
                <Form className='form-material' onSubmit={e => {
                  e.preventDefault();
                  setLoadingPlaces(true)
                  setLoading(true);
                }}>
                  <FormGroup>
                    <Label className='d-block'>
                      {t('benchmarking.filtering.loadingplaces')}
                    </Label>
                    {filters.loadingplaces.map(item => (
                      <div key={item.key} className='form-check form-check-inline'>
                        <Input
                          className='form-check-input'
                          type='radio'
                          id={item.key}
                          value={item.key}
                          checked={item.active}
                          onChange={(e) => handleLoadingChange(e, item)}
                        />
                        <Label className='my-0' for={item.key}>
                          {item.key}
                        </Label>
                      </div>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <Button type='submit' className='btn-primary'>
                      {t('benchmarking.filtering.apply')}
                    </Button>
                    <Button
                      type='button'
                      className='ml-2 btn-secondary'
                      onClick={resetFilter}
                    >
                      {t('benchmarking.filtering.reset')}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* end filters */}
        { !loading && drlvbmkData.length > 0 &&
          <div>
            <Row>
              {/* Avg Pigs per delivery */}
              <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
                <Card>
                  <CardBody>
                    <CardTitle className='d-flex justify-content-between'>
                      <span>
                        {t('benchmarking.graphLabels.AvgMbiAfi')}
                      </span>
                      { degemmbChart.data &&
                        <GraphTooltip
                          key={degemmbChart?.data.length}
                          question= 'true'
                          message = {`${t('benchmarking.pigGraphInfo')} ${t('benchmarking.pigGraphInfoTwo')}`}
                          id='AvgPigsPerDelivery'
                          content={t('benchmarking.graphLabels.AvgPigsPerDelivery')}
                        />
                      }
                    </CardTitle>
                    <ChartSchema key={loadingFilter} filter={filters} data={degemmbChart} id="degemmbChart" deaanta={deaanta}/>
                  </CardBody>
                </Card>
              </Col>
              {/* Avg AFI per delivery */}
              {/* <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
                <Card>
                  <CardBody>
                    <CardTitle className='d-flex justify-content-between'>
                      <span>{t('benchmarking.graphLabels.AvgAFIPerDelivery')}</span>
                      <GraphTooltip
                        question= 'true'
                        message = {t('benchmarking.avgAFIGraphInfo')}
                        id='AvgAFIPerDelivery'
                        content={t('benchmarking.graphLabels.AvgAFIPerDelivery')}
                      />
                    </CardTitle>
                    <ChartSchema filter={filters} data={degemmbChart} id="degemmbChart"  />
                  </CardBody>
                </Card>
              </Col> */}
              {/* Avg Meatpercentage */}
              <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
                <Card>
                  <CardBody>
                    <CardTitle className='d-flex justify-content-between'>
                      <span>{t('benchmarking.graphLabels.AvgMeatPercentage')}</span>
                      <GraphTooltip
                        question= 'true'
                        message = {t('benchmarking.meatPerGraphInfo')}
                        id='AvgMeatPercentage'
                        content={t('benchmarking.graphLabels.AvgMeatPercentage')}
                      />
                    </CardTitle>
                    <ChartSchema key={loadingFilter} filter={filters} data={degemnvChart} id="degemnvChart" deaanta={deaanta}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
            <Row>


              {/* Avg Bacon Thickness */}
              <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
                <Card>
                  <CardBody>
                    <CardTitle className='d-flex justify-content-between'>
                      <span>{t('benchmarking.graphLabels.AvgBaconThickness')}</span>
                      <GraphTooltip
                        question= 'true'
                        message = {t('benchmarking.baconGraphInfo')}
                        id='AvgBaconThickness'
                        content={t('benchmarking.graphLabels.AvgBaconThickness')}
                      />
                    </CardTitle>
                    <ChartSchema key={loadingFilter} filter={filters} data={degemsdChart} id="degemsdChart" deaanta={deaanta} />
                  </CardBody>
                </Card>
              </Col>

              {/* Avg Meat Thickness */}
              <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
                <Card>
                  <CardBody>
                    <CardTitle className='d-flex justify-content-between'>
                      <span>{t('benchmarking.graphLabels.AvgMeatThickness')}</span>
                      <GraphTooltip
                        question= 'true'
                        message = {t('benchmarking.meatThickGraphInfo')}
                        id='AvgMeatThickness'
                        content={t('benchmarking.graphLabels.AvgMeatThickness')}
                      />
                    </CardTitle>
                    <ChartSchema key={loadingFilter} filter={filters} data={degemvdChart} id="degemvdChart" deaanta={deaanta} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              

              {/* Avg Weight */}
              <Col sm='12' md='12' lg='12' xl='6' className='col-xlg-6'>
                <Card>
                  <CardBody>
                    <CardTitle className='d-flex justify-content-between'>
                      <span>{t('benchmarking.graphLabels.AvgWeight')}</span>
                      <GraphTooltip
                        question= 'true'
                        message = {t('benchmarking.weightGraphInfo')}
                        id='AvgWeight'
                        content={t('benchmarking.graphLabels.AvgWeight')}
                      />
                    </CardTitle>
                    <ChartSchema key={loadingFilter} filter={filters} data={degemgwChart} id="degemgwChart" deaanta={deaanta} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        }

      </div>
    </Fragment>
  );
};

export default BenchmarkingComponent;
