import React, { Fragment } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Componenets
import StablesList from './StablesList';
import StableDetail from './StableDetail';

const StablesComponent = () => {
  const match = useRouteMatch();

  return (
    <Fragment>
      <Switch>
        <Route path={`${match.path}/view`} component={StableDetail} />
        <Route path={match.path} component={StablesList} />
      </Switch>
    </Fragment>
  );
};

export default StablesComponent;
