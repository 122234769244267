import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const ContainerSpinner = () => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="container-loader">
        <div role="status" className={`spinner-border text-primary`}>
          <span className="sr-only">{t('general.loading')}...</span>
        </div>
      </div>
    </Fragment>
  )

}

export default ContainerSpinner;
