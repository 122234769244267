export const changeTopbar = () => dispatch => {
  dispatch({
    type: 'CHANGE_TOPBAR',
  });
};

export const changeSidebar = () => dispatch => {
  dispatch({
    type: 'CHANGE_SIDEBAR',
  });
};

export const expandSettings = () => dispatch => {
  dispatch({
    type: 'EXPAND_SETTINGS',
  });
};
