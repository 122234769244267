import React, { Fragment, useContext, useState, useEffect } from 'react';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from 'reactstrap';

import ContainerSpinner from '../layout/ContainerSpinner';

import { GlobalContext } from '../../store/Provider';

import { setAlert } from '../../store/actions/alerts';

// Translation
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const AccountForm = () => {
  const { t, i18n } = useTranslation();
  const { alertsDispatch, authState } = useContext(GlobalContext);

  const [changes, setChanges] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem('language'));
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [languageLoading, setLanguageLoading] = useState(false);

  useEffect(() => {
    setChanges('');
    setLoading(false);
    setPasswordLoading(false);

    // eslint-disable-next-line
  }, []);

  const onChange = e => {
    setChanges(e.target.value);
  };

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = {
        req: changes,
        uid: authState.user_data.uid[0].value,
      };

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await axios.post('/jsonapi/request_change', formData, config);

      setAlert(t('alerts.success.requestSent'), 'info')(alertsDispatch);
    } catch (err) {
      if (navigator.onLine) {
        setAlert(
          t('alerts.errors.account.submitChangeRequestFailed'),
          'danger'
        )(alertsDispatch);
      } else {
        setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
      }
    }

    setLoading(false);
  };

  const requestPasswordChange = async e => {
    e.preventDefault();
    setPasswordLoading(true);
    const current_password = e.target.childNodes[0].childNodes[1].value;
    const new_password = e.target.childNodes[1].childNodes[1].value;
    const confirm_new_password = e.target.childNodes[2].childNodes[1].value;
    
    if (new_password !== confirm_new_password) {
      setAlert(
        t('alerts.warnings.account.passwordMatch'),
        'danger'
      )(alertsDispatch);
    } else if (current_password === new_password) {
      setAlert(
        t('alerts.warnings.account.newPasswordMatchesCurrent'),
        'danger'
      )(alertsDispatch);
    } else {
      try {
        const formData = {
          uid: authState.user_data.uid[0].value,
          current_password: current_password,
          new_password: new_password,
        };

        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        await axios.post('/jsonapi/change_pwd', formData, config);

        setAlert(
          t('alerts.success.account.passwordChanged'),
          'success'
        )(alertsDispatch);
      } catch (err) {
        if (navigator.onLine) {
          setAlert(
            t('alerts.errors.account.failedToChangePassword'),
            'danger'
          )(alertsDispatch);
        } else {
          setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
        }
      }
    }
    setPasswordLoading(false);
  };

  const requestLanguageChange = async e => {
    e.preventDefault();

    setLanguageLoading(true);

    try {
      const formData = {
        uid: authState.user_data.uid[0].value,
        langcode: language,
      };

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await axios.post('/jsonapi/edit_user_info', formData, config);

      localStorage.setItem('language', language);
      i18n.changeLanguage(language);
      setAlert(
        t('alerts.success.account.updatedLanguage'),
        'success'
      )(alertsDispatch);
    } catch (error) {
      if (navigator.onLine) {
        setAlert(
          t('alerts.errors.account.failedToChangeLanguage'),
          'danger'
        )(alertsDispatch);
      } else {
        setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
      }
    }

    setLanguageLoading(false);
  };

  const onLanguageChange = e => {
    setLanguage(e.target.value);
  };

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col className='col-md-12 col-lg-6 col-xl-5 col-xlg-4'>
          <Card>
            {loading ? <ContainerSpinner /> : ''}
            <CardBody>
              <div>
                <div>
                  <strong>{t('general.name')}:</strong>{' '}
                  {authState.user_data.mail &&
                  typeof authState.user_data.field_first_name[0] !== 'undefined'
                    ? decodeStr(authState.user_data.field_first_name[0].value)
                    : ''}
                </div>
                <div>
                  <strong>{t('general.email')}:</strong>{' '}
                  {authState.user_data.mail &&
                    authState.user_data.mail[0].value}
                </div>
                <div>
                  <strong>{t('general.phone')}:</strong>{' '}
                  {authState.user_data.field_mobilenumber &&
                    authState.user_data.field_mobilenumber[0].value}
                </div>
              </div>
              <div className='mt-4'>
                <p>
                  <strong>{t('account.requestModifications')}</strong>
                </p>
              </div>
              <Form className='mt-3' onSubmit={onSubmit}>
                <FormGroup>
                  <Input
                    type='textarea'
                    value={changes}
                    onChange={onChange}
                    required={true}
                  ></Input>
                </FormGroup>
                <FormGroup className='text-right'>
                  <Button className='btn-primary'>{t('general.submit')}</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Card className='mt-3'>
            {languageLoading ? <ContainerSpinner /> : ''}
            <CardBody>
              <CardTitle>{t('account.changeStandardLanguage')}</CardTitle>
              <Form className='mt-3' onSubmit={requestLanguageChange}>
                <FormGroup>
                  <Input
                    type='select'
                    onChange={onLanguageChange}
                    value={language}
                    disabled={languageLoading}
                  >
                    <option value='nl'>{t('languages.dutch')}</option>
                    <option value='fr'>{t('languages.french')}</option>
                  </Input>
                </FormGroup>
                <FormGroup className='text-right'>
                  <Button className='btn-primary'>{t('general.save')}</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Card className='mt-3'>
            {passwordLoading ? <ContainerSpinner /> : ''}
            <CardBody>
              <CardTitle>{t('account.changePassword')}</CardTitle>
              <Form className='mt-3' onSubmit={requestPasswordChange}>
                <FormGroup>
                  <Label>{t('account.currentPassword')}</Label>
                  <Input
                    type='password'
                    name='current-password'
                    required={true}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('login.newPassword')}</Label>
                  <Input type='password' name='new-password' required={true} />
                </FormGroup>
                <FormGroup>
                  <Label>{t('login.confirmNewPassword')}</Label>
                  <Input
                    type='password'
                    name='confirm-new-password'
                    required={true}
                  />
                </FormGroup>
                <FormGroup className='text-right'>
                  <Button className='btn-primary'>{t('general.save')}</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AccountForm;
