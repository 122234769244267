import React, { Fragment, useContext } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// Layout
import AccountForm from './AccountForm';
import AccountNativeForm from './AccountNativeForm';

import { GlobalContext } from '../../store/Provider';

const AccountComponent = () => {
  const { authState } = useContext(GlobalContext);
  const match = useRouteMatch();

  const is_axi = authState.user_data.field_is_axi_user[0].value;

  return (
    <Fragment>
      <Switch>
        {is_axi ? (
          <Route path={match.path} component={AccountForm} />
        ) : (
          <Route path={match.path} component={AccountNativeForm} />
        )}
      </Switch>
    </Fragment>
  );
};

export default AccountComponent;
