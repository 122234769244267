import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import checkPermission from '../../utils/checkPermission';
import { GlobalContext } from '../../store/Provider';
import { expandSettings } from '../../store/actions/layout';

const Sidebar = () => {
  const [sidebarMargin, setSidebarMargin] = useState('0px');
  const { suppliersState, authState, layoutState, layoutDispatch } =
    useContext(GlobalContext);

  const { t } = useTranslation();
  const location = useLocation();

  const ifOwner = () => {
    return authState.user.roles && authState.user.roles.includes('owner');
  };

  const onClick = () => {
    expandSettings()(layoutDispatch);
  };

  const activeRoute = route => {
    let res = '';

    if (Array.isArray(route)) {
      route.forEach(r => {
        if (location.pathname.indexOf(r) > -1) {
          res = 'selected';
        }
      });
    } else {
      if (location.pathname.indexOf(route) > -1) {
        res = 'selected';
      }
    }

    return res;
  };

  useEffect(() => {
    if (layoutState.topbarOpen && window.innerWidth < 768) {
      setSidebarMargin('72px');
    } else {
      setSidebarMargin('0px');
    }
  }, [layoutState.topbarOpen]);

  // const getSidebarStyle = () => {
  //   if (topbarOpen && window.innerWidth < 768) {
  //     return `
  //       margin-top: '72px'
  //     `;
  //   }
  // };

  return (
    <Fragment>
      <aside className='left-sidebar' id='sidebarbg' data-sidebarbg='skin6'>
        <div className='scroll-sidebar'>
          <PerfectScrollbar className='sidebar-nav'>
            <Nav id='sidebarnav' style={{ marginTop: sidebarMargin }}>
              <li className={`sidebar-item ${activeRoute('dashboard')}`}>
                <Link className='sidebar-link nav-link' to='/dashboard'>
                  <i className='mdi mdi-chart-bar' />
                  <span className='hide-menu'>{t('dashboard.name')}</span>
                </Link>
              </li>

              {suppliersState.suppliers.list !== null &&
                suppliersState.suppliers.list.length > 0 && (
                  <li
                    className={`sidebar-item ${activeRoute([
                      'contributors',
                      'stables',
                      'loadingplaces',
                      'veterinarians',
                    ])}`}
                  >
                    <span
                      data-toggle='collapse'
                      className='sidebar-link has-arrow'
                      aria-expanded={layoutState.settingsExpanded}
                      onClick={onClick}
                    >
                      <i className='mdi mdi-wrench' />
                      <span className='hide-menu'>
                        {t('general.generalSettings')}
                      </span>
                    </span>

                    <Collapse isOpen={layoutState.settingsExpanded}>
                      <ul className='first-level'>
                        {(ifOwner() ||
                          checkPermission(
                            authState.permissions,
                            'contributors'
                          )) && (
                          <li
                            className={`sidebar-item ${activeRoute(
                              'contributors'
                            )}`}
                          >
                            <Link
                              className='sidebar-link nav-link'
                              to='/contributors'
                            >
                              <i className='mdi mdi-account-multiple' />
                              <span className='hide-menu'>
                                {t('contributors.name')}
                              </span>
                            </Link>
                          </li>
                        )}
                        {(ifOwner() ||
                          checkPermission(
                            authState.permissions,
                            'veterinarians'
                          )) && (
                          <li
                            className={`sidebar-item ${activeRoute(
                              'veterinarians'
                            )}`}
                          >
                            <Link
                              className='sidebar-link nav-link'
                              to='/veterinarians'
                            >
                              <i className='mdi mdi-medical-bag' />
                              <span className='hide-menu'>
                                {t('veterinarians.name')}
                              </span>
                            </Link>
                          </li>
                        )}

                        {(ifOwner() ||
                          checkPermission(
                            authState.permissions,
                            'loadingplaces'
                          )) && (
                          <li
                            className={`sidebar-item ${activeRoute(
                              'loadingplaces'
                            )}`}
                          >
                            <Link
                              className='sidebar-link nav-link'
                              to='/loadingplaces'
                            >
                              <i className='mdi mdi-truck-trailer' />
                              <span className='hide-menu'>
                                {t('loadingplaces.name')}
                              </span>
                            </Link>
                          </li>
                        )}
                        {(ifOwner() ||
                          checkPermission(
                            authState.permissions,
                            'stables'
                          )) && (
                          <li
                            className={`sidebar-item ${activeRoute('stables')}`}
                          >
                            <Link
                              className='sidebar-link nav-link'
                              to='/stables'
                            >
                              <i className='mdi mdi-pig' />
                              <span className='hide-menu'>
                                {t('stables.name')}
                              </span>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </li>
                )}

              {suppliersState.suppliers.list !== null &&
                suppliersState.suppliers.list.length > 0 &&
                (ifOwner() ||
                  checkPermission(authState.permissions, 'offers')) && (
                  <li className={`sidebar-item ${activeRoute('offers')}`}>
                    <Link className='sidebar-link nav-link' to='/offers'>
                      <i className='mdi mdi-clipboard-check' />
                      <span className='hide-menu'>{t('offers.name')}</span>
                    </Link>
                  </li>
                )}

              {suppliersState.suppliers.list !== null &&
                suppliersState.suppliers.list.length > 0 &&
                (ifOwner() ||
                  checkPermission(authState.permissions, 'documents')) && (
                  <li className={`sidebar-item ${activeRoute('documents')}`}>
                    <Link className='sidebar-link nav-link' to='/documents'>
                      <i className='mdi mdi-file-multiple' />
                      <span className='hide-menu'>{t('documents.name')}</span>
                    </Link>
                  </li>
                )}

              {(ifOwner() ||
                checkPermission(authState.permissions, 'reporting')) &&
                suppliersState.suppliers.list !== null &&
                suppliersState.suppliers.list.length > 0 && (
                  <li className={`sidebar-item ${activeRoute('reporting')}`}>
                    <Link className='sidebar-link nav-link' to='/reporting'>
                      <i className='mdi mdi-chart-line' />
                      <span className='hide-menu'>{t('reporting.name')}</span>
                    </Link>
                  </li>
                )}

              {(ifOwner() ||
                checkPermission(authState.permissions, 'benchmarking')) &&
                suppliersState.suppliers.list !== null &&
                suppliersState.suppliers.list.length > 0 && (
                  <li className={`sidebar-item ${activeRoute('benchmarking')}`}>
                    <Link className='sidebar-link nav-link' to='/benchmarking'>
                      <i className='mdi mdi-speedometer' />
                      <span className='hide-menu'>{t('benchmarking.name')}</span>
                    </Link>
                  </li>
                )}

              {/* {(ifOwner() ||
                checkPermission(authState.permissions, 'benchmarking')) &&
                suppliersState.suppliers.list !== null &&
                suppliersState.suppliers.list.length > 0 && (
                  <li className={`sidebar-item ${activeRoute('benchmarking')}`}>
                    <Link className='sidebar-link nav-link' to='/benchmarking'>
                      <i className='mdi mdi-chart-areaspline' />
                      <span className='hide-menu'>
                        {t('benchmarking.name')}
                      </span>
                    </Link>
                  </li>
                )} */}
            </Nav>
          </PerfectScrollbar>
        </div>
      </aside>
    </Fragment>
  );
};

export default Sidebar;
