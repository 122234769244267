import React, { Fragment, useContext } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';

// Item
import LoadingplaceItem from './LoadingplaceItem';

// Context
import { GlobalContext } from '../../store/Provider';

// Translation
import { useTranslation } from 'react-i18next';

const LoadingplacesList = () => {
  const { loadingplacesState } = useContext(GlobalContext);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Row className='mt-4'>
        <Col md='12'>
          <Card>
            <CardBody>
              {loadingplacesState.loadingplaces.list !== null &&
              loadingplacesState.loadingplaces.list.length > 0 &&
              !loadingplacesState.loadingplaces.loading ? (
                <Table responsive className='mb-0'>
                  <thead>
                    <tr>
                      <th>{t('loadingplaces.code')}</th>
                      <th>{t('general.address')}</th>
                      <th>{t('loadingplaces.standardVeterinarian')}</th>
                      <th>{t('general.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingplacesState.loadingplaces.list.map(loadingplace => (
                      <LoadingplaceItem
                        key={loadingplace.id}
                        loadingplace={loadingplace}
                      />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4>{t('loadingplaces.notfound')}</h4>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoadingplacesList;
