/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { LinearProgress } from '@mui/material';
// Item
import OfferItem from './OfferItem';


// Translation
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../store/Provider';

const OffersArchive = () => {
  const { suppliersState } = useContext(GlobalContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const redirect = () => {
    history.push('/offers');
  };

  const getAchievedOffers = useCallback(async () => {
    setLoading(true)
    try {
        await axios.get("jsonapi/node/archived_offers").then((response) => {
            const data = response?.data;
            // Use map to create a new array with updated offer_lines
            const parsed_data = data.map((element) => {
                return {
                    ...element, // Spread the existing properties of the element
                    offer_lines: JSON.parse(element["offer_lines"]), // Update the offer_lines property
                };
            });
            setItems(parsed_data || []);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        console.error(error);
    }
  }, []);

  useEffect(() => {
    getAchievedOffers()
  }, [suppliersState.suppliers.activeID])
  
  return (
      <Fragment>
          <h1>{t("offers.archive")}</h1>
          {loading ? (
              <LinearProgress color="inherit" />
          ) : (
              <>
                  <Row className="mb-3 mt-4">
                      <Col md="12">
                          <button
                              className="btn btn-primary"
                              onClick={redirect}
                          >
                              {t("general.prev")}
                          </button>
                      </Col>
                  </Row>
                  <Row className="mt-4">
                      {items.length === 0 ? (
                          <Col lg="6" md="12">
                              <Card>
                                  <CardBody>
                                      <h4>{t("offers.notfound")}</h4>
                                  </CardBody>
                              </Card>
                          </Col>
                      ) : (
                          items.map((offer) => (
                              <OfferItem
                                  key={offer.nid}
                                  offer={offer}
                                  archived={true}
                              />
                          ))
                      )}
                  </Row>
              </>
          )}
      </Fragment>
  );
};

export default OffersArchive;
