import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../../store/Provider';
import { closeAlert } from '../../store/actions/alerts';

const Alerts = () => {
  const { alertsState, alertsDispatch } = useContext(GlobalContext);

  const { t } = useTranslation();

  const onClick = e => {
    closeAlert(e.target.dataset.uuid)(alertsDispatch);
  };

  return (
    alertsState.alerts.length > 0 &&
    alertsState.alerts.map(alert => (
      <div key={alert.id} className='toast fade show'>
        <div className='toast-header'>
          <svg
            className={`rounded text-${alert.type}`}
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'
            role='img'
          >
            <rect fill='currentColor' width='100%' height='100%'></rect>
          </svg>
          &nbsp;
          {alert.type === 'info' ? t('alerts.types.info') : ''}
          {alert.type === 'success' ? t('alerts.types.success') : ''}
          {alert.type === 'danger' ? t('alerts.types.danger') : ''}
          {alert.type === 'warning' ? t('alerts.types.warning') : ''}
          <div className='ml-auto alert-close'>
            <i
              className='mdi mdi-close'
              onClick={onClick}
              data-uuid={alert.id}
            ></i>
          </div>
        </div>
        <div className='toast-body'>{alert.msg}</div>
      </div>
    ))
  );
};

export default Alerts;
