import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GlobalContext } from '../../store/Provider';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authState } = useContext(GlobalContext);

  return (
    <Route
      {...rest}
      render={props =>
        !authState.isAuthenticated ? (
          <Redirect to='/' />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
