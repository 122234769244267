import React, { Fragment } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Layout
import ContributorForm from './ContributorForm';
import ContributorsList from './ContributorsList';

const ContributorsComponent = () => {
  const match = useRouteMatch();

  return (
    <Fragment>
      <Switch>
        <Route path={`${match.path}/edit`} component={ContributorForm} />
        <Route path={`${match.path}/new`} component={ContributorForm} />
        <Route path={match.path} component={ContributorsList} />
      </Switch>
    </Fragment>
  );
};

export default ContributorsComponent;
