import React, { Fragment, useContext, useState, useEffect } from 'react';
import { decodeStr } from '../../utils/Utils';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from 'reactstrap';

import ContainerSpinner from '../layout/ContainerSpinner';

import { GlobalContext } from '../../store/Provider';

import { setAlert } from '../../store/actions/alerts';
import { editUserData } from '../../store/actions/auth';

// Translation
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const AccountNativeForm = () => {
  const { t, i18n } = useTranslation();

  const { alertsDispatch, authState, authDispatch } = useContext(GlobalContext);

  const [changes, setChanges] = useState({
    firstname: authState.user_data.field_first_name[0].value,
    mail: authState.user_data.mail[0].value,
    mobile: authState.user_data.field_mobilenumber[0].value,
    langcode: localStorage.getItem('language'),
  });

  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  useEffect(() => {
    setChanges({
      firstname: authState.user_data.field_first_name[0].value,
      mail: authState.user_data.mail[0].value,
      mobile: authState.user_data.field_mobilenumber[0].value,
      langcode: localStorage.getItem('language'),
    });
    setLoading(false);
    setPasswordLoading(false);

    // eslint-disable-next-line
  }, []);
  const validateTel = e => {
      const re = /^[\+ \d() -]+$/
      if (e.target.value === '' || re.test(e.target.value)) {
        setChanges({ ...changes, [e.target.name]: e.target.value });
     }
  }
  const onChange = e => {
    setChanges({ ...changes, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (changes.langcode !== localStorage.getItem('language')) {
        localStorage.setItem('language', changes.langcode);
        i18n.changeLanguage(changes.langcode);
      }

      const formData = {
        field_first_name: changes.firstname,
        mail: changes.mail,
        mobile: changes.mobile,
        langcode: changes.langcode,
        uid: authState.user_data.uid[0].value,
      };

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await axios.post('/jsonapi/edit_user_info', formData, config);

      editUserData({
        field_first_name: [{ value: changes.firstname }],
        mail: [{ value: changes.mail }],
        field_mobilenumber: [{ value: changes.mobile }],
      })(authDispatch);

      setAlert(t('alerts.success.savedChanges'), 'success')(alertsDispatch);
    } catch (err) {
      if (navigator.onLine) {
        setAlert(
          t('alerts.errors.account.failedToSaveChanges'),
          'danger'
        )(alertsDispatch);
      } else {
        setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
      }
    }

    setLoading(false);
  };

  const requestPasswordChange = async e => {
    e.preventDefault();
    setPasswordLoading(true);
    const current_password = e.target.childNodes[0].childNodes[1].value;
    const new_password = e.target.childNodes[1].childNodes[1].value;
    const confirm_new_password = e.target.childNodes[2].childNodes[1].value;
    
    console.log('here', current_password, new_password, confirm_new_password);

    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!validPassword.test(new_password)) {
      setAlert(
        t('Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'),
        'danger'
      )(alertsDispatch);
      // formIsValid = false;
    } 
    else if (new_password !== confirm_new_password) {
      setAlert(
        t('alerts.warnings.account.passwordMatch'),
        'danger'
      )(alertsDispatch);
    } else if (current_password === new_password) {
      setAlert(
        t('alerts.warnings.account.newPasswordMatchesCurrent'),
        'danger'
      )(alertsDispatch);
    } else {
      try {
        const formData = {
          uid: authState.user_data.uid[0].value,
          current_password: current_password,
          new_password: new_password,
        };

        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        await axios.post('/jsonapi/change_pwd', formData, config);

        setAlert(
          t('alerts.success.account.passwordChanged'),
          'info'
        )(alertsDispatch);
      } catch (err) {
        if (navigator.onLine) {
          setAlert(
            t('alerts.errors.account.failedToChangePassword'),
            'danger'
          )(alertsDispatch);
        } else {
          setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
        }
      }
    }
    setPasswordLoading(false);
  };

  console.log(changes.mobile)

  return (
    <Fragment>
      <Row className='mb-3 mt-4'>
        <Col className='col-md-12 col-lg-6 col-xl-5 col-xlg-4'>
          <Card>
            {loading ? <ContainerSpinner /> : ''}
            <CardBody>
              <CardTitle>{t('account.modifyInformation')}</CardTitle>
              <CardSubtitle>
                <i className='mdi mdi-alert' />{' '}
                {t('account.warningModifyInformation')}
              </CardSubtitle>
              <Form className='mt-3' onSubmit={onSubmit}>
                <FormGroup>
                  <Label htmlFor='firstname'>{t('general.name')}</Label>
                  <Input
                    type='text'
                    name='firstname'
                    value={decodeStr(changes.firstname)}
                    onChange={onChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='mail'>{t('general.email')}</Label>
                  <Input
                    type='email'
                    name='mail'
                    value={changes.mail}
                    onChange={onChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='mobile'>
                    {t('general.phone')}
                    <span className='text-secondary'> (+32123456789)</span>
                  </Label>
                  <Input
                    type='text'
                    name='mobile'
                    value={changes.mobile}
                    onChange={validateTel}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='langcode'>{t('general.language')}</Label>
                  <Input
                    type='select'
                    name='langcode'
                    onChange={onChange}
                    value={changes.langcode}
                    disabled={loading}
                  >
                    <option value='nl'>{t('languages.dutch')}</option>
                    <option value='fr'>{t('languages.french')}</option>
                  </Input>
                </FormGroup>
                <FormGroup className='text-right'>
                  <Button className='btn-primary'>{t('general.save')}</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Card className='mt-3'>
            {passwordLoading ? <ContainerSpinner /> : ''}
            <CardBody>
              <CardTitle>{t('account.changePassword')}</CardTitle>
              <Form className='mt-3' onSubmit={requestPasswordChange}>
                <FormGroup>
                  <Label>{t('account.currentPassword')}</Label>
                  <Input
                    type='password'
                    name='current-password'
                    required={true}
                    disabled={passwordLoading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('login.newPassword')}</Label>
                  <Input type='password' name='new-password' required={true} />
                </FormGroup>
                <FormGroup>
                  <Label>{t('login.confirmNewPassword')}</Label>
                  <Input
                    type='password'
                    name='confirm-new-password'
                    required={true}
                    disabled={passwordLoading}
                  />
                </FormGroup>
                <FormGroup className='text-right'>
                  <Button className='btn-primary'>{t('general.save')}</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AccountNativeForm;
