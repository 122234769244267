import axios from 'axios';
import i18next from 'i18next';

export const getLoadingplaces = (activeSupplier, lang) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  let loadingplaceData, optionData, researchData;
  let queryString = `lang=${lang}&supplier_id=${activeSupplier}`;

  try {
    const res = await axios.get('/jsonapi/entity/loadingplaces?_format=json');

    loadingplaceData = res.data;

    loadingplaceData.forEach(lp => {
      queryString += `&loading_place_ids[]=${lp.id}`;
    });

    response.code = 200;
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.loadingplaces.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  try {
    const res2 = await axios.get(`/jsonapi/multi_lp_offer_type?${queryString}`);

    optionData = Object.keys(res2.data).map(key => {
      return res2.data[key];
    });
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.loadingplaces.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  try {
    const res3 = await axios.get(
      `/jsonapi/loadingplace_researches?${queryString}`
    );

    researchData = Object.keys(res3.data).map(key => {
      return res3.data[key];
    });
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.loadingplaces.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  try {
    researchData.forEach(item => {
      if (item.researches !== undefined) {
        item.researches.sort((a, b) =>
          a.field_research_date < b.field_research_date
            ? 1
            : b.field_research_date < a.field_research_date
            ? -1
            : 0
        );
      }
    });

    loadingplaceData.forEach((lp, index) => {
      const optionObj = optionData.find(option => {
        return option.id === lp.id;
      });
      loadingplaceData[index].options = optionObj.options;

      const researchObj = researchData.find(research => {
        return Number(research.id) === Number(lp.id);
      });
      researchObj.researches === undefined
        ? (loadingplaceData[index].researches = [])
        : (loadingplaceData[index].researches = researchObj.researches);
    });

    dispatch({
      type: 'GET_LOADINGPLACES',
      payload: loadingplaceData,
    });
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.loadingplaces.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

export const setCurrentLoadingplace = loadingplace => dispatch => {
  dispatch({
    type: 'SET_CURRENT_LOADINGPLACE',
    payload: loadingplace,
  });
};

export const clearCurrentLoadingplace = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_LOADINGPLACE' });
};

export const setLPLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const clearLoadingplaceState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};

export const updateLoadingplace =
  (veterinarian, countries, investigated, typeOfCompany, loadingplace) =>
  async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };
    console.log('loadingplace', loadingplace);
    let formData = {
      id: loadingplace.id,
      default_veterinarian: veterinarian !== null ? veterinarian.id : '',
      field_default_countries: countries,
      field_research_carried_out: investigated,
      field_type_of_company: typeOfCompany,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // loadingplace.default_veterinarian = veterinarian !== null ? veterinarian.id : '';
    // loadingplace.field_default_countries = countries;

    // loadingplace.field_research_carried_out = investigated;

    // loadingplace.field_type_of_company =
    //   typeOfCompany === '' ? 0 : typeOfCompany;

    loadingplace = {...loadingplace, 
      default_veterinarian : veterinarian === undefined|| veterinarian === null ? '': veterinarian.id,
      field_default_countries : countries,
      field_research_carried_out : investigated,
      field_type_of_company : typeOfCompany === '' ? 0 : typeOfCompany}

    try {
      await axios.post(`/jsonapi/lp_edit?_format=json`, formData, config);
      dispatch({
        type: 'UPDATE_LOADINGPLACE',
        payload: loadingplace,
      });
      response.code = 200;
      response.msg = i18next.t('alerts.success.savedChanges');
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t('alerts.errors.loadingplaces.couldNotUpdate');
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const removeDefaultVeterinarian = id => dispatch => {
  dispatch({
    type: 'REMOVE_DEFAULT_VETERINARIAN',
    payload: id,
  });
};

export const addResearch = (research, id) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  const formData = {
    loading_place_id: id,
    field_research_type: research.type,
    field_research_date: research.date,
    field_research_result: research.result,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `/jsonapi/loadingplace_post_research?_format=json`,
      formData,
      config
    );

    const research_id = res.data;

    dispatch({
      type: 'ADD_RESEARCH',
      payload: {
        ...formData,
        ID: research_id,
      },
    });

    response.code = 200;
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.500');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.internetConnectionRequired');
    }
  }

  return response;
};

export const removeResearch = (loading_place_id, ID) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  const formData = {
    loading_place_id: loading_place_id,
    ID: ID,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(
      `/jsonapi/loadingplace_delete_research?_format=json`,
      formData,
      config
    );

    dispatch({
      type: 'REMOVE_RESEARCH',
      payload: formData,
    });

    response.code = 200;
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.500');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.internetConnectionRequired');
    }
  }

  return response;
};

export const setFetching = value => dispatch => {
  dispatch({
    type: 'SET_FETCHING',
    payload: value,
  });
};
