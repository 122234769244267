import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { decodeStr } from '../../utils/Utils';
// Context

import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';

import {
  removeContributorFromStable,
  setLoading,
} from '../../store/actions/stables';

import { useTranslation } from 'react-i18next';

const StableContributorItem = ({ contributor }) => {
  const { t } = useTranslation();

  const { stablesState, stablesDispatch, alertsDispatch, authState } =
    useContext(GlobalContext);

  const { id, first_name, email, roles } = contributor;

  const ifOwner = () => {
    return authState.user.roles && authState.user.roles.includes('owner');
  };

  const onDelete = async () => {
    var r = window.confirm(t('general.thisActionCannotBeUndone'));
    if (r === true) {
      setLoading(true)(stablesDispatch);
      const res = await removeContributorFromStable(
        id,
        stablesState.stables.current.id,
        authState.user.uid
      )(stablesDispatch);

      if (res.code === 400) {
        setAlert(res.msg, 'danger')(alertsDispatch);
      } else if (res.code === 504) {
        setAlert(res.msg, 'warning')(alertsDispatch);
      } else {
        setAlert(res.msg, 'success')(alertsDispatch);
      }

      setLoading(false)(stablesDispatch);
    }
  };

  return (
    <Fragment>
      <tr>
        <th scope='row'>{decodeStr(first_name)}</th>
        <td>{email}</td>
        <td>{roles}</td>
        {ifOwner() && (
          <td className='text-center'>
            {ifOwner() && Number(id) !== Number(authState.user.uid) && (
              <button className='btn btn-secondary btn-sm' onClick={onDelete}>
                <i className='icon-trash'></i>{' '}
                <span>{t('general.delete')}</span>
              </button>
            )}
          </td>
        )}
      </tr>
    </Fragment>
  );
};

StableContributorItem.propTypes = {
  contributor: PropTypes.object.isRequired,
};

export default StableContributorItem;
