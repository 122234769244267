import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <Fragment>
      <li className='nav-item'>
        <a className='nav-link' href='#!' onClick={() => changeLanguage('nl')}>
          NL
        </a>
      </li>
      {/* <li className="nav-item">
                <a className="nav-link" href="#!" onClick={() => changeLanguage('en')}>EN</a>
            </li> */}
      <li className='nav-item'>
        <a className='nav-link' href='#!' onClick={() => changeLanguage('fr')}>
          FR
        </a>
      </li>
    </Fragment>
  );
};

export default Language;
