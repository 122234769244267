import React, { Fragment, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Questionmark from './QuestionMarkTip';
const GraphTooltip = ({ id, content, question, message }) => {
  return (
    <Fragment>
      {/* <span id={id} href='#'>
        <i className='icon-question' style={{ verticalAlign: 'middle' }}></i>
      </span>
      <UncontrolledTooltip placement='right' target={id}>
        <div className='p-2 text-left'>{content}</div>
      </UncontrolledTooltip> */}
      <Questionmark question = {question}  message = {message} id={id}/>
    </Fragment>

  );
};

export default GraphTooltip;
