import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Language from '../components/layout/Language';

import img2 from '../assets/images/bg-pig.jpg';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

const LegalInfo = () => {
  // eslint-disable-next-line
  const { t } = useTranslation();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div
        className='auth-wrapper overflow-y align-items-center d-flex'
        style={sidebarBackground}
      >
        <div className='auth-nav'>
          <ul className='ml-auto navbar-nav'>
            {/* <li>
              <Link className='nav-link mr-3' to='/about'>
                {t('general.aboutPP')}
              </Link>
            </li> */}
            <Language />
          </ul>
        </div>
        <div className='container'>
          <div>
            <Row className='no-gutters justify-content-center'>
              <Col md='12' className='bg-white'>
                <div className='p-4'>
                  <Link to='/login' className='btn btn-primary btn-sm mb-3'>
                    {t('general.backToLogin')}
                  </Link>
                  <h3>Bescherming van persoonsgegevens.</h3>
                  <p>
                    Belgian Pork Group (hierna Belgian Pork Group genoemd) hecht
                    groot belang aan de bescherming van de persoonlijke
                    <br />
                    levenssfeer en verwerkt jouw persoonsgegevens met de nodige
                    zorg.
                    <br />
                    De persoonsgegevens die je via
                    https://www.belgianporkgroup.com verstrekt, worden verwerkt
                    door Belgian Pork
                    <br />
                    Group, Ommegang West 9 - B-8840 Westrozebeke. Belgian Pork
                    Group is de verantwoordelijke voor de verwerking.
                    <br />
                    Jouw gegevens worden verwerkt conform de bepalingen van de
                    Europese Algemene Verordening
                    <br />
                    gegevensbescherming (AVG) van 24 mei 2016.
                  </p>
                  <h3>Doeleinde van de verwerking.</h3>
                  <p>
                    We verwerken jouw persoonsgegevens om een dienstverlening te
                    kunnen aanbieden, nl. solliciteren op vacatures en
                    <br />
                    informatie aanvragen via een contactformulier via een
                    digitaal platform. Alle verwerkingen gebeuren enkel en
                    alleen met
                    <br />
                    het oog op het verzekeren van deze dienstverlening.
                  </p>
                  <h3>Aard van de verwerkte gegevens.</h3>
                  <p>
                    Ben je een sollicitant, dan verwerken we volgende
                    persoonsgegevens: naam, voornaam, email, telefoon, adres,
                    <br />
                    geboortedatum, nationaliteit en talenkennis.
                  </p>
                  <h3>Bewaartermijn van de gegevens.</h3>
                  <p>
                    De gegevens worden bewaard zolang als nodig is om de
                    hierboven vermelde dienstverlening te kunnen aanbieden.
                  </p>
                  <h3>Doorgifte aan derden.</h3>
                  <p>
                    Belgian Pork Group geeft je gegevens in geen enkel geval
                    door aan derden. Om jouw gegevens te verwerken maakt
                    <br />
                    Belgian Pork Group gebruikt van de diensten van CreaThing
                    bvba. CreaThing bvba treedt op als verwerker en verwerkt
                    <br />
                    jouw gegevens enkel volgens de schriftelijke instructies van
                    Belgian Pork Group zoals overeengekomen in een
                    <br />
                    verwerkingsovereenkomst.
                  </p>
                  <h3>Beveiliging van de gegevens.</h3>
                  <p>
                    Belgian Pork Group neemt alle technische en organisatorische
                    maatregelen om de verwerking van jouw gegevens veilig
                    <br />
                    te laten verlopen en wij eisen van derden die jouw
                    persoonsgegevens voor ons behandelen of verwerken dat zij
                    <br />
                    hetzelfde doen. Toegang tot jouw persoonsgegevens is beperkt
                    om ongeoorloofde toegang, wijziging of misbruik te
                    <br />
                    voorkomen en is alleen toegestaan aan onze medewerkers.
                  </p>
                  <h3>Jouw rechten als betrokkene.</h3>
                  <p>
                    Je kan je gegevens bekijken via{' '}
                    <a href='https://www.belgianporkgroup.com'>
                      www.belgianporkgroup.com
                    </a>
                    . Dit doe je door in te loggen met je account. Je kan ook
                    <br />
                    foutieve gegevens laten wijzigen of onvolledige laten
                    aanvullen. Hiervoor neem je best contact op met Belgian Pork
                    <br />
                    Group via{' '}
                    <a href='mailto:privacy@belgianporkgroup.com'>
                      privacy@belgianporkgroup.com
                    </a>
                    . Een aantal gegevens kan je zelf aanpassen via{' '}
                    <a href='https://www.belgianporkgroup.com'>
                      www.belgianporkgroup.com
                    </a>
                    .<br />
                    Dit doe je door in te loggen met je account. Je kan jouw
                    gegevens laten verwijderen door je account via
                    <br />
                    <a href='https://www.belgianporkgroup.com'>
                      www.belgianporkgroup.com
                    </a>{' '}
                    te verwijderen. Besef dan wel dat je hierdoor geen beroep
                    meer kan doen op de hierboven
                    <br />
                    vermelde dienstverlening van Belgian Pork Group.
                  </p>
                  <h3>Vragen of klachten?</h3>
                  <p>
                    Heb je een vraag over de verwerking van jouw
                    persoonsgegevens, stel deze dan via{' '}
                    <a href='mailto:privacy@belgianporkgroup.com'>
                      privacy@belgianporkgroup.com
                    </a>
                    .<br />
                    Denk je dat jouw persoonsgegevens niet correct verwerkt
                    worden, dan je ook terecht bij de Commissie voor de
                    <br />
                    bescherming van de persoonlijke levenssfeer, Drukpersstraat
                    35 in 1000 Brussel, tel. 02 274 48 00 of via
                    <br />
                    <a href='mailto:commission@privacycommission.be'>
                      commission@privacycommission.be
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-nav'>
          &copy; 2021 - <a href='#!'>Legal info</a>
        </div>
      </div>
    </Fragment>
  );
};

export default LegalInfo;
