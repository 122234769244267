import axios from 'axios';

export const getArticles = () => dispatch => {
  try {
    axios.get('/jsonapi/entity/news?_format=json').then(res => {
      dispatch({
        type: 'SET_ARTICLES',
        payload: res.data,
      });
    });
  } catch (err) {
    console.log(err.response);
  }
};

export const setCurrentArticle = id => dispatch => {
  dispatch({
    type: 'SET_CURRENT_ARTICLE',
    payload: id,
  });
};

export const clearCurrentArticle = () => dispatch => {
  dispatch({
    type: 'CLEAR_CURRENT_ARTICLE',
    payload: null,
  });
};
