import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ChartPigPrice = ({ chartData, cardData }) => {
  const [series, setSeries] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let tmp = [];
    for (const [key, value] of Object.entries(chartData)) {
      let data = [];
      // eslint-disable-next-line
      for (const [dKey, dValue] of Object.entries(value)) {
        data.push(dValue);
      }
      tmp.push({
        name: key,
        data: data,
      });
    }
    setSeries(tmp);
  }, [chartData]);

  const chartOptions = {
    series: series,
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          dynamicAnimation: {
            enabled: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 1,
      },
      title: {
        text: 'Price history',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        tickAmount: 12,
        labels: {
          show: true,
          hideOverlappingLabels: true,
        },
        // categories: [
        //   'Jan',
        //   'Feb',
        //   'Mar',
        //   'Apr',
        //   'May',
        //   'Jun',
        //   'Jul',
        //   'Aug',
        //   'Sep',
        // ],
        categories: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '41',
          '42',
          '43',
          '44',
          '45',
          '46',
          '47',
          '48',
          '49',
          '50',
          '51',
          '52',
          '53',
        ],
      },
    },
  };

  return (
    <Card style={{ height: '100%', marginBottom: 0 }}>
      <CardBody style={{ flex: 0 }}>
        <CardTitle className='mb-0'>
          {t('dashboard.pigPricing')} - {t('general.weekNumber')}{' '}
          <span className='text-primary'>{cardData.current_week}</span>
        </CardTitle>
        <div className='mt-3'></div>
      </CardBody>
      <CardBody>
        <div className='pigprice-wrap'>
          <div className='pigprice-item'>
            <p>{t('dashboard.slaughtered')}</p>
            <h5>{cardData.slaughtered}</h5>
            <p>{cardData.mutation_slaughtered}</p>
          </div>
          <div className='pigprice-item'>
            <p>{t('dashboard.alive')}</p>
            <h5>{cardData.alive}</h5>
            <p>{cardData.mutation_alive}</p>
          </div>
        </div>
      </CardBody>
      <CardBody>
        {/* <ChartPigPrice priceData={priceData} /> */}
        <div className='mixed-chart'>
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type='line'
            height={430}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartPigPrice;
