import axios from 'axios';
import i18next from 'i18next';

export const setFetching = value => dispatch => {
  dispatch({
    type: 'SET_FETCHING',
    payload: value,
  });
};

export const getOffers = () => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  dispatch({
    type: 'SET_FETCHING',
    payload: false,
  });
  
  // try {
  //   const res = await axios.get('/jsonapi/node/offers?_format=json');

  //   const allOffers = res.data;
  //   let archived = [];
  //   let active = [];

  //   allOffers.forEach(offer => {
  //     offer.offer_lines = JSON.parse(offer.offer_lines);
  //     offer.field_archived !== '1' ? active.push(offer) : archived.push(offer);
  //   });

  //   const payload = {
  //     active: active,
  //     archived: archived,
  //   };

  //   dispatch({
  //     type: 'GET_OFFERS',
  //     payload: payload,
  //   });
  //   response.code = 200;
  // } catch (err) {
  //   dispatch({
  //     type: 'SET_FETCHING',
  //     payload: false,
  //   });
  //   if (navigator.onLine) {
  //     response.code = 400;
  //     response.msg = i18next.t('alerts.errors.offers.couldNotFetch');
  //   } else {
  //     response.code = 504;
  //     response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
  //   }
  // }
  return response;
};

function pad2(n) {
  return n < 10 ? '0' + n : n;
}

export const addOffer = (offer, status) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  const {
    field_loading_place,
    departure,
    field_remarks,
    field_supplier,
    offer_lines,
  } = offer;

  const date = departure.split('-');
  const year = date[0];
  const week = date[1];

  const dateTime = new Date();
  const statusDate = `${pad2(dateTime.getDate())}/${pad2(
    dateTime.getMonth() + 1
  )}/${dateTime.getFullYear().toString()} ${pad2(dateTime.getHours())}:${pad2(
    dateTime.getMinutes()
  )}`;

  const updatedOfferLines = [];
  offer_lines.forEach(offerLine => {
    updatedOfferLines.push({
      numpigs: offerLine.numpigs,
      country_code: offerLine.country_code,
      offertype_code: offerLine.offertype_code,
      dropdown1: offerLine.dropdown1,
      dropdown2: offerLine.dropdown2,
      dropdown3: offerLine.dropdown3,
      status: 'OPMAK',
      field_status_changed: statusDate,
      vki: '',
      field_vki_base64: '',
    });
  });

  const formData = {
    dejaart: year,
    deweenr: week,
    status: status,
    field_status_changed: statusDate,
    field_loading_place: field_loading_place,
    field_remarks: field_remarks,
    field_supplier: field_supplier,
    offer_lines: updatedOfferLines,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post('/jsonapi/offer', formData, config);

    let data;

    if (res.data.DEERROR) {
      data = res.data;
      response.code = 300;
      response.msg = i18next.t(
        'alerts.warnings.offers.offerSentButNotReceived'
      );
    } else {
      data = JSON.parse(res.data);
      response.code = 200;
      response.msg = i18next.t('alerts.success.offers.added');
    }

    const offerLinesWithNid = [];
    for (let i = 0; i < updatedOfferLines.length; i++) {
      offerLinesWithNid.push({
        ...updatedOfferLines[i],
        nid: data.R_PPLLN[i].DEPPLLN.toString(),
        status: data.R_PPLLN[i].DEABDST,
      });
    }

    dispatch({
      type: 'ADD_OFFER',
      payload: {
        ...formData,
        nid: data.R_PPLLN[0].DEPPLID.toString(),
        offer_lines: offerLinesWithNid,
      },
    });
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.offers.couldNotAdd');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  return response;
};

export const updateOffer = offer => dispatch => {
  dispatch({
    type: 'UPDATE_OFFER',
    payload: offer,
  });
};


export const offersListAction = (previousState , offer={}) => async dispatch => {
  const user_details = JSON.parse(localStorage.getItem("user_data"));
  const { pageNumber = 1 } = offer;

  let response = {
    code: 0,
    msg: '',
  };


  try {
    const res = await axios.get(`/offers/${user_details["uid"]}/${pageNumber}?_format=json`)

    dispatch({
      payload: { ...previousState,...res},
    });
    response.data = res
    response.code = 200;
    response.msg = i18next.t('alerts.success.offers.archived');
  } catch (err) {
    console.log('error ', err);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.offers.couldNotArchive');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  return response;
};

export const pageNumberAction = (previousState) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {

    dispatch({
      payload: { ...previousState},
    });

    response.code = 200;
    response.msg = i18next.t('alerts.success.offers.archived');
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.offers.couldNotArchive');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  return response;
};

export const archiveOffer = offer => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  const config = {
    headers: {
      // 'Content-Type': 'application/hal+json',
      'Content-Type': 'application/json',
    },
  };

  // const formData = {
  //   _links: {
  //     type: {
  //       href: 'https://www.backend.bpgap.creathing.be/rest/type/node/offer',
  //     },
  //   },
  //   field_archived: { value: true },
  // };

  const formData = {
    offer_id: offer.nid,
  };

  try {
    // await axios.patch(
    //   `/node/${offer.nid}?_format=hal_json`,
    //   JSON.stringify(formData),
    //   config
    // );
    await axios.post(`/jsonapi/archiveoffer?_format=json`, formData, config);

    dispatch({
      type: 'ARCHIVE_OFFER',
      payload: { ...offer, field_archived: '1' },
    });
    response.code = 200;
    response.msg = i18next.t('alerts.success.offers.archived');
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.offers.couldNotArchive');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  return response;
};

export const setCurrentOffer = offer => dispatch => {
  dispatch({
    type: 'SET_CURRENT_OFFER',
    payload: offer,
  });
};

export const clearCurrentOffer = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_OFFER' });
};

export const setCurrentOfferLine = offerLine => dispatch => {
  dispatch({
    type: 'SET_CURRENT_OFFER_LINE',
    payload: offerLine,
  });
};

export const clearCurrentOfferLine = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_OFFER_LINE' });
};

export const setLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const saveVKI =
  (base64, filename, offer_nid, offerLine_nid) => dispatch => {
    dispatch({
      type: 'SAVE_VKI',
      payload: {
        base64: base64,
        filename: filename,
        offer_nid: offer_nid,
        offerLine_nid: offerLine_nid,
      },
    });
  };

export const clearOfferState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};

export const resendOfferLine = data => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post('/jsonapi/resendofferline', data, config);

    const parsed = JSON.parse(res.data);

    dispatch({
      type: 'RESEND_OFFER_LINE',
      payload: {
        offer_id: data.offer_id,
        offerline_id: data.offerline_id,
        status: parsed.R_PPLLN[0].DEABDST,
      },
    });

    response.code = 200;
    response.msg = i18next.t('alerts.success.offers.resendOfferLine');
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.offers.failedToResendOfferLine');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  return response;
};

export const setCurrentVKI = (supplier_id, offerline_id) => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {
    const res = await axios.get(
      `/getvki?offerlineID=${offerline_id}&supplierID=${supplier_id}`
    );

    const vki_data = res.data;

    dispatch({
      type: 'SET_CURRENT_VKI',
      payload: vki_data,
    });

    response.code = 200;
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.500');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.offers.offlineVKINotFetched');
    }
  }

  return response;
};

export const clearCurrentVKI = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_VKI' });
};
