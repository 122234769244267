import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import axios from 'axios';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../store/Provider';
import { setAlert } from '../store/actions/alerts';
import { login } from '../store/actions/auth';

import Language from '../components/layout/Language';

import img1 from '../assets/images/logo.svg';
import img2 from '../assets/images/bg-pig.jpg';
import InlineSpinner from '../components/layout/InlineSpinner';
import ContainerSpinner from '../components/layout/ContainerSpinner';
import Alerts from '../components/layout/Alerts';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

const Login = () => {
  const { alertsDispatch, authState, authDispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('localstorage_login') === 'true') {
      login(null, true)(authDispatch);
    }
    setLoginState({
      ...loginState,
      modal: false,
      verify: false,
    });
    // eslint-disable-next-line
    return () => {
      setLoginState({
        ...loginState,
        modal: false,
        verify: false,
      });
    };
    // eslint-disable-next-line
  }, []);

  const [loginState, setLoginState] = useState({
    modal: false,
    verify: false,
    recoveryLoading: false,
  });

  const toggleRecoveryModal = () => {
    setLoginState({
      ...loginState,
      modal: !loginState.modal,
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    let data = {};

    if (
      window.location.host === 'pigplaza.be' ||
      window.location.host === 'www.pigplaza.be'
    ) {
      if (localStorage.getItem('fingerprint') === null) {
        // Initialize an agent at application startup.
        // const fpPromise = FingerprintJS.load({
        //   token: 'SKJXjlcBdzJT91HqVey9',
        //   region: 'eu',
        // });

        // // Get the visitor identifier when you need it.
        // fpPromise
        //   .then(fp => fp.get())
        //   .then(result => localStorage.setItem('fingerprint', result));
        //tijdelijke oplossing.
        if (localStorage.getItem('fingerprint') === null) {
          localStorage.setItem(
            'fingerprint',
            '8Vs=KTHnS_3Mly!=*hA&*Rp!6c!9?dbU_m8x2o_s90C%0gjhY3'
          );
        }
      }
    } else {
      if (localStorage.getItem('fingerprint') === null) {
        localStorage.setItem(
          'fingerprint',
          '8Vs=KTHnS_3Mly!=*hA&*Rp!6c!9?dbU_m8x2o_s90C%0gjhY3'
        );
      }
    }

    if (loginState.verify) {
      data = {
        name: e.target[0].value,
        pass: e.target[1].value,
        fingerprint: localStorage.getItem('fingerprint'),
        verification_code: e.target[2].value,
      };
    } else {
      data = {
        name: e.target[0].value,
        pass: e.target[1].value,
        fingerprint: localStorage.getItem('fingerprint'),
      };
    }

    const res = await login(data)(authDispatch);

    if (res.code !== 200 && res.code === 418) {
      setLoginState({
        ...loginState,
        verify: true,
      });
    } else if (res.code !== 200) {
      setLoginState({
        ...loginState,
        verify: false,
      });
      setAlert(res.msg, 'danger')(alertsDispatch);
    }
  };

  const onPassRecoverySubmit = async e => {
    setLoginState({
      ...loginState,
      recoveryLoading: true,
    });
    e.preventDefault();

    const data = {
      mail: e.target[0].value,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.post('/user/lost-password?_format=json', data, config);

      toggleRecoveryModal();
      setAlert(
        t('alerts.success.auth.resetPasswordSent'),
        'success'
      )(alertsDispatch);
    } catch (error) {
      if (navigator.onLine) {
        setAlert(
          t('alerts.errors.auth.failedToRequestPasswordReset'),
          'danger'
        )(alertsDispatch);
      } else {
        setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
      }
    }
    setLoginState({
      ...loginState,
      recoveryLoading: false,
    });
  };

  if (authState.isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Fragment>
      {/* {loading ? <PageSpinner msg={t('general.oneMomentPlease')} /> : ''} */}
      <div
        className='auth-wrapper  align-items-center d-flex'
        style={sidebarBackground}
      >
        <div className='auth-nav'>
          <ul className='ml-auto navbar-nav'>
            {/* <li>
              <Link className='nav-link mr-3' to='/about'>
                {t('general.aboutPP')}
              </Link>
            </li> */}
            <Language />
          </ul>
        </div>
        <div className='container'>
          <div>
            <Row className='no-gutters justify-content-center'>
              <Col
                md='6'
                lg='4'
                className='bg-dark text-white d-flex align-items-center justify-content-center'
              >
                <div className='text-center p-4'>
                  <img src={img1} width='50%' alt={t('general.appName')} />
                  <h3 className='font-medium mt-3 text-white'>
                    {t('general.appName')}
                  </h3>
                </div>
              </Col>
              <Col md='6' lg='4' className='bg-white'>
                <div className='p-4'>
                  <Form
                    className='mt-3 mb-3'
                    id='loginform'
                    onSubmit={onSubmit}
                  >
                    {loginState.verify && (
                      <div className='text-center mb-4'>
                        <p className='text-primary'>
                          {t('login.verificationCodeSent')}
                        </p>
                      </div>
                    )}
                    <InputGroup className='mb-2' size='lg'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ti-user'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled={authState.loading || loginState.verify}
                        name='email'
                        type='text'
                        placeholder={t('general.username')}
                        required
                        className='fs-rem-85'
                      />
                    </InputGroup>
                    <InputGroup
                      className={loginState.verify ? 'mb-2' : 'mb-3'}
                      size='lg'
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ti-pencil'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled={authState.loading || loginState.verify}
                        type='password'
                        name='password'
                        placeholder={t('general.password')}
                        required
                        className='fs-rem-85'
                      />
                    </InputGroup>

                    {loginState.verify && (
                      <InputGroup className='mb-3' size='lg'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ti-key'></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={authState.loading}
                          type='text'
                          name='verification_code'
                          placeholder={t('general.verificationCode')}
                          required
                        />
                      </InputGroup>
                    )}

                    <Row className='mb-3'>
                      <Col xs='12'>
                        <Button
                          disabled={authState.loading}
                          color='primary'
                          size='lg'
                          type='submit'
                          block
                        >
                          {authState.loading ? (
                            <InlineSpinner color='white' />
                          ) : (
                            t('login.signIn')
                          )}
                        </Button>
                      </Col>
                    </Row>
                    <div className='text-center'>
                      <a
                        href='#!'
                        onClick={toggleRecoveryModal}
                        className='text-info ml-1'
                      >
                        <b>{t('login.forgotPassword')}</b>
                      </a>
                    </div>
                    <div className='text-center'>
                      {t('login.noAccount')}{' '}
                      <Link to='/register' className='text-info ml-1'>
                        <b>{t('login.signUp')}</b>
                      </Link>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-nav'>
          &copy; {new Date().getFullYear()} - <Link to='/legal-info'>Legal info</Link>
        </div>
      </div>
      <Modal
        isOpen={loginState.modal}
        toggle={toggleRecoveryModal}
        className='modal-center'
      >
        <ModalHeader toggle={toggleRecoveryModal}>
          {t('login.forgotPassword')}
        </ModalHeader>
        <Form onSubmit={onPassRecoverySubmit}>
          <ModalBody>
            {loginState.recoveryLoading && <ContainerSpinner />}
            <FormGroup>
              <Label>{t('general.email')}</Label>
              <Input name='recoveryEmail' type='email' required />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color='primary'>{t('general.submit')}</Button>
            <Button color='secondary' onClick={toggleRecoveryModal}>
              {t('general.close')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <div className='alert-region'>
        <Alerts />
      </div>
    </Fragment>
  );
};

export default Login;