import React, { Fragment, useContext } from 'react';
import { Row, Col, Card, CardBody, Table, CardTitle } from 'reactstrap';

// Item
import StableItem from './StableItem';

// Context
import { GlobalContext } from '../../store/Provider';

// Translation
import { useTranslation } from 'react-i18next';

const StablesList = () => {
  const { stablesState, loadingplacesState } = useContext(GlobalContext);

  const { t } = useTranslation();

  const checkStables = lp_code => {
    let connectedStables = [];

    if (stablesState.stables.list.length > 0) {
      stablesState.stables.list.forEach(stable => {
        if (stable.lp_code === lp_code) {
          connectedStables.push(stable);
        }
      });
    }

    return connectedStables;
  };

  return (
    <Fragment>
      {stablesState.stables.list !== null &&
      stablesState.stables.list.length !== 0 &&
      !stablesState.stables.loading ? (
        <Row className='mt-4'>
          <Col md='12'>
            <Card>
              {loadingplacesState.loadingplaces.list.map(loadingplace => {
                const checkedStables = checkStables(loadingplace.lp_code);

                if (checkedStables.length > 0) {
                  return (
                    <CardBody key={loadingplace.id}>
                      <CardTitle className='mt-2'>
                        {t('loadingplaces.singular')} {loadingplace.lp_code}
                      </CardTitle>
                      <Table responsive className='mb-0'>
                        <thead>
                          <tr>
                            <th>{t('stables.stableId')}</th>
                            <th>{t('stables.stableTitle')}</th>
                            <th>{t('loadingplaces.code')}</th>
                            <th>{t('general.accessFrom')}</th>
                            <th>{t('general.accessTo')}</th>
                            <th>{t('contributors.name')}</th>
                            <th>{t('general.actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkedStables.map(stable => {
                            return (
                              <StableItem key={stable.id} stable={stable} />
                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  );
                } else {
                  return '';
                }
              })}
              {/* <CardBody>
                <Table responsive className='mb-0'>
                  <thead>
                    <tr>
                      <th>{t('stables.stableId')}</th>
                      <th>{t('stables.stableTitle')}</th>
                      <th>{t('loadingplaces.code')}</th>
                      <th>{t('contributors.name')}</th>
                      <th>{t('general.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stables.map(stable => (
                      <StableItem key={stable.id} stable={stable} />
                    ))}
                  </tbody>
                </Table>
              </CardBody> */}
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md='12'>
            <Card>
              <CardBody>
                <h4>{t('stables.notfound')}</h4>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default StablesList;
