import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import PageSpinner from '../../layout/PageSpinner';

import emptyVkiNL from '../../../assets/docs/vki-nl.pdf';
import emptyVkiFR from '../../../assets/docs/vki-fr.pdf';

import { GlobalContext } from '../../../store/Provider';
import { saveVKI } from '../../../store/actions/offers';
import { setAlert } from '../../../store/actions/alerts';

// Translation
import { useTranslation } from 'react-i18next';

const Step6 = ({ vki, setVKI }) => {
  const { suppliersState, alertsDispatch, offersState, offersDispatch } =
    useContext(GlobalContext);

  const { t } = useTranslation();
  const history = useHistory();
  const langCode = localStorage.getItem('language');

  useEffect(() => {
    async function init() {
      const base64 = await generateVki();
      onSend(base64);
    }
    init();

    return () => {
      setVKI({ ...vki, finished: true });
    };
    // eslint-disable-next-line
  }, []);

  const supplierName = suppliersState.suppliers.list
    .filter(supplier => supplier.id === suppliersState.suppliers.activeID)[0]
    .title.trim()
    .replaceAll(' ', '-')
    .toLowerCase();

  const filename = `vki_${supplierName}_00${
    offersState.offers.currentOfferLine.nid
  }${Date.now()}.pdf`;

  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState('');
  // eslint-disable-next-line
  const [pdfBase64, setPdfBase64] = useState('');

  const generateVki = async () => {
    // const url = emptyVki;
    let url;
    if (langCode === 'nl') {
      url = emptyVkiNL;
    } else if (langCode === 'fr') {
      url = emptyVkiFR;
    }
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    if (langCode === 'nl') {
      // Naam verantwoordelijke
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld1[0]')
        .setText(vki.nameProducer);
      // Adres Beslag
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld2[0]')
        .setText(
          `${vki.addressProducer}, ${vki.zipProducer} ${vki.cityProducer}, ${vki.countryProducer}`
        );
      // Email producent
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld3[0]')
        .setText(vki.emailProducer);
      // Code beslag
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld4[0]')
        .setText(vki.codeFittings);
      // Telefoon product
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld5[0]')
        .setText(vki.phoneProducer);
      // Fax producent
      // form
      //   .getTextField('topmostSubform[0].Page1[0].Tekstveld6[0]')
      //   .setText(vki.faxProducer);
      // Naam dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld7[0]')
        .setText(vki.nameVeterinarian);
      // Adres dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld8[0]')
        .setText(
          `${vki.addressVeterinarian}, ${vki.zipVeterinarian} ${vki.cityVeterinarian}, ${vki.countryVeterinarian}`
        );
      // Telefoon dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld5[1]')
        .setText(vki.phoneVeterinarian);
      // Fax dierenarts
      // form
      //   .getTextField('topmostSubform[0].Page1[0].Tekstveld5[2]')
      //   .setText(vki.faxVeterinarian);
      // Email dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld5[3]')
        .setText(vki.emailVeterinarian);
      // Aantal varkens
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld9[0]')
        .setText(vki.amountOfPigs.toString());
      // Klopnummer
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld9[1]')
        .setText(vki.serialNumber);
      // Opzetdatum
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld10[0]')
        .setText(new Date(vki.setupDate).toLocaleDateString('en-GB'));
      // Voorziene datum van vertrek
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld11[0]')
        .setText(new Date(vki.departureDate).toLocaleDateString('en-GB'));
      // Type varkens
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[0]')
        .select(vki.typeOfPigs);
      // Buitenbeloop
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[1]')
        .select(vki.outdoors);
      // Gecontroleerde huisvesting
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[2]')
        .select(vki.controlledHousing);
      // Antiparasitaire middelen
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[3]')
        .select(vki.antiparasiticDrugs);
      // Antibiotica
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[4]')
        .select(vki.antibiotics);
      // Ontstekingswerende middelen
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[5]')
        .select(vki.antiInflamatoryDrugs);
      // Naam geneesmiddel 1
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld12[0]')
        .setText(vki.nameMedication1);
      // Datum geneesmiddel 1
      if (vki.dateFromMedication1 !== '') {
        let dateString = new Date(vki.dateFromMedication1).toLocaleDateString(
          'en-GB'
        );

        if (vki.dateUntilMedication1 !== '') {
          dateString += ` - ${new Date(
            vki.dateUntilMedication1
          ).toLocaleDateString('en-GB')}`;
        }

        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld15[0]')
          .setText(dateString);
      }
      // Duur geneesmiddel 1
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld18[0]')
        .setText(vki.durationMedication1);
      // Naam geneesmiddel 2
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld13[0]')
        .setText(vki.nameMedication2);
      // Datum geneesmiddel 2
      if (vki.dateFromMedication2 !== '') {
        let dateString = new Date(vki.dateFromMedication2).toLocaleDateString(
          'en-GB'
        );

        if (vki.dateUntilMedication2 !== '') {
          dateString += ` - ${new Date(
            vki.dateUntilMedication2
          ).toLocaleDateString('en-GB')}`;
        }

        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld16[0]')
          .setText(dateString);
      }
      // Duur geneesmiddel 2
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld19[0]')
        .setText(vki.durationMedication2);
      // Naam geneesmiddel 3
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld14[0]')
        .setText(vki.nameMedication3);
      // Datum geneesmiddel 3
      if (vki.dateFromMedication3 !== '') {
        let dateString = new Date(vki.dateFromMedication3).toLocaleDateString(
          'en-GB'
        );

        if (vki.dateUntilMedication3 !== '') {
          dateString += ` - ${new Date(
            vki.dateUntilMedication3
          ).toLocaleDateString('en-GB')}`;
        }

        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld17[0]')
          .setText(dateString);
      }
      // Duur geneesmiddel 3
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld20[0]')
        .setText(vki.durationMedication3);
      // Sterfte
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[6]')
        .select(vki.deaths);
      // Oorzaken sterfte
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld21[0]')
        .setText(vki.causeOfDeaths);
      // Ziekte
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[7]')
        .select(vki.sickness);
      // Oorzaken Ziekte
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld22[0]')
        .setText(vki.causeOfSickness);
      // Er werden onderzoeken uitgevoerd ...
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[10]')
        .select(vki.investigated);
      // Datum onderzoek
      if (vki.investigationDate !== '') {
        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld23[0]')
          .setText(new Date(vki.investigationDate).toLocaleDateString('en-GB'));
      }

      // Laatste gemiddelde S/P-ratio
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld24[0]')
        .setText(vki.investigationResult);
      // Risicobedrijf
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[8]')
        .select(vki.typeOfCompany);
      // Soort onderzoek optioneel

      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld25[0]')
        .setText(vki.optionalInvestigation);

      // Datum onderzoek optioneel
      if (vki.optionalInvestigationDate !== '') {
        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld26[0]')
          .setText(
            new Date(vki.optionalInvestigationDate).toLocaleDateString('en-GB')
          );
      }
      // Analyse resultaat optioneel
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld27[0]')
        .setText(vki.optionalInvestigationResult);
      // Export aan derde landen
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld28[0]')
        .setText(vki.listOfCountries);
      // Handtekening producent
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld29[0]')
        .setText(vki.signatureProducer);
      // Datum
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld30[0]')
        .setText(new Date(vki.signatureDate).toLocaleDateString('en-GB'));
    } else if (langCode === 'fr') {
      // Naam verantwoordelijke
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld1[0]')
        .setText(vki.nameProducer);
      // Adres Beslag
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld2[0]')
        .setText(
          `${vki.addressProducer}, ${vki.zipProducer} ${vki.cityProducer}, ${vki.countryProducer}`
        );
      // Email producent
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld3[0]')
        .setText(vki.emailProducer);
      // Code beslag
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld4[0]')
        .setText(vki.codeFittings);
      // Telefoon product
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld5[0]')
        .setText(vki.phoneProducer);
      // Fax producent
      // form
      //   .getTextField('topmostSubform[0].Page1[0].Tekstveld6[0]')
      //   .setText(vki.faxProducer);
      // Naam dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld7[0]')
        .setText(vki.nameVeterinarian);
      // Adres dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld8[0]')
        .setText(
          `${vki.addressVeterinarian}, ${vki.zipVeterinarian} ${vki.cityVeterinarian}, ${vki.countryVeterinarian}`
        );
      // Telefoon dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld9[0]')
        .setText(vki.phoneVeterinarian);
      // Fax dierenarts
      // form
      //   .getTextField('topmostSubform[0].Page1[0].Tekstveld10[0]')
      //   .setText(vki.faxVeterinarian);
      // Email dierenarts
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld11[0]')
        .setText(vki.emailVeterinarian);
      // Aantal varkens
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld12[0]')
        .setText(vki.amountOfPigs.toString());
      // Klopnummer
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld13[0]')
        .setText(vki.serialNumber);
      // Opzetdatum
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld14[0]')
        .setText(new Date(vki.setupDate).toLocaleDateString('en-GB'));
      // Voorziene datum van vertrek
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld15[0]')
        .setText(new Date(vki.departureDate).toLocaleDateString('en-GB'));
      // Type varkens
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[0]')
        .select(vki.typeOfPigs);
      // Buitenbeloop
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[1]')
        .select(vki.outdoors);
      // Gecontroleerde huisvesting
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[2]')
        .select(vki.controlledHousing);
      // Antiparasitaire middelen
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[3]')
        .select(vki.antiparasiticDrugs);
      // Antibiotica
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[4]')
        .select(vki.antibiotics);
      // Ontstekingswerende middelen
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[5]')
        .select(vki.antiInflamatoryDrugs);
      // Naam geneesmiddel 1
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld16[0]')
        .setText(vki.nameMedication1);
      // Datum geneesmiddel 1
      if (vki.dateFromMedication1 !== '') {
        let dateString = new Date(vki.dateFromMedication1).toLocaleDateString(
          'en-GB'
        );

        if (vki.dateUntilMedication1 !== '') {
          dateString += ` - ${new Date(
            vki.dateUntilMedication1
          ).toLocaleDateString('en-GB')}`;
        }

        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld19[0]')
          .setText(dateString);
      }
      // Duur geneesmiddel 1
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld22[0]')
        .setText(vki.durationMedication1);
      // Naam geneesmiddel 2
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld17[0]')
        .setText(vki.nameMedication2);
      // Datum geneesmiddel 2
      if (vki.dateFromMedication2 !== '') {
        let dateString = new Date(vki.dateFromMedication2).toLocaleDateString(
          'en-GB'
        );

        if (vki.dateUntilMedication2 !== '') {
          dateString += ` - ${new Date(
            vki.dateUntilMedication2
          ).toLocaleDateString('en-GB')}`;
        }

        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld20[0]')
          .setText(dateString);
      }
      // Duur geneesmiddel 2
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld23[0]')
        .setText(vki.durationMedication2);
      // Naam geneesmiddel 3
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld18[0]')
        .setText(vki.nameMedication3);
      // Datum geneesmiddel 3
      if (vki.dateFromMedication3 !== '') {
        let dateString = new Date(vki.dateFromMedication3).toLocaleDateString(
          'en-GB'
        );

        if (vki.dateUntilMedication3 !== '') {
          dateString += ` - ${new Date(
            vki.dateUntilMedication3
          ).toLocaleDateString('en-GB')}`;
        }

        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld21[0]')
          .setText(dateString);
      }
      // Duur geneesmiddel 3
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld24[0]')
        .setText(vki.durationMedication3);
      // Sterfte
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[6]')
        .select(vki.deaths);
      // Oorzaken sterfte
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld25[0]')
        .setText(vki.causeOfDeaths);
      // Ziekte
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[7]')
        .select(vki.sickness);
      // Oorzaken Ziekte
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld26[0]')
        .setText(vki.causeOfSickness);
      // Er werden onderzoeken uitgevoerd ...
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[8]')
        .select(vki.investigated);
      // Datum onderzoek
      if (vki.investigationDate !== '') {
        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld27[0]')
          .setText(new Date(vki.investigationDate).toLocaleDateString('en-GB'));
      }

      // Laatste gemiddelde S/P-ratio
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld39[0]')
        .setText(vki.investigationResult);
      // Risicobedrijf
      form
        .getRadioGroup('topmostSubform[0].Page1[0].LijstKeuzerondje[9]')
        .select(vki.typeOfCompany);
      // Soort onderzoek optioneel

      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld28[0]')
        .setText(vki.optionalInvestigation);

      // Datum onderzoek optioneel
      if (vki.optionalInvestigationDate !== '') {
        form
          .getTextField('topmostSubform[0].Page1[0].Tekstveld29[0]')
          .setText(
            new Date(vki.optionalInvestigationDate).toLocaleDateString('en-GB')
          );
      }
      // Analyse resultaat optioneel
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld30[0]')
        .setText(vki.optionalInvestigationResult);
      // Export aan derde landen
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld31[0]')
        .setText(vki.listOfCountries);
      // Handtekening producent
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld32[0]')
        .setText(vki.signatureProducer);
      // Datum
      form
        .getTextField('topmostSubform[0].Page1[0].Tekstveld33[0]')
        .setText(new Date(vki.signatureDate).toLocaleDateString('en-GB'));
    }

    // In case we want all the fields to be read-only, uncomment below
    // const acroFields = getAcroFields(pdfDoc)
    // acroFields.forEach(field => lockField(field))

    const pdfBytes = await pdfDoc.save();
    const base64 = await pdfDoc.saveAsBase64();

    setPdf(pdfBytes);
    setPdfBase64(base64);

    return base64;
  };

  const formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const onDownload = () => {
    download(pdf, filename, 'application/pdf');
  };

  const onSend = async base64 => {
    const date = formatDate(new Date(vki.signatureDate));
    var formData = new FormData();
    formData.append('pdf', base64);
    formData.append('offer_id', offersState.offers.current.nid);
    formData.append('offer_line_id', offersState.offers.currentOfferLine.nid);
    formData.append('supplier_id', suppliersState.suppliers.activeID);
    formData.append('date', date);
    formData.append('filename', filename);
    formData.append('vki', JSON.stringify(vki));
    // vki_SUPPLIERID_DATUM.pdf

    // console.log(formData.get('vki'));

    await axios
      .post('/sendvki', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        setAlert(t('alerts.success.offers.VKIsent'), 'success')(alertsDispatch);
        saveVKI(
          base64,
          filename,
          offersState.offers.current.nid,
          offersState.offers.currentOfferLine.nid
        )(offersDispatch);
      })
      .catch(err => {
        if (navigator.onLine) {
          console.log(err);
          setAlert(
            t('alerts.errors.offers.VKINotSent'),
            'danger'
          )(alertsDispatch);
        } else {
          setAlert(t('alerts.warnings.pwaDataSync'), 'warning')(alertsDispatch);
        }
      });

    setLoading(false);
  };

  const onRedirect = () => {
    history.push('/offers');
  };

  return (
    <div className='step step1 mt-5 mb-5'>
      <Row className='justify-content-md-center mt-5'>
        <Col md='12'>
          {loading ? (
            <PageSpinner />
          ) : (
            <div className='mt-3'>
              <div className='mt-3 text-center'>
                <Button className='btn-primary mr-2' onClick={onDownload}>
                  {t('vki.download')}
                </Button>
                <Button className='btn-secondary' onClick={onRedirect}>
                  {t('general.backToOverview')}
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Step6;
