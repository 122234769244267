/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'CHANGE_TOPBAR':
      return produce(state, draft => {
        draft.topbarOpen = !draft.topbarOpen;
      });
    case 'CHANGE_SIDEBAR':
      localStorage.setItem('sidebarOpen', !state.sidebarOpen);
      return produce(state, draft => {
        draft.sidebarOpen = !draft.sidebarOpen;
      });
    case 'EXPAND_SETTINGS':
      return produce(state, draft => {
        draft.settingsExpanded = !draft.settingsExpanded;
      });
    default:
      return state;
  }
};
