import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/logo.svg'

const PageSpinner = ({ msg }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
    <div className="page-loader">
      <div role="status">
        <img width="150px" src={logo} alt={t('general.appName')}/>
        <span className="text-white">{msg}</span>
      </div>
    </div> 
  </Fragment>
  )

}

export default PageSpinner;
