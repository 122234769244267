import React, { Fragment, useContext } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import DocumentItem from './DocumentItem';
import DocumentFilter from './DocumentFilter';
import { useTranslation } from 'react-i18next';

import PageSpinner from '../layout/PageSpinner';

import { GlobalContext } from '../../store/Provider';

const DocumentsComponent = () => {
  const { documentsState } = useContext(GlobalContext);

  const { t } = useTranslation();

  console.log('documents: ', documentsState.documents.filtered)

  return (
    <Fragment>
      <Row className='mt-4'>
        <Col lg='4' md='12'>
          <DocumentFilter filetypes={documentsState.documents.filetypes} />
        </Col>
        <Col lg='8' md='12'>
          {documentsState.documents.loading ? <PageSpinner /> : ''}
          <Card>
            <CardBody>
              {documentsState.documents.list.length === 0 ? (
                <h4>{t('documents.notfound')}</h4>
              ) : documentsState.documents.filtered ? (
                documentsState.documents.filtered.length !== 0 ? (
                  <Table responsive className='mb-0'>
                    <thead>
                      <tr>
                        <th>{t('documents.filename')}</th>
                        <th>{t('documents.filetype')}</th>
                        <th>{t('documents.fileDate')}</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentsState.documents.filtered &&
                        documentsState.documents.filtered.map(document => (
                          <DocumentItem key={document.id} document={document} />
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <h4>{t('documents.notfound')}</h4>
                )
              ) : (
                <h4>{t('documents.infoFilter')}</h4>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DocumentsComponent;
