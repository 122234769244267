/* IMPORTS */
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { GlobalProvider } from './store/Provider';

/**
 * SET AXIOS DEFAULTS
 * axios is a promise based HTTP client, see https://www.npmjs.com/package/axios
 */
// axios.defaults.baseURL = 'https://www.backend.bpgap.creathing.be';
axios.defaults.baseURL = 'https://www.backend.pigplaza.be';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

/**
 * SAVE VISITOR FINGERPRINT TO LOCALSTORAGE
 * We use the FingerprintJS library for this, see https://dashboard.fingerprintjs.com/
 * We only check the fingerprint in production (pigplaza.be)
 * This is used later in the login component in the onSubmit() function
 */
(async () => {
  if (!localStorage.getItem('fingerprint')) {
    if (
      window.location.host === 'pigplaza.be' ||
      window.location.host === 'www.pigplaza.be'
    ) {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load({
        token: 'SKJXjlcBdzJT91HqVey9',
        region: 'eu',
      });

      // Get the visitor identifier when you need it.
      fpPromise
        .then(fp => fp.get())
        .then(result => localStorage.setItem('fingerprint', result));
    } else {
      localStorage.setItem(
        'fingerprint',
        '8Vs=KTHnS_3Mly!=*hA&*Rp!6c!9?dbU_m8x2o_s90C%0gjhY3'
      );
    }
  } else {
    localStorage.getItem('fingerprint');
  }
})();

ReactDOM.render(
  <React.StrictMode>
    {/* Language provider */}
    <I18nextProvider i18n={i18n}>
      {/* Global state provider */}
      <GlobalProvider>
        {/* The Application */}
        <App />
      </GlobalProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

/**
 * SERVICEWORKER REGISTRATION
 * added custom onUpdate function to notify users when a new version of the application is available
 * this only works on production (pigplaza.be)
 */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    var userLang = navigator.language || navigator.userLanguage;
    var alertText = 'New version available, click "OK" to update.';
    switch (userLang) {
      case 'nl':
        alertText = 'Nieuwe versie beschikbaar, klik op "OK" om te updaten.';
        break;
      case 'nl-BE':
        alertText = 'Nieuwe versie beschikbaar, klik op "OK" om te updaten.';
        break;
      case 'fr':
        alertText =
          'Nouvelle version disponible, cliquez sur "OK" pour mettre à jour.';
        break;
      case 'fr-BE':
        alertText =
          'Nouvelle version disponible, cliquez sur "OK" pour mettre à jour.';
        break;
      default:
        alertText = 'New version available, click "OK" to update.';
        break;
    }
    alert(alertText);
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.href = 'https://www.pigplaza.be/';
  },
});
