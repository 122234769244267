import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Label, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../store/Provider';
import { setAlert } from '../store/actions/alerts';
import { resetPassword } from '../store/actions/auth';

import Language from '../components/layout/Language';

import img1 from '../assets/images/logo.svg';
import img2 from '../assets/images/bg-pig.jpg';
import PageSpinner from '../components/layout/PageSpinner';
import Alerts from '../components/layout/Alerts';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

const ResetPassword = () => {
  const { alertsDispatch, authState, authDispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [info, setInfo] = useState({
    login_name: '',
    temp_pass: '',
    new_pass: '',
    confirm_new_pass: '',
  });

  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(() => {
    const queryString = window.location.hash;
    // const urlParams = new URLSearchParams(queryString);
    // const login_name = urlParams.get('e');
    // const temp_pass = urlParams.get('p');

    const login_name = queryString.split('?e=')[1].split('&p=')[0];
    const temp_pass = queryString.split('&p=')[1];

    if (login_name && temp_pass) {
      setInfo({
        ...info,
        login_name: login_name,
        temp_pass: temp_pass,
      });
    }
    // eslint-disable-next-line
  }, []);

  const onChange = e => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };


  const validation = () => {
    let formIsValid = true;
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    
    if (!validPassword.test(info.new_pass)) {
      setAlert('Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character', 'danger')(alertsDispatch);
      formIsValid = false;
    } 
    return formIsValid
  }

  const onSubmit = async e => {
    e.preventDefault();
    if(validation()){
      if (info.confirm_new_pass === info.new_pass) {
        const data = {
          name: info.login_name,
          temp_pass: info.temp_pass,
          new_pass: info.new_pass,
        };

        const res = await resetPassword(data)(authDispatch);

        if (res.code === 400) {
          setAlert(res.msg, 'danger')(alertsDispatch);
        } else {
          if (res.code === 504) {
            setAlert(res.msg, 'warning')(alertsDispatch);
          }
          setAlert(t('login.passwordHasBeenReset'), 'success')(alertsDispatch);
          setResetSuccess(true);
          // Redirect to /
        }
      } else {
        setAlert(
          t('alerts.warnings.account.passwordMatchError'),
          'warning'
        )(alertsDispatch);
      }
    }
  };

  if (resetSuccess) {
    return <Redirect to='/' />;
  }

  return (
    <Fragment>
      {authState.loading ? (
        <PageSpinner msg={t('general.oneMomentPlease')} />
      ) : (
        ''
      )}
      <div
        className='auth-wrapper align-items-center d-flex'
        style={sidebarBackground}
      >
        <div className='auth-nav'>
          <ul className='ml-auto navbar-nav'>
            <Language />
          </ul>
        </div>
        <div className='container'>
          <div>
            <Row className='no-gutters justify-content-center'>
              <Col
                md='6'
                lg='4'
                className='bg-dark text-white d-flex align-items-center justify-content-center'
              >
                <div className='text-center p-4'>
                  <img src={img1} width='50%' alt={t('general.appName')} />
                  <h3 className='font-medium mt-3 text-white'>
                    {t('general.appName')}
                  </h3>
                </div>
              </Col>
              <Col md='6' lg='4' className='bg-white'>
                <div className='p-4'>
                  <Form
                    className='mt-3 mb-3'
                    id='loginform'
                    onSubmit={onSubmit}
                  >
                    {resetSuccess && (
                      <div className='text-center mb-4'>
                        <p>{t('login.passwordHasBeenReset')}</p>
                        <p>
                          <Link to='/'>{t('general.backToLogin')}</Link>
                        </p>
                      </div>
                    )}
                    <Input
                      disabled={authState.loading}
                      type='hidden'
                      name='temp_pass'
                      value={info.temp_pass}
                      onChange={onChange}
                      required
                    />
                    <FormGroup className='mb-3' size='lg'>
                      <Label>{t('general.email')}</Label>
                      <Input
                        disabled={authState.loading}
                        name='login_name'
                        type='text'
                        value={info.login_name}
                        onChange={onChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup className='mb-3' size='lg'>
                      <Label>{t('login.newPassword')}</Label>
                      <Input
                        disabled={authState.loading}
                        type='password'
                        name='new_pass'
                        value={info.new_pass}
                        onChange={onChange}
                        autoComplete='new-password'
                        required
                      />
                    </FormGroup>
                    <FormGroup className='mb-3' size='lg'>
                      <Label>{t('login.confirmNewPassword')}</Label>
                      <Input
                        disabled={authState.loading}
                        type='password'
                        name='confirm_new_pass'
                        value={info.confirm_new_pass}
                        onChange={onChange}
                        autoComplete='confirm-new-password'
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Button
                        disabled={authState.loading}
                        color='primary'
                        size='lg'
                        type='submit'
                        block
                      >
                        {t('login.savePassword')}
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-nav'>
          &copy; 2021 - <Link to='/legal-info'>Legal info</Link>
        </div>
      </div>

      <div className='alert-region'>
        <Alerts />
      </div>
    </Fragment>
  );
};

export default ResetPassword;
