/* eslint-disable import/no-anonymous-default-export */
import produce from "immer";

export default (state, { type }) => {
  switch (type) {
    case "RESET":
      return 1; // Set it back to the initial state
    default:
      return produce(state, (draft) => {
        let data = 1;
        data = state +  1
        return data;
      });
  }
};
