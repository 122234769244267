import React, { useState, useContext } from 'react';

import { Button } from 'reactstrap';

import { checkStringDatePast } from '../../utils/Utils';

import { GlobalContext } from '../../store/Provider';
import { addResearch } from '../../store/actions/loadingplaces';

// Translation
import { useTranslation } from 'react-i18next';

const LoadingplaceResearchForm = ({ setResearchLoading }) => {
  const { t } = useTranslation();

  const { loadingplacesState, loadingplacesDispatch } =
    useContext(GlobalContext);

  const [newResearch, setNewReasearch] = useState({
    type: 'Salmonella',
    date: '',
    result: '',
  });

  const [typeSelect, setTypeSelect] = useState('1');

  const [falsyFields, setFalsyFields] = useState({
    type: false,
    date: false,
    result: false,
  });

  const checkResearchInput = () => {
    let continueSubmit = true;
    let inputErrors = {
      type: false,
      date: false,
    };

    if (
      newResearch.type === '' ||
      newResearch.type === null ||
      newResearch.type === undefined
    ) {
      continueSubmit = false;
      inputErrors.type = true;
    }

    if (
      newResearch.date === '' ||
      newResearch.date === null ||
      newResearch.date === undefined ||
      !checkStringDatePast(newResearch.date)
    ) {
      continueSubmit = false;
      inputErrors.date = true;
    }

    if (
      newResearch.result === '' ||
      newResearch.result === null ||
      newResearch.result === undefined
    ) {
      continueSubmit = false;
      inputErrors.result = true;
    }

    setFalsyFields(inputErrors);

    return continueSubmit;
  };

  return (
    <tr>
      <td>
        <select
          onChange={e => {
            setTypeSelect(e.target.value);

            if (e.target.value === '1') {
              setNewReasearch({
                ...newResearch,
                type: 'Salmonella',
              });
            } else {
              setNewReasearch({
                ...newResearch,
                type: '',
              });
            }
          }}
          value={typeSelect}
        >
          <option value='1'>{t('vki.salmonella')}</option>
          <option value='2'>{t('general.other')}</option>
        </select>
        {typeSelect === '2' && (
          <input
            className='mt-2 d-block'
            type='text'
            name='type'
            value={newResearch.type}
            required={true}
            placeholder={t('general.specify')}
            onChange={e => {
              setNewReasearch({
                ...newResearch,
                type: e.target.value,
              });
            }}
          />
        )}

        {falsyFields.type && (
          <span className='text-danger d-block'>
            {t('forms.fieldErrors.fieldRequired')}
          </span>
        )}
      </td>
      <td>
        <input
          type='date'
          name='date'
          value={newResearch.date}
          required={true}
          onChange={e => {
            setNewReasearch({
              ...newResearch,
              date: e.target.value,
            });
          }}
        />
        {falsyFields.date && (
          <span className='text-danger d-block'>
            {t('forms.fieldErrors.dateMustBeInPast')}
          </span>
        )}
      </td>
      <td>
        <input
          type='text'
          name='result'
          value={newResearch.result}
          required={true}
          onChange={e => {
            setNewReasearch({
              ...newResearch,
              result: e.target.value,
            });
          }}
        />
        {falsyFields.result && (
          <span className='text-danger d-block'>
            {t('forms.fieldErrors.fieldRequired')}
          </span>
        )}
      </td>
      <td>
        <Button
          type='submit'
          className='btn-primary'
          onClick={async () => {
            setResearchLoading(true);

            if (checkResearchInput()) {
              await addResearch(
                newResearch,
                loadingplacesState.loadingplaces.current.id
              )(loadingplacesDispatch);

              setNewReasearch({
                type: '',
                date: '',
                result: '',
              });
              setTypeSelect('1');
            }

            setResearchLoading(false);
          }}
        >
          {t('general.add')}
        </Button>
      </td>
    </tr>
  );
};

export default LoadingplaceResearchForm;
