import React, { useContext, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { decodeStr } from '../../../utils/Utils';

// Translation
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../../../store/Provider';

const Step1 = ({ vki, setVKI, jumpToStep }) => {
  const { veterinariansState } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [invalidFields, setInvalidFields] = useState([]);

  const {
    codeFittings,
    nameProducer,
    addressProducer,
    zipProducer,
    cityProducer,
    countryProducer,
    emailProducer,
    phoneProducer,
    selectedVeterinarian,
  } = vki;

  const onVeterinarianChange = async e => {
    if (e.target.value && e.target.value !== '') {
      const vet = veterinariansState.veterinarians.list.find(
        veterinarian => veterinarian.id === e.target.value
      );
      await setVKI({
        ...vki,
        selectedVeterinarian: e.target.value,
        nameVeterinarian: decodeStr(vet.title),
        addressVeterinarian: decodeStr(vet.address_line1),
        zipVeterinarian: vet.postcode,
        cityVeterinarian: vet.locality,
        countryVeterinarian: vet.country_code,
        emailVeterinarian: vet.email,
        phoneVeterinarian: vet.telephone,
      });
    }
  };

  const onChange = e =>
    setVKI({ ...vki, [e.target.getAttribute('data-field')]: e.target.value });

  const validateStep = async () => {
    let falsyFields = [];

    if (!codeFittings) {
      falsyFields.push({
        field: 'codeFittings',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!nameProducer) {
      falsyFields.push({
        field: 'nameProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!addressProducer) {
      falsyFields.push({
        field: 'addressProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!zipProducer) {
      falsyFields.push({
        field: 'zipProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!cityProducer) {
      falsyFields.push({
        field: 'cityProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!countryProducer) {
      falsyFields.push({
        field: 'countryProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!emailProducer) {
      falsyFields.push({
        field: 'emailProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!phoneProducer) {
      falsyFields.push({
        field: 'phoneProducer',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (!selectedVeterinarian && selectedVeterinarian === '') {
      falsyFields.push({
        field: 'selectedVeterinarian',
        msg: t('forms.fieldErrors.fieldRequired'),
      });
    }

    if (falsyFields.length === 0) {
      jumpToStep(1);
    } else {
      setInvalidFields(falsyFields);
    }
  };

  const checkField = fieldName => {
    return invalidFields.find(field => field.field === fieldName)
      ? true
      : false;
  };

  const getMessage = fieldName => {
    return invalidFields.find(field => field.field === fieldName).msg;
  };

  return (
    <div className='step step1 mt-5 mb-5'>
      <Row className='justify-content-md-center mt-5'>
        <Col lg='6'>
          {invalidFields.length > 0 && (
            <div className='alert alert-danger fade show' role='alert'>
              {t('forms.fieldErrors.checkFields')}
            </div>
          )}
          <Form
            className='form-horizontal form-material mt-2'
            onSubmit={e => e.preventDefault()}
          >
            <h4 className='mb-3'>{t('vki.informationProducer')}</h4>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='nameProducer'>
                    {t('vki.nameProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={decodeStr(nameProducer)}
                    data-field='nameProducer'
                    name='nameProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('nameProducer') && (
                    <span className='text-danger'>
                      {getMessage('nameProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='codeFittings'>
                    {t('vki.codeFittings')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={codeFittings}
                    data-field='codeFittings'
                    name='codeFittings'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('codeFittings') && (
                    <span className='text-danger'>
                      {getMessage('codeFittings')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='addressProducer'>
                    {t('vki.addressProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={decodeStr(addressProducer)}
                    data-field='addressProducer'
                    name='addressProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('addressProducer') && (
                    <span className='text-danger'>
                      {getMessage('addressProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='zipProducer'>
                    {t('vki.zipProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={zipProducer}
                    data-field='zipProducer'
                    name='zipProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('zipProducer') && (
                    <span className='text-danger'>
                      {getMessage('zipProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='cityProducer'>
                    {t('vki.cityProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={cityProducer}
                    data-field='cityProducer'
                    name='cityProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('cityProducer') && (
                    <span className='text-danger'>
                      {getMessage('cityProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='countryProducer'>
                    {t('vki.countryProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={countryProducer}
                    data-field='countryProducer'
                    name='countryProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('countryProducer') && (
                    <span className='text-danger'>
                      {getMessage('countryProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='emailProducer'>
                    {t('vki.emailProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='email'
                    value={emailProducer}
                    data-field='emailProducer'
                    name='emailProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('emailProducer') && (
                    <span className='text-danger'>
                      {getMessage('emailProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='phoneProducer'>
                    {t('vki.phoneProducer')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='text'
                    value={phoneProducer}
                    data-field='phoneProducer'
                    name='phoneProducer'
                    onChange={onChange}
                    // disabled={true}
                  />
                  {checkField('phoneProducer') && (
                    <span className='text-danger'>
                      {getMessage('phoneProducer')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <h4 className='mb-3 mt-5'>{t('vki.informationVeterinarian')}</h4>
            <Row>
              <Col md='12'>
                <FormGroup>
                  <Label>
                    {t('veterinarians.singular')}{' '}
                    <span className='text-primary'>*</span>
                  </Label>
                  <Input
                    type='select'
                    onChange={onVeterinarianChange}
                    value={vki.selectedVeterinarian}
                    name='selectedVeterinarian'
                  >
                    <option value='' disabled={true}>
                      {t('general.makeChoice')}
                    </option>
                    {veterinariansState.veterinarians.list.map(veterinarian => (
                      <option key={veterinarian.id} value={veterinarian.id}>
                        {decodeStr(veterinarian.title)} -{' '}
                        {decodeStr(veterinarian.address_line1)},{' '}
                        {veterinarian.postcode} {veterinarian.locality}
                      </option>
                    ))}
                  </Input>
                  {checkField('selectedVeterinarian') && (
                    <span className='text-danger'>
                      {getMessage('selectedVeterinarian')}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div className='mt-4 d-flex justify-content-end'>
            <Button onClick={validateStep} className='btn-primary'>
              {t('general.next')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step1;
