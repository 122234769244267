/* eslint-disable import/no-anonymous-default-export */
export default {
  documents: {
    list: [],
    filtered: [],
    loading: false,
    filetypes: [],
    fetching: true,
  },
};
