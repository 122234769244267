import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const InlineSpinner = props => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className='inline-loader'>
        <div role='status' className={`spinner-border text-${props.color}`}>
          <span className='sr-only'>{t('general.loading')}...</span>
        </div>
      </div>
    </Fragment>
  );
};

export default InlineSpinner;
