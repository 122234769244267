
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../../store/Provider';
import { clearCurrentArticle } from '../../store/actions/articles';

const BlogPage = () => {
  const { articlesState, articlesDispatch, layoutState } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();

  const getSidebarType = () => {
    if (window.innerWidth >= 768) {
      return layoutState.sidebarOpen ? 'iconbar' : 'mini-sidebar';
    } else {
      return 'mini-sidebar';
    }
  };

  useEffect(() => {
    return () => {
      clearCurrentArticle()(articlesDispatch);
    };
  }, [articlesDispatch]);

  const redirect = () => {
    history.push('/dashboard');
  };

  const { title, body, image } = articlesState.articles.current;

  return (
    <div
      id='main-wrapper'
      data-layout='vertical'
      data-sidebartype={getSidebarType()}
      data-sidebarposition='fixed'
      data-header-position='fixed'
      data-boxed-layout='full'
      data-theme='light'
    >
      <Header />
      <Sidebar />
      <div className='page-wrapper d-block'>
        <div className='page-content container-fluid'>
          <div className='container'>
            <Button className='btn btn-primary' onClick={redirect}>
              {t('general.prev')}
            </Button>
          </div>
          <div className='container mt-4'>
            <Card>
              <CardBody>
                <div className='p-4'>
                  <h1 className='text-center' dangerouslySetInnerHTML={{ __html: title }}></h1>

                  <div className='mt-4 text-center'>
                    <img width='auto' src={image} alt={title} />
                  </div>

                  {/* I also like to live dangerously */}
                  <div
                    className='mt-4 pl-4 pr-4 pt-4'
                    dangerouslySetInnerHTML={{ __html: body }}
                  ></div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
