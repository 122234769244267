/* eslint-disable import/no-anonymous-default-export */

import produce from 'immer';

export default (state, { payload, type }) => {
  switch (type) {
    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.suppliers.loading = false;
        draft.suppliers.activeID = null;
        draft.suppliers.activeUUID = null;
        draft.suppliers.list = [];
      });
    case 'SET_ACTIVE_SUPPLIER':
      return produce(state, draft => {
        draft.suppliers.activeID = payload.id;
        draft.suppliers.activeUUID = payload.uuid;
      });
    case 'SET_ALL_SUPPLIERS':
      return produce(state, draft => {
        draft.suppliers.list = payload;
      });
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.suppliers.loading = payload;
      });
    default:
      return state;
  }
};
