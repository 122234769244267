import React, { Fragment } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Componenets
import LoadingplacesList from './LoadingplacesList';
import LoadingplacesDetail from './LoadingplacesDetail';

const LoadingplacesComponent = () => {
  const match = useRouteMatch();

  return (
    <Fragment>
      <Switch>
        <Route path={`${match.path}/view`} component={LoadingplacesDetail} />
        <Route path={match.path} component={LoadingplacesList} />
      </Switch>
    </Fragment>
  );
};

export default LoadingplacesComponent;
