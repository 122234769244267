/* eslint-disable import/no-anonymous-default-export */
export default {
  contributors: {
    list: [],
    loading: false,
    current: null,
    error: null,
    fetching: true,
  },
};
