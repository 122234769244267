import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import download from 'downloadjs';

import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import { setLoading } from '../../store/actions/documents';

const DocumentItem = ({ document }) => {
  const { alertsDispatch, documentsDispatch } = useContext(GlobalContext);

  const { filename, filetype, field_desecnm, factuurvoorsteldatum } = document;

  const { t } = useTranslation();

  const base64toBlob = base64Data => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  };

  const onView = async () => {
    setLoading(true)(documentsDispatch);


    console.log('desecnm ', field_desecnm);
    console.log('title ', filename)

    try {
      const res = await axios.get(
        `/jsonapi/document?desecnm=${field_desecnm}&title=${filename}`
      );

      const blob = base64toBlob(res.data);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } catch (err) {
      if (navigator.onLine) {
        setAlert(
          t('alerts.errors.documents.couldNotRequestDocument'),
          'danger'
        )(alertsDispatch);
      } else {
        setAlert(
          t('alerts.errors.internetConnectionRequired'),
          'danger'
        )(alertsDispatch);
      }
    }

    setLoading(false)(documentsDispatch);
  };

  const onDownload = async () => {
    setLoading(true)(documentsDispatch);

    try {
      const res = await axios.get(
        `/jsonapi/document?desecnm=${field_desecnm}&title=${filename}`
      );

      if(filetype === 'Cercosoft') {
        
        //md5 hash
        const covFile = res.data;
        download(atob(covFile), filename, 'application/cov');
      } else {
        const base64 = `data:application/pdf;base64,${res.data}`;
        download(base64, filename, 'application/pdf');
      }
  


    } catch (err) {
      if (navigator.onLine) {
        console.log(err);
        setAlert(
          t('alerts.errors.documents.couldNotRequestDocument'),
          'danger'
        )(alertsDispatch);
      } else {
        setAlert(
          t('alerts.errors.internetConnectionRequired'),
          'danger'
        )(alertsDispatch);
      }
    }

    setLoading(false)(documentsDispatch);
  };
  return (
    <Fragment>
      {field_desecnm !== '' && filetype !== 'Cercosoft' ?  (
        <tr>
          <td>{filename}</td>
          <td>{filetype}</td>
          <td>{factuurvoorsteldatum}</td>
          <td>
            <button onClick={onView} className='btn btn-secondary btn-sm'>
              <i className='icon-eye'></i> <span>{t('general.view')}</span>
            </button>

            <button onClick={onDownload} className='btn btn-secondary btn-sm'>
              <i className='icon-cloud-download'></i>{' '}
              <span>{t('general.download')}</span>
            </button>
          </td>
        </tr>
      ) : field_desecnm !== '' && filetype === 'Cercosoft' && (
         <tr>
         <td>{filename}</td>
         <td>{filetype}</td>
         <td>{factuurvoorsteldatum}</td>
         <td>
           <button onClick={onDownload} className='btn btn-secondary btn-sm'>
             <i className='icon-cloud-download'></i>{' '}
             <span>{t('general.download')}</span>
           </button>
         </td>
       </tr>
      )}
    </Fragment>
  );
};

DocumentItem.propTypes = {
  document: PropTypes.object.isRequired,
};

export default DocumentItem;
