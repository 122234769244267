import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import img2 from '../assets/images/bg-pig.jpg';

import axios from 'axios';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

const About = () => {
  useEffect(() => {
    getReq();
    // eslint-disable-next-line
  }, []);

  const getReq = async () => {
    const res = await axios.get('/node/2?_format=json');
    console.log(res);
  };

  return (
    <Fragment>
      <div
        className='auth-wrapper  align-items-center d-flex'
        style={sidebarBackground}
      >
        <div className='container'>
          <div>
            <Row className='no-gutters justify-content-center'>
              <Col md='12' className='bg-white'>
                <div className='p-4'>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Corporis eos officia nihil et temporibus quo quaerat laborum
                  facere velit aut. Error dolorum quaerat delectus expedita
                  nulla ducimus sit, harum earum.
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-nav'>
          &copy; 2021 - <a href='#!'>Legal info</a>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
