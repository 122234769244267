import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { decodeStr } from '../../utils/Utils';

// Context
import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';

import {
  deleteVeterinarian,
  setCurrentVeterinarian,
  clearCurrentVeterinarian,
  setLoading,
} from '../../store/actions/veterinarians';
import { removeDefaultVeterinarian } from '../../store/actions/loadingplaces';

// Translation
import { useTranslation } from 'react-i18next';

const VeterinarianItem = ({ veterinarian }) => {
  const { veterinariansDispatch, alertsDispatch, loadingplacesDispatch } =
    useContext(GlobalContext);

  const { id, title, address_line1, postcode, locality, telephone, email } =
    veterinarian;

  const { t } = useTranslation();

  const onDelete = async () => {
    setLoading(true)(veterinariansDispatch);
    var r = window.confirm(t('general.thisActionCannotBeUndone'));
    if (r === true) {
      const res = await deleteVeterinarian(id)(veterinariansDispatch);
      clearCurrentVeterinarian()(veterinariansDispatch);

      if (res.code === 400) {
        setAlert(res.msg, 'danger')(alertsDispatch);
      } else if (res.code === 504) {
        removeDefaultVeterinarian(id)(loadingplacesDispatch);
        setAlert(res.msg, 'warning')(alertsDispatch);
      } else {
        removeDefaultVeterinarian(id)(loadingplacesDispatch);
        setAlert(res.msg, 'success')(alertsDispatch);
      }
    }
    setLoading(false)(veterinariansDispatch);
  };

  let match = useRouteMatch();

  return (
    <tr>
      {/* Name */}
      <td>{decodeStr(title)}</td>
      {/* Email */}
      <td>{email}</td>
      {/* Phone */}
      <td>{telephone}</td>
      {/* Address */}
      <td>
        {address_line1}, {postcode} {locality}
      </td>
      {/* Actions */}
      <td>
        <Link
          onClick={() =>
            setCurrentVeterinarian(veterinarian)(veterinariansDispatch)
          }
          to={`${match.path}/edit`}
          className='btn btn-secondary mr-1 btn-sm'
        >
          <i className='icon-pencil'></i> <span>{t('general.edit')}</span>
        </Link>
        <button className='btn btn-secondary btn-sm' onClick={onDelete}>
          <i className='icon-trash'></i> <span>{t('general.delete')}</span>
        </button>
      </td>
    </tr>
  );
};

VeterinarianItem.propTypes = {
  veterinarian: PropTypes.object.isRequired,
};

export default VeterinarianItem;
