/* eslint-disable import/no-anonymous-default-export */
import produce from 'immer';
import {
  filterDateStringToTimestamp,
  docDateStringToTimestamp,
} from '../../utils/Utils';

export default (state, { payload, type }) => {
  switch (type) {
    case 'SET_FETCHING':
      return produce(state, draft => {
        draft.documents.fetching = payload;
      });
    case 'CLEAR_STATE':
      return produce(state, draft => {
        draft.documents.list = [];
        draft.documents.filtered = [];
        draft.documents.filetypes = [];
        draft.documents.loading = false;
        draft.documents.fetching = true;
      });
    case 'SET_LOADING':
      return produce(state, draft => {
        draft.documents.loading = payload;
      });
    case 'GET_DOCUMENTS':
      return produce(state, draft => {
        draft.documents.list = payload;
        draft.documents.loading = false;
        draft.documents.fetching = false;
      });
    case 'CLEAR_DOCUMENTS_FILTER':
      return produce(state, draft => {
        draft.documents.filtered = [];
        draft.documents.loading = false;
      });
    case 'GET_FILETYPES':
      let types = new Set();
      payload.forEach(element => {
        types.add(element.filetype);
      });
      return produce(state, draft => {
        draft.documents.loading = false;
        draft.documents.filetypes = [...new Set(types)].sort();
      });

    case 'FILTER_DOCUMENTS':
      if (
        payload.fileType === '0' &&
        payload.fileDateOne === '' &&
        payload.fileDateTwo === ''
      ) {
        return produce(state, draft => {
          draft.documents.loading = false;
          draft.documents.filtered = [];
        });
      }

      let filteredDocuments = state.documents.list;

      // Check if FileType is given
      if (payload.fileType !== '0') {
        filteredDocuments = filteredDocuments.filter(document => {
          if (document.filetype.toLowerCase() === payload.fileType) {
            return document;
          } else {
            return false;
          }
        });
      }

      // Check if Date "From" is given
      if (payload.fileDateOne !== '') {
        const timestampFilter = filterDateStringToTimestamp(
          payload.fileDateOne
        );

        filteredDocuments = filteredDocuments.filter(document => {
          let timestampDoc = docDateStringToTimestamp(
            document.factuurvoorsteldatum
          );

          if (timestampDoc >= timestampFilter) {
            return document;
          } else {
            return false;
          }
        });
      }

      // Check if Date "Until" is given
      if (payload.fileDateTwo !== '') {
        const timestampFilter = filterDateStringToTimestamp(
          payload.fileDateTwo
        );

        filteredDocuments = filteredDocuments.filter(document => {
          let timestampDoc = docDateStringToTimestamp(
            document.factuurvoorsteldatum
          );

          if (timestampDoc <= timestampFilter) {
            return document;
          } else {
            return false;
          }
        });
      }

      return produce(state, draft => {
        draft.documents.loading = false;
        draft.documents.filtered = filteredDocuments;
      });

    default:
      return state;
  }
};
